/*
V2 1.) Home Page
V2 2.) Header
V2 3.) Footer
V2 4.) Players Schedule
V2 5.) Live Scoring



1.) Login page 
2.) Header page 
3.) Forgot Password page 
4.) Sidebar page 
5.) Club Management page 
6.) User Management page 
7.) My Profile page 
8.) Add new club page 
9.) Add Player page 
10.) Match page 
11.) Tournament page 
12.) Home page 

*/

.main-container {
  padding: 95px 15px 24px 15px;
  background-color: #eeeeee;
}
.loaded, .loaded .home-page, .loaded #root{background:transparent !important;}
.main-content{background: #124e25!important;}
/* ============ NEW UI CSS START HERE =========== */

/* V2 1.) Home Page css start here -------------------- */
.card-container{
  transition: 1s;
  transform: translateY(100%);
  transition: all 1s ease-in-out;
  opacity: 0;
}
.loaded .card-container{
  transform: translateY(0);
  opacity: 1;
}
.card-col:hover{
   -ms-transform: scale(105%,105%);
  transform: scale(105%,105%);
  transition: all 0.30s ease-in-out;
  /* cursor: pointer; */
}
.card-col:hover:after{
  content: '';
  height: 15px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #b52830;
  transition: all 0.30s ease-in-out;
}
.home-page .card-container .card-col{min-width:100%;}
.card-col{
  background: #fff;
  border-radius: 8px;
  height: 412px;
  min-width: 300px;
  transition: all 0.30s ease-in-out;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  }
.card-col .card-top{
  padding: 16px 20px;
  box-shadow: 0 3px 6px 0 rgba(18, 78, 37, 0.1);
  background-color: #ffffff;
  border-radius: 8px 8px 36px 36px;
  margin-bottom: 13px;

}
.card-col .card-top .time-city{
  font-size: 13px;
  line-height: 16px;
  color: #666666;
  margin-top: 3px;
}
.card-col .card-top .club_name{
  font-size: 13px;
  line-height: 20px;
  color: #666666;
  margin-top: 3px;
}
.card-col .card-top h3{
  font-size: 16px;
  line-height: 21px;
  color: #333333;
  margin-bottom: 0px;
}
.card-col .card-top h3 span{
  font-weight: 600;
}
.w-30 {
  width: 30% !important;
}
.card-col .card-top .team-logo{
  margin-bottom: 15px;
}
.card-col .card-top .team-logo-img svg{
  max-width: 88px;
  height: auto;
}
.card-col .card-top .team-logo .title{
  font-size: 13px;
  line-height: 20px;
  color: #124e25;
}
.card-col .team-list ul{
  padding: 0;
  margin: 0;
}
.card-col .team-list ul li{
  font-size: 13px;
  line-height: 20px;
  color: #454545;
  font-family: "robotomedium";
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}
.card-col .team-list ul li:hover{
  color: #b52830;
  text-decoration: underline;
}
.card-col .team-list ul li:hover svg path{
  fill: #b52830;
}
.card-col .team-list ul li:nth-child(odd){
  max-width: 60%;
  flex: 0 0 60%;
}
.card-col .team-list ul li:nth-child(even){
  max-width: 40%;
  flex: 0 0 40%;
}
.card-col .team-list ul li.entertournament {
  position: absolute;
  bottom: 57px;
  text-align: center;    
  left: 50%;    
  transform: translateX(-50%);    width: 100%;cursor:unset;
  align-items: center;color: unset;text-decoration:unset;
  justify-content: center;
}
.card-col .team-list ul li.entertournament div{
  color: #2c5c3b;    font-size: 14px;
}
.card-col .team-list ul li.entertournament span, .card-col .team-list ul li.entertournament:hover span{
  color: #b52830;  transform:unset;    font-family: "robotoregular";

}
.card-col:hover .entertournament span{    font-size: 13px;
}
.card-col .team-list ul li.entertournament:hover svg path{fill:#b52830;}
.card-col .team-list ul li.entertournament:hover div{    color: #b52830;  text-decoration: underline;cursor:pointer;
}
.modal-dialog .login-page .card-top {
  padding: 22px 11px;
  box-shadow: 0 3px 6px 0 rgba(18, 78, 37, 0.1);
  background-color: #ffffff;
  border-radius: 10px 10px 10px 10px;
}
.modal-dialog .login-page .card-top .team-logo .title {
  font-size: 13px;
  line-height: 20px;
  color: #124e25;
}

.modal-dialog .login-page .btn.login-btn.btns{
  background: #b52830;
  margin: auto; 
  display: block;
  padding: 9px 26px;
}
.modal-dialog .login-page .btn.login-btn.btns:hover{background-color: #8b1118;}
.modal-dialog .login-page .card-top h5 {
  font-size: 16px;
  line-height: 21px;
  color: #333333;
  margin-bottom: 2px;
}
.modal-dialog .login-page .card-top .club_name {
  font-size: 13px;
  line-height: 20px;
  color: #666666;
  margin-top: 0px;
}
.card-col .team-list ul li .svg-bx{
  margin-right: 9px;
  max-width: 22px;
}
.card-col .team-list{
  padding:0 20px;
  margin-bottom: 5px;
}
.live-btn a e {
  padding-left: 4px;
}
.live-btn a{
  border:1px solid #cccccc;
  height: 40px;
  max-width: 160px;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #454545;
  border-radius: 40px;
  margin-bottom: 21px;
}
.live-btn a span{
  width: 12px;
  height: 12px;
  box-shadow: 0 0 4px 0 rgba(237, 45, 45, 0.6);
  background-color: #e53935;
  border-radius: 50%;
  margin-right: 11px;
}
.live-btn a:hover {
  cursor: pointer;
  border: 1px solid rgb(181, 40, 48);
  box-shadow: rgb(181 40 48) 0px 0px 4px 0px;
}
.isLiveGrayDot{
  width: 12px;
  height: 12px;
  box-shadow: 0 0 4px 0 rgb(85, 82, 82) !important;
  background-color:grey !important;
  border-radius: 50%;
  margin-right: 11px;
}


.top-section{
  margin-bottom: 21px;
}
.top-section h2{
  font-size: 20px;
  line-height: 20px;
  font-family: "robotomedium";
  color: #ffffff;
  padding: 0 15px;
  width: 100%;
  margin-bottom: 12px;
}
.search-section{
  font-size: 14px;
  line-height: 20px;
  color: rgba(255,255,255,0.7);
  width: 100%;
}
.search-section .form-control{
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  padding: 0 16px;
  border:none; 
}
.search-section .form-control.type{
  width: 100%;
}
.search-section .form-control.type:hover{cursor: pointer;}
.search-section .form-control.game-name{
  width: 100%;
  background-image:url(../images/select-arrow.png);
  background-position: center right 13px;
  background-repeat: no-repeat;
}
.infinite-scroll-component__outerdiv{width:100%;}
.search-section .form-control.game-name:hover{cursor:pointer;}
.search-section .form-control.type{
  background-image:url(../images/select-arrow.png);
  background-position: center right 13px;
  background-repeat: no-repeat;
}
.search-section .form-control.search{
  width: 100%;
  padding-left: 44px;
}
.search-section .form-control.search::placeholder {
  color: rgba(255,255,255,0.4);
  opacity: 1;
}

.search-section .form-control.search:-ms-input-placeholder {
  color: rgba(255,255,255,0.4);
}

.search-section .form-control.search::-ms-input-placeholder {
  color: rgba(255,255,255,0.4);
}
.search-section span{
  white-space: nowrap;
  margin-right: 12px;
  font-family: "robotomedium";
  margin-bottom: 8px;
}
.search-section .search-col.tournament-style,
.search-section .search-col.sport-type{
  flex-direction: column;
  justify-content: flex-start;
  max-width: calc(50% - 7.5px);
  flex: 0 0 calc(50% - 7.5px);
  margin-top: 16px;
}
.search-section .search-col.tournament-style{
  margin-right: 7.5px;
}
.search-section .search-col.sport-type{
  margin-left: 7.5px;
}
.search-section .search-col{
  position: relative;
}
.search-section .search-col .drop-arrow{
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
}
.search-section .search-col .search-ico{
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
}
/* V2 1.) Home Page css end here -------------------- */

/* V2 2.) Header css start here -------------------- */

/* V2 2.) Header css start here -------------------- */

/* V2 3.) Footer css start here -------------------- */
.home-page-footer {
  background: #0b2f16 url(../images/bg-left-bottam.png) -75px center no-repeat;
  padding: 28px 15px;
  position: relative;
}
.home-page-footer:after{
  content: '';
  background: url(/static/media/bg-right-bottom.dd814794.png) left 110px top no-repeat;
  position: absolute;
  bottom: -60px;
  right: 0;
  height: 358px;
  width: 265px;
  z-index: 1;
  background-size: contain;
}
.home-page-footer .social{
  z-index: 2;
}
.home-page-footer .social a{
  margin: 0 8px;
}
/* V2 3.) Footer css start here -------------------- */


/* V2 4.) Players Schedule css start here -------------------- */
#tournamentDetails.fade{
  display: block !important;
  opacity: 1;
  transform: translateX(100%);
  transition: all 1s ease-in-out;
}
#tournamentDetails.show{
  z-index: 1050;
  transform: translateX(0);
}
#tournamentDetails .modal-dialog{
  width: 100%;
  max-width: 100% !important;
  margin: initial;
  height: 100%;
  transform: none !important;
  transition: initial !important;
  pointer-events: initial !important;
}
.white-container {
  background: #ffffff;
  max-width: 960px;
  position: absolute;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  transform: translateX(100%);
  transition: all 1s ease-in-out;
}
#tournamentDetails.show .white-container{
  transform: translateX(0);
}
#tournamentDetails.show{
  display: inherit !important;
  opacity: 1;
}
#tournamentDetails.fade:not(.show){
  opacity: 1 !important;
}
.top-head{
  background: #124e25;
  padding: 15px 16px 22px 16px;
}
#tournamentDetails{
  padding: 0 !important;
}
.tabbing-header{
  background: #fff;
  padding: 19px 16px 0 16px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 5;
}
.tabbing-header .nav-link{
  color: #454545;
  font-size: 15px;
  line-height: 20px;
  font-family: "robotomedium";
  padding: 6px 7px;
}
.tabbing-header .nav-link.active{
  border:none;
  border-bottom: 2px solid #b52830;
  color: #b52830;
}
.tabbing-header .nav-tabs .nav-item{
  margin-right: 24px;
}
.tabbing-header .nav-tabs{
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}
.tabbing-header .nav-tabs .nav-link{
  border:none;
  white-space: nowrap;
}
.tabbing-header .nav-tabs .nav-link.active{
  border-bottom: 3px solid #b52830;
}
.tabbing-header .team-logo{
  margin-right: 27px;
}
.tabbing-header .team-logo svg{
  width: 73px;
  height: auto;
}
.tabbing-header .title-col h2{
  font-size: 18px;
  line-height: 22px;
  color: #333333;
  margin-bottom: 6px;
}
.tabbing-header .title-col .small-title{
  font-size: 13px;
  line-height: 20px;
  color: #666666;
}
.tabbing-header .team-details{
  margin-bottom: 25px;
}
.tabbing-header .title-col .small-title .green-txt{
  color: #124e25;
}

.green-txt-title{
  color: #124e25;
}
.for-streaming .green-txt-title span{
  background: #124e25;
  color: #fff;
  padding: 3px 10px;
  border-radius: 3px 3px 0 0;
  position: relative;
  top: 0px;
  /* font-size: 12px; */
  font-weight: bold;
}
.match-name{
  color: #fff;
  font-size: 26px;
  text-shadow: 0 0 14px #727272;
}
.match-score{
  color: #fff;
  font-size: 32px;
  text-shadow: 0 0 14px #727272;
}

.tabbing-header .title-col .small-title .dot{
  font-family: "robotomedium";
}
.add-club-wrapper #resultsModal td {
  padding: 4px 11px;font-size: 14px !important;
}
.add-club-wrapper #resultsModal .form-control {
  font-size: 14px;
  height: 30px;
  width: 100%;
  margin: auto;
}
.add-club-wrapper #resultsModal .total-matches-play td {
  padding: 8px 11px !important;
}
.add-club-wrapper #resultsModal .total-matches-play td.text-center.text-red.font-bold{font-size: 16px !important;}
.team-content .heading{
  font-family: "robotomedium";
  font-size: 18px;
  line-height: 20px;
  color: #454545;
  padding: 17px 16px 14px 16px;
}
.group-heading{
  padding: 0 16px 9px 16px;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  color: #454545;
  text-transform: uppercase;
}
.group-heading:hover{
  color: #b52830;
  text-decoration: underline;
  cursor: pointer;
}
 svg.group-heading-svg{
  height: 13px;
  position: relative;
  left: -20px;
  top: -4px;
}
.team-content .heading .bottom-txt{
  font-size: 13px;
  line-height: 20px;
  margin-top: 3px;
}
.team-content .heading .bottom-txt .dark-gray{
  color: #b52830;
  font-style: italic;
}
.team-content .heading span{
  color: #858585;
}
.team-content .table{
  font-size: 14px;
  line-height: 22px;
}
/* .team-content .table .match-time-row th{
  width: 250px;
} */
.team-content .table .match-time-row th,.team-content .table .match-time-col td{
  width: 100%;
}
/* .team-content .table .match-time-row th:first-child{
  width: 5%;
} */
.team-content .table .match-time-col td{
  padding: 0;
}
.team-content .table .match-time-col td .match-time-inner{
  white-space: nowrap;
  padding: 10px 24px;
}
.team-content .table .match-time-col td .match-time-inner:nth-child(even){
  background: rgba(18, 78, 37, 0.04);
}
.team-content .table .match-time-col a{
  color: #666666;
  cursor: pointer;
}
/* .tooltip-coordinates tr td:nth-child(3) .player-tooltip,
.tooltip-coordinates tr td:nth-child(4) .player-tooltip,
.tooltip-coordinates tr td:nth-child(5) .player-tooltip,
.tooltip-coordinates tr td:nth-child(6) .player-tooltip,
.tooltip-coordinates tr td:nth-child(7) .player-tooltip,
.tooltip-coordinates tr td:nth-child(8) .player-tooltip,
.tooltip-coordinates tr td:nth-child(9) .player-tooltip,
.tooltip-coordinates tr td:nth-child(10) .player-tooltip{
  left: initial;
  right: -20px;
}
.tooltip-coordinates tr td .player-tooltip,
.tooltip-coordinates tr td .player-tooltip{
  bottom: initial;
  top: 40px;
  right: initial;
} */
.tooltip-coordinates tr td:nth-child(3) .player-tooltip,
  .tooltip-coordinates tr td:nth-child(4) .player-tooltip,
  .tooltip-coordinates tr td:nth-child(5) .player-tooltip,
  .tooltip-coordinates tr td:nth-child(6) .player-tooltip,
  .tooltip-coordinates tr td:nth-child(7) .player-tooltip,
  .tooltip-coordinates tr td:nth-child(8) .player-tooltip,
  .tooltip-coordinates tr td:nth-child(9) .player-tooltip,
  .tooltip-coordinates tr td:nth-child(10) .player-tooltip{
    left: -50px;
    margin-left: 0;
    right: initial;
  }
  .tooltip-coordinates tr td:nth-last-child(1) .player-tooltip{
 margin-left: -30px;
  }
.team-content .table td .saprater{
  margin: 0 8px;
  color: #858585;
  font-family: 'robotobold_italic'
}
.team-content .table thead{
  background: rgba(18, 78, 37, 0.24);
}
.team-content .table thead th{
  border-bottom: 0;
  font-family: "robotomedium";
  color: #124e25;
  font-weight: 500;
  font-size: 14px;
}
.team-content .table thead th span.team{
  position: absolute;
  left: 17px;
}
.team-content .table td,
.team-content .table th{
  padding:8px 8px;
  border:none;
  white-space: normal;
}
.team-content .table td a{
  color: #212529;
  cursor: pointer;
  position: relative;
}

.team-content .table td a.active{
  text-decoration: none;
  color: #333333;
  font-family: "robotoregular";
  text-transform: capitalize;
}
.team-content .table td a:hover{
  text-decoration: underline;
  color: #b52830;
}
.team-content .table tbody tr:nth-child(even){
  background: rgba(18, 78, 37, 0.04);
}
/* .match-time-row{
  width: 100%;
  background: rgba(18, 78, 37, 0.04);
} */
.player-match-row{
display: grid;
}
.top-head .top-title{
  font-size: 18px;
  line-height: 20px;
  font-weight: bold;
  color: #fff;
}
.top-head .top-title .small-title{
  color: rgba(255, 255, 255, 0.6);
  font-size: 13px;
  line-height: 20px;
  font-weight: normal;
  margin-top: 5px;
}
.top-head .back{
  margin-right: 20px;
  margin-top: 5px;
  cursor: pointer;
}
.top-head a{
  background: #fff;
  border-radius: 6px;
  padding: 11px 14px;
  font-size: 0;
  line-height: 20px;
  color: #4b4b4b;
  font-family: "robotomedium";
  height: 40px;
}
.top-head a span{
  width: 12px;
  height: 12px;
  box-shadow: 0 0 4px 0 rgb(237 45 45 / 60%);
  background-color: #e53935;
  border-radius: 50%;
}

.edit-score-row {
  background: transparent !important;
  border-right: none !important;
  position: relative;
  /* top: 186px; */
  z-index: 999;
  /* right: 22px; */
}

.edit-score-cell {
  border-right: none !important;
}

.edit-scores {
  text-align: end;
  font-size: 14px;
  color: #b52830;
}

.edit-icon {
  cursor: pointer;
  display: inline-block;
  border: none !important;
  /* background-color: #ffffff !important; */
  color: #b52830;
}

.edit-icon.disabled {
  /* pointer-events: none; */
  display: inline-block;
  opacity: 0.5 ;
}

.edit-icon svg {
  fill: #b52830;
  display: inline-block;
  margin-right: 7px;
  height: 16px;
  width: 16px;
  cursor: pointer;
}

/* Hover effect */
.edit-icon:hover svg {
  fill: #9e0202; /* Change color on hover */
}

.edit-icon:hover span {
  color: #9e0202; /* Change color on hover */
}

.edit-icon.disabled:hover svg {
  fill: #b52830; /* Change color on hover */
}

.edit-icon.disabled:hover span {
  color: #b52830; /* Change color on hover */
}


.table td a:hover ~ .player-tooltip,
.table td a:hover .player-tooltip{
  display: inherit;
}
.text-green{
  color: #124e25 !important;
}
.text-red{
  color: #b52830 !important;
}
.text-gray{
  color: #666666;
}
.text-light-gray{
  color: #858585;
}
.font-medium{
  font-weight: 500;
}
.text-italic{
    font-style: italic;
}
.font-bold{
  font-weight: bold;
}
.highest-break{
  max-width: 450px;
  width: 100%;
  font-size: 13px;
  margin-top: 0px;
  text-align: end;
}
.player-tooltip.large{
  max-width: 620px;
  height: 180px;
  width: 620px;
  margin-left: -30px !important;
 top: 0;
}
.player-tooltip .or-col{
  font-size: 16px;
  line-height: 20px;
  color: #666666;
  padding: 0 22px;
  font-style: italic;
}
.player-tooltip .right-col,
.player-tooltip .left-col{
  width: 280px;
  height: 150px;
}
.player-tooltip{
  position: absolute;
  max-width: 338px;
  width: 100%;
  background: #fff;
  border-radius: 20px;
  z-index: 5;
  margin-left: -10px;
  height: 150px;
  box-shadow: 0 6px 16px 0 #124e258c;
  /* margin-top: -37px; */
  display: none;
  width: 338px;
}
.match-time-inner{
  position: relative;
}
.player-tooltip svg.bottom{
  position: absolute;
  right: 0;
  bottom: 0;
}
.player-tooltip svg.top-right{
  right: 0;
}
.player-tooltip svg.top-left{
  left: 0;
  top: 0;
  position: absolute;
}
.player-tooltip svg.top-right{
  position: absolute;
  right: 0;
  top: 0;
}
.player-tooltip .left-col .inside-content{
  padding-left: 0;
}
.player-tooltip .right-col .inside-content{
  padding-right: 0;
}
.player-tooltip .inside-content{
  padding: 20px;
  position: relative;
  z-index: 2;
}
.player-tooltip .title-top{
  color: #454545;
  font-size: 14px;
  line-height: 20px;
  font-family: "robotomedium";
  width: 100%;
  padding-top:10px;
}
.player-tooltip .title-top .text-gray{
  font-size: 13px;
  color: #666666;
  font-weight: normal;
  margin: 0 3px;
}
.player-tooltip .bottom-txt{
  font-size: 13px;
  line-height: 20px;
  color: #fff;
  margin-top: 20px;
  text-align: left;
}
.left-bottom-text{
  font-size: 13px;
  line-height: 20px;
  color: #fff;
  margin-top: 20px;
  text-align: right;
}

/* .left-bottom-text{
  font-size: 13px;
  line-height: 20px;
  color: #fff;
  margin-top: 70px;
  text-align: right;
  margin-bottom: 30px !important;
  position: absolute;
  margin-left: -135px;
} */

/* .leftplayer2-bottom-text{
  font-size: 13px;
  line-height: 20px;
  color: #fff;
  margin-top: 70px;
  text-align: left;
  margin-bottom: 30px !important;
  position: absolute;
  margin-left: -135px;
} */

.faq {
  padding: 1.5em 20px;
  text-align: left;
  overflow: hidden;
  width: 90%;
  margin: auto;
}
.faq .card {
  width: 30%;
  margin: auto;border: 1px solid rgba(172, 189, 214, 0.5);  
  box-shadow: 0px 4px 10px rgba(172, 189, 214, 0.5);
  border-radius: 10px;padding: 10px 10px;margin-bottom: 15px;
}
.faq .card span:before{display:none;}
.faq .card h3{font-weight: 600;
  font-size: 16px;margin-bottom: 0px;
margin-top: 10px;
  color: #b52830;
}
.videos-page .embed-responsive{
  border-radius: 0px 0px 10px 10px;
}
.modal-dialog.videos{    max-width:800px;
}
.faq .card .video:hover h3{color: #8b1118;}
.modal-dialog.videos .modal-content{border:none;}
.videos-page .flex  h3{margin-bottom:0px;  font-size: 16px;
  margin-top: 0px;    margin-left: 9px;   
    color: #b52830;}
  
    .videos-page .flex {
      padding: 10px 10px;
      border-bottom: 1px solid #eee;
      margin-bottom: -10px;
  }
.faq .card figure{position: relative;margin:0px;padding:0px;}
.faq .card figure img{width:100%;height:200px;object-fit: cover;}
.faq .card .video {
  padding: 0px;
  display:block;
}

.faq .card:hover {
  box-shadow: 0px 4px 10px rgb(89 89 89 / 50%);
}

.faq .card .video:hover .videoicon{opacity: 1;}
.faq .card .videoicon{  border: none;height: auto;    width: auto; position: absolute;
  left: 50%;
  top: 50%;
  opacity: 0.8;
  transform: translate(-50%, -50%);

}
.faq figure{    text-align: center;}
.faq figure img{width:80%;    border:1px solid #eee;
  border-radius: 10px;}
.faq h1{
  font-size: 30px;
  margin: 0px;
  font-weight: bold;
  color: #b52830;}
  .faq h6 {
    padding-top: 10px;margin-bottom: 1em;
  }

.faq .Collapsible span {
  padding: 13px 15px 13px 20px;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
  /* background: #ebb1b1; */
  display: block;
}
.faq .Collapsible .is-closed:after {
  content: "\f107";
  float: right;
  font: normal normal normal 14px/1 FontAwesome;
  margin-top: 2px;
  font-size: 20px;
}
.faq .Collapsible__contentInner p {
  font-size: 16px;
  color: #000;
  line-height: 1.5;margin-bottom: 7px;
}
.toggle {
  cursor: pointer;
}
.faq .Collapsible span:before {
  content: "\f059";
  float: left;
  font: normal normal normal 14px/1 FontAwesome;
  margin-top: 4px;
  font-size: 19px;padding-right: 8px;    color: #9b0101;

}
.faq .Collapsible .is-open:after {transform: rotate(180deg);content: "\f107";
  float: right;
  font: normal normal normal 14px/1 FontAwesome;
  margin-top:0px;
  font-size: 20px;}

  .faq figure{padding:10px 0;}
  .faq ul{padding:10px 0;margin-bottom: 0px;}
  .faq li{position: relative; font-size: 16px;
    color: #000;margin-left:1.5em;}
    .faq li:before {
      position: absolute;
      content: "";
      width: 8px;
      height: 8px;
      background: #9b0101;
      left: -20px;
      top: 8px;
      border-radius: 50%;
  }
  .faq .Collapsible {
  border: 1px solid #ededed;
    border-bottom: 2px solid #ededed;
    border-radius: 7px;    margin-bottom: 7px;
}
.faq .Collapsible__contentInner {
  padding: 12px 30px 12px 30px;
  border: 1px solid #eee;
  border-bottom: 0px;    border-top: 0px;
}

.player-tooltip .left-col .inside-content figure{
  border-color: #124E25;
}
.player-tooltip .inside-content figure{
  width: 86px;
  min-width: 86px;
  height: 112px;
  border:1.5px solid #B52830;
  background: #fff;
  overflow: hidden;
  border-radius: 112px;
}
.player-tooltip .inside-content figure img{
  height: 100%;
  border: 3px solid #fff;
  border-radius: 112px;
}
.player-tooltip .country-name{
  font-size: 13px;
  line-height: 15px;
  color: #666666;
  white-space: initial;
  height: 20px;
}
.player-tooltip .country-name-knockout{
  font-size: 13px;
  line-height: 15px;
  color: #666666;
  white-space: initial;
  line-height: 0px !important;
  height: 22px;
}
.player-tooltip .country-name .flag{
  /* width: 20px; */
  margin-right: 10px;
}
.player-tooltip .right-col .country-name .flag{
  margin-left: 10px;
  margin-right: initial;
}
.player-tooltip .right-col .bottom-txt{
  text-align: right;
}
.player-tooltip .right-content{
  padding-left: 15px;
}
.player-tooltip .right-col .right-content{
  padding-right: 15px;
  padding-left: initial;
  width: 180px;
}
div#knockout .player-tooltip .right-col .right-content {
  width: auto;
}
div#knockout .player-tooltip .right-col .right-content .title {
  width: 169px;
}
div#knockout .player-tooltip.large {
  top: -172px !important;
}
.player-tooltip .right-col .right-content .title{
  text-align: right;
}
.player-tooltip .right-content .title{
  color: #333333;
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
  margin-bottom: 7px;
  text-align: left;
  height: 26px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  inline-size: 199px;

}
.collapse ~ .whens{
  display: none !important;
}
.collapse.show ~ .whens{
  display: flex !important;
}
.whens{
  position: absolute;
  right: 25px;
}
span.tooltiptext.toolwhen {
  left: -209px;    bottom: -46px;

}
.group-team{
  font-size: 11px;
  line-height: 20px;
  color: #666666;
  max-width: 430px;
  width: 100%;
  position: relative;
  top: 10px;
  margin-bottom: 15px;
}
.group-team .col-sm-4{
  width: 33.33%;
  padding: 0 6px;
}
.group-team strong{
  color: #000000;
}
.result-search-section{
  margin-top: 15px;
}
.result-search-section .form-control{
  border:1px solid #cccccc;
  border-radius: 4px;
  background: transparent !important;
  font-size: 13px;
  line-height: 20px;
  color: #858585 !important; 
  height: 32px;
  width: 100%;
  padding: 5px 12px;
}
.result-search-section svg{
  position: absolute;
  right: 12px;
  top: 13px;
}
div#playersSchedule .date-col a {
  min-width: 114px;
}
.date-col{
  padding: 0 16px 14px 16px;
  overflow-x: auto;
  overflow-y: hidden;
}
.date-col a{
  font-size: 13px;
  line-height: 20px;
  color: #666666;
  padding: 7px 20px;
  border-radius: 35px;
  margin-right: 10px;
  background: #ebebeb;
  white-space: nowrap;
}
.date-col a.active,
.date-col a:hover{
  background: #b52830;
  color: #fff;
  text-decoration: none;
}
.position-group .table thead th{
  white-space: initial;
  font-size: 13px;
  line-height: 17px;
  vertical-align: inherit;
}
/* V2 4.) Players Schedule css End here -------------------- */

/* V2 5.) Live Scoring css Start here -------------------- */
.live-scoring{
  padding-bottom:30px;
}
.live-scoring .top-links{
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
  line-height: 20px;
  padding: 0 15px;
}
.live-scoring .top-links a{
  color: #fff;
}
.live-scoring .total-matches{
  font-size: 16px;
  line-height: 20px;
  color: #fff;
}
.live-scoring .total-matches span{
  color: rgba(255, 255, 255, 0.6);
}
.live-scoring .live-heading{
  font-size: 24px;
  line-height: 24px;
  color: #fff;
  margin-top: 7px;
}
.live-scoring .white-col{
  background: #fff;
  border-radius: 8px;
  position: relative;
  margin-bottom: 16px;
}
.live-scoring .white-col .left-bottom{
  position: absolute;
  bottom: 10px;
  left: 19px;
}
.live-scoring .white-col .right-bottom{
  position: absolute;
  bottom: 0;
  right: 14px;
}
.live-scoring .white-col .live-top-head{
  border-radius: 8px 8px 30px 30px;
  padding: 18px 20px 11px;
  box-shadow: 0 3px 6px 0 rgba(18, 78, 37, 0.1);
}
.live-scoring .white-col .live-top-head .table-text {
  padding-left:40px;
}
.live-scoring .white-col .live-top-head .top-head-logo svg{
  width: 87px;
  height: auto;
}
.live-scoring .white-col .live-top-head .live-social-icons{
  font-size: 13px;
  line-height: 20px;
  color: #858585;
  z-index: 1111;
}
.live-scoring .white-col .live-top-head .live-social-icons a{
  margin-left: 10px;
}
.match-status-left .title{
  font-size: 16px;
  line-height: 21px;
  color: #333333;
  font-weight: bold;
  margin-bottom: 3px;
}
.match-status-left .small-title{
  font-size: 13px;
  line-height: 20px;
  color: #666666;
}
.inner-container .match-status {
  border: 0px solid #124E25;
  border-radius: 0px;
  margin-top: 12px;
  margin-bottom: 0px;
  max-width: 667px;
  width: 100%;
  padding-top: 0px;
  position: relative;
  overflow: hidden;
  /* background: #f4d341; */
}
.inner-container .vs-match span{
  font-style: italic;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  color: #858585;
}
.match-col-left.refreescore figure {
  margin: auto !important;
}
/*.player-center-col .match-col-left{width:38%;}*/
.inner-container .vs-match{
  padding: 0 10px;
  width: 25%;    text-align: center;
}
.player-center-col .match-col-left .left-content {
  width: 60%;
}
.inner-container .vs-match .time{
  font-size: 13px;
  line-height: 15px;
  font-family: "robotomedium";
  color: #333333;
  margin-top: 4px;
}
.inner-container .match-status iframe{
  width: 100%;
  border:none;
  max-height: 115px;
}
iframe body{
  background: transparent;
  padding: 0;
  margin: 0;
  overflow: hidden;
}
.inner-container .match-status span.live-ico {
    width: 10px;
    height: 10px;
    box-shadow: 0 0 4px 0 rgb(237 45 45 / 60%);
    background-color: #e53935;
    border-radius: 50%;
    margin-right: 11px;
    position: absolute;
    left: 12px;
}
.inner-container .match-status .title{
  font-size: 13px;
  line-height: 15px;
  color: #666666;
}
.frame-title{
  font-size: 9px;
  display: none;
  line-height: 10px;
  color: #333333;
  text-transform: uppercase;
  margin-bottom: 2px;
  text-align: center;
  font-weight: normal;
}
.inner-container .frame-col .win-col{
  font-size: 12px;
  line-height: 14px;
  font-family: "robotomedium";
}
.match-status .gray{
  font-size: 14px;
  color: #858585;
  margin: 0 2px;
}
.inner-container .match-status .total-match-play{
  color: #fff;
  font-size: 18px;
  line-height: 21px;
  font-weight: bold;
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
  min-width: 57px;
  margin: 0 10px;
}
.inner-container .match-status .total-match-play svg{
  position: absolute;
  z-index: 1;
}
.inner-container .match-status .total-match-play span{
  position: relative;
  z-index: 2;
}
.table-text{
  font-size: 13px;
  line-height: 20px;
  color: #666666;
}
.live-scoring .white-col .inner-container{
  padding: 22px 10px;min-height: 222px;
}
.live-scoring .match-col-left figure{
  height: 74px;
  width: 74px;
  border-radius: 50%;
  overflow:hidden;
  border: 1.5px solid #124e25;
  padding: 3px;
  margin:0; 
  margin-left: 15px;
}
.live-scoring .match-col-left.col-right figure{
  margin-left: initial;
  margin-right:15px;
}
.live-scoring .match-col-left figure img{
  border-radius: 50%;
  height: 100%;
}
.live-scoring .match-col-left .player-name{
  font-size: 14px;
  line-height: 16px;
  color: #b52830;
  font-family: "robotomedium";
  margin-bottom: 3px;
}
.live-scoring .match-col-left .title{
  font-size: 17px;
  line-height: 20px;
  color: #333333;
  font-weight: bold;
  margin-bottom: 15px;
}
.live-scoring .player-center-col .match-col-left .title{width:130px;    word-wrap: break-word;
}
.live-scoring .player-center-col .match-col-left.refreescore .title{width:unset;text-align: center;}
.live-scoring .match-col-left .country-name{
  font-size: 13px;
  line-height: 20px;
  color: #666666;
}
.live-scoring .match-col-left .country-name .flag{
  margin-left: 12px;
}
.live-scoring .match-col-left .country-name .flag img{font-size:20px !important;}
.live-scoring .match-col-left.col-right .country-name .flag{
  margin-left: initial;
  margin-right: 12px;
}
.live-scoring .match-col-left .country-name .flag svg{
  width: 32px;
}
.match-status .saprater{
  height: 32px;
  width: 1px;
  background: #999999;
  transform: rotate(14deg);
  display: inline-table;
}
.match-status .saprater.left{
  margin-right: 10px;
}
.match-status .saprater.right{
  margin-left: 10px;
}
/*.light-green{
  background: #c7d5cb;
}*/
.light-green{
  background: #c7d5cb;
  font-size: 13px;
  line-height: 20px;
  padding: 0 6px 0 12px;
  min-height: 36px;
  line-height: 18px;
  margin-bottom: 2px;
}
.light-green .sno,
.light-gray .sno{
  font-size: 14px;
  color: #333333;
  margin-right: 10px;
}
.light-green .match-win,
.light-gray .match-win{
  font-size: 16px;
  color: #333333;
  font-family: "robotomedium";
  width: 32px;
  border-left: 1px solid #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.light-gray{
  background: #ebebeb;
  font-size: 13px;
  line-height: 20px;
  padding: 0 6px 0 12px;
  min-height: 36px;
  line-height: 18px;
}
.light-gray a span.span-title,
.light-green a span.span-title{
  display: flex;
  align-items: center;
  height: 100%;
  padding: 3px 5px 3px 0;
}
.second-row-margin .mt-6{
  margin-top: 45px;
}
.second-row-margin .mb-12{
  margin-bottom: 107px;
}
.third-row-margin .mt-8{
  margin-top: 136px;
}
.third-row-margin .mb-15{
  margin-bottom: 107px;
}
.fourth-row-margin .mt-12{
  margin-top: 136px;
}
.fourth-row-margin .mb-15{
  margin-bottom: 107px;
}
.knockout-container{
  padding: 0 16px 14px 16px;
}
.knockout-container .table td,
.knockout-container .table th{
  border-right: 1px solid rgba(18, 78, 37, 0.24);
}
.knockout-container .table td:last-child,
.knockout-container .table th:last-child{
  border: none;
}
.knockout-container .table-container{
  border: solid 1px rgba(18, 78, 37, 0.24);
}
.knockout-container .table-container .table{
  margin-bottom: 0;
}
.knockout-container-head .custom-col{
  background: rgba(18, 78, 37, 0.24);
  font-size: 14px;
  line-height: 20px;
  color: #124e25;
  padding: 8px 12px;
  font-family: "robotomedium";
}
.table-knockout-justify{
  vertical-align: middle !important; 
}
.results-modal{
  z-index: 1060;
}
.results-modal.results-height{overflow-y:unset;}
.results-modal.results-height .table-container {
  max-height: 435px;
  overflow-y: auto;
}
.results-modal-container{
  padding: 20px;
  background: #fff;
  border-radius: 20px;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  top: 50%;
  position: relative;
  transform: translateY(-50%);
  box-shadow: 0px 5px 100px -15px grey;
  max-height: 635px;
}
.results-modal-container .title{
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 4px;
  line-height: 20px;
  color: #333333;
}
.results-modal-container .date-time{
  font-size: 16px;
  line-height: 20px;
  color: #333333;
  font-family: "robotomedium";
  margin-bottom: 7px;
}
.results-modal-container .table-container{
  border: 1px solid #c7d5cb;
  margin-top: 5px;
  max-width: 928px;
}
.results-modal-container .table-text{
  font-size: 13px;
  line-height: 20px;
  color: #858585;
}
.team-content .left-player-info{
  margin-top: 15px;
}
/*.team-content .left-player-info{
  max-width: 49%;
  flex: 0 0 49%;
}*/
.team-content .left-player-info .table{
  /* border: 1px solid #c7d5cb; */
  margin-bottom: 0;
}
.team-content .left-player-info .table td,
.team-content .left-player-info .table th{
  border-right: 1px solid #b8c7bc;
}
.team-content .left-player-info .table td:last-child,
.team-content .left-player-info .table th:last-child{
  /* border-right: none; */
}
.team-content .left-player-info .table td:nth-child(3){
  font-size: 14px;
  font-weight: bold;
}
.team-content .left-player-info .table td:nth-child(2){
  font-size: 14px;
  font-weight: bold;
}
.team-content .right_player-info .table td:nth-child(3){
  font-size: 14px;
  font-weight: bold;
}
.team-content .right_player-info .table td:nth-child(2){
  font-size: 14px;
  font-weight: bold;
}
.team-content .left-player-info .table .total-matches-play{
  background: transparent;
  border-top: 1px solid #c7d5cb;
}
.tableView table{
  font-size: 14px;
  line-height: 22px;
}
.tableView table th,
.tableView table td{
  border-right: 1px solid #b8c7bc;
  padding: 8px 8px;
  white-space: normal;
  border-top: none;
  text-align: center;
  word-break: break-all;
}
.tableView .table thead tr{
  background: rgba(18, 78, 37, .24);
}
.tableView .table thead tr.top-head{
  background: #fff;
}
.tableView .table thead tr.top-head td{
  border: none;
  padding: 0px 15px;
  vertical-align: middle;
}
.tableView .table thead tr.top-head .player-title{
  color: #124e25;
  font-size: 16px;
  font-weight: 600;
}
.tableView .player-details .player-name{
  font-size: 14px;
  margin-bottom: 6px;
  color: #b52830;
  line-height: 16px;
}
.tableView .player-details .content-left{
  margin-right: 15px;
}
.tableView .player-details .content-right {
  margin-left: 15px;
}
.tableView .player-details.left-player{
  margin-right: 15px;
  position: relative;
}
.tableView .player-details.left-player:after{
  content: 'vs';
  position: absolute;
  font-size: 16px;
  line-height: 20px;
  color: #858585;
  font-style: italic;
  font-weight: 700;
  top: 50%;
  transform: translateY(-50%);
  right: -35px;
}
.tableView .player-details.right-player{
  margin-left: 15px;
}
.tableView .player-details.right-player figure{
  border-color: #124e25;
}
.tableView .player-details.right-player .player-name{
  color: #124e25;
}
.tableView .player-details .title{
  color: #333;
  margin-bottom: 3px;
  font-size: 20px;
  line-height: 24px;
  font-family: "robotomedium";
  min-height: 24px;
  font-weight: 700;
}
.tableView .player-details figure{
  width: 93px;
  min-width: 93px;
  height: 93px;
  min-height: 93px;
  border: 1.5px solid #b52830;
  background: #fff;
  overflow: hidden;
  border-radius: 112px;
  /* margin-bottom: 0; */
  margin: 10px 0px;
}
.tableView .player-details figure img{
  height: 100%;
  border: 3px solid #fff;
  border-radius: 112px;
}
.tableView .player-details .country{
  font-size: 13px;
  color: #666;
  line-height: 20px;
  min-height: 20px;
}
.tableView table th {
  border-bottom: 0;
  font-family: "robotomedium";
  color: #124e25;
  font-weight: 500;
}
.tableView table th.w-235{
  min-width: 225px;
  max-width: 225px;
  width: 225px;
}
.tableView table th.w-70 {
  min-width: 70px;
  max-width: 70px;
  width: 70px;
}
.tableView table th.w-105 {
  min-width: 105px;
  max-width: 105px;
  width: 105px;
}

.tableView table th.w-125 {
  min-width: 230px;
  max-width: 230px;
  width: 230px;
}

.tableView table th.w-85 {
  min-width: 85px;
  max-width: 85px;
  width: 85px;
}

.tableView table tbody tr:nth-child(2n){
  background: rgba(18, 78, 37, .04);
}
.tableView .total-score{
  background: #fff;
  border-top: 0.5px solid #b8c7bc;
}
.remove-firstChild tr td:first-child,
.remove-firstChild tr th:first-child{
  display: none !important;
}
.display-b{
  display: none;
}
.remove-firstChild .display-b{
  display: table-cell;
}

.player-info{
  /* border: 1px solid #c7d5cb; */
  /* border-bottom: none; */
  padding: 16px 0;
  padding-bottom: 0;
  position: relative;
  max-width: 949px;
}
.custome-reponsive-duration{
  min-width: 1160px !important;
  /* min-width: 964px !important; */
}
.player-info .left-player{
  max-width: 49%;
  flex: 0 0 49%;
}
.player-info .vs-col{
  max-width: 10%;
  flex: 0 0 10%;
}
.player-info .right-player{
  max-width: 49%;
  flex: 0 0 49%;
  margin-left: auto;
}
.player-info .right-player .info-right{
  margin-left: 30px;
}
.player-info .left-player .info-left{
  margin-right: 30px;
}
.player-info .right-player figure{
  border-color: #124E25;
}

.player-info .frame-duration{
  max-width: 24%;
  flex: 0 0 49%;
  margin-left: auto;
}
.player-info .frame-duration .info-right{
  margin-top: 49px;
}
.player-info .left-player .info-left{
  margin-right: 30px;
}
.player-info .frame-duration figure{
  border-color: #124E25;
}
.frame-duration-center{
  padding: 8px !important;
}
.player-info .frame-duration .vs-col {
  left: 60% !important;
  top: 27px !important;
}

.frame-duration .player-title.match-time {
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 600;
  color: #124e25;
}

.frame-duration .right_player-info{
margin-top: 7px !important;
}
.frame-duration thead
{
  border-right: 1px solid #b8c7bc;
}
.match-duration-time {
  font-size: 14px;
  line-height: 20px;
  font-family: "robotomedium";
  margin-bottom: 1px;
  color: #b52830!important;
  margin-top: 10px;
}
.player-info figure{
  width: 93px;
  min-width: 93px;
  height: 93px;
  min-height: 93px;
  border:1.5px solid #B52830;
  background: #fff;
  overflow: hidden;
  border-radius: 112px;
  margin-bottom: 0;
}

.match-time-code{
  min-height: 94px;
}

.player-info figure img{
  height: 100%;
  border: 3px solid #fff;
  border-radius: 112px;
}
.player-info .player-title{
  font-size: 14px;
  margin-bottom: 6px;
  color: #b52830;
  line-height: 16px;
}
.player-info  .right-player .player-title{
  color: #124e25;
}
.player-info  .right-player .content-left{
  margin-left: 15px;
}

.player-info  .frame-duration .player-title{
  color: #124e25;
}
.player-info  .frame-duration .content-left{
  margin-left: 15px;
}

.player-info .left-player .content-left{
  margin-right: 15px;
}
.player-info .title{
  color: #333333;
  margin-bottom: 3px;
  font-size: 20px;
  line-height: 24px;
  font-family: "robotomedium";
  min-height: 24px;
}
.player-info .country{
  font-size: 13px;
  color: #666666;
  line-height: 20px;
}
.player-info .vs-col{
  font-size: 13px;
  line-height: 20px;
  color: #858585;
}
.player-info .vs-col{
  font-size: 16px;
  line-height: 20px;
  color: #858585;
  font-style: italic;
  font-weight: bold;
  position: absolute;
  left: 73%;
  transform: translateX(-50%);
  top: 60px;
}
.no-scoring{
  font-size: 16px;
  margin:40px 0px;
  color: #b52830;
  line-height: 16px;
}
.results-modal-container .close{
  position: absolute;
  right: 18px;
  top: 18px;
}
.modal-open .modal.results-modal{
  background: rgba(0,0,0,0.3);
  padding: 0 15px;
}
.modal-backdrop + .modal-backdrop{
  display: none !important;
}
.main-content{
  position: relative;
  z-index: 8;
}
.group-details-container{
  background: #ffffff;
  max-width: 960px;
  position: absolute;
  right: 0;
  width: 100%;
  height: calc(100% - 225px);
  overflow: auto;
  bottom: 0;
  z-index: 1;
}
.groupDetails-modal{
  z-index: 1;
}
.sqbx{
  position: relative;
}
.team-content .table thead th.teams{  padding: 10px 6px !important;
  margin-left: 4px;
  text-align: center;}
td.text-center.sqbx {
  padding: 6px 6px !important;
  margin-left: 4px;
  text-align: center;
}

.team-content .table td .blank-bx{
  height: 30px;
  width: 30px;
  background: #c7d5cb;
  display: inline-block;
  /*position: absolute;
  left: 5px;
  top: 5px;*/
  display: flex;
  align-items: center;
  justify-content: center;
}
.team-content .group-details-container .heading{
  padding-bottom: 0;
}
.team-content .group-details-container .heading .close{
  align-self: flex-start;
  opacity: 1;
}
.team-content .group-details-container .match-heading{
  font-size: 13px;
  line-height: 20px;
  color: #454545;
  padding: 8px 20px;
  background: rgba(18, 78, 37, 0.24);
}

.demo-app .searchss {
  display: flex;
  justify-content:space-between;
  margin-bottom: 17px;
  align-items: center;
}
.demo-app .searchss .pasba-admin {
  display: flex;
  align-items: center;
  padding-left: 5px;
}
.demo-app .searchss .pasba-admin span {
  width: 28%;
}
.otherPlayerDetail{
  background-color: #f0f0f0;
  padding: 8px 12px;
}
.calenderUiAlign{
  top: 20px !important;
  left: 3px;
}
.otherMemberCalender{
margin-left: 30px;
}
.otherPlayerModal .modal-content {
  padding: 20px !important;
  width: 85% !important
}
.otherPlayerModal .modal-dialog{
  top : 30% !important;
  left: 5% !important;
}
.timeOtherPlayer{
  left: 4px !important;
}
.calenderDuration{
  top: 9px;
}
.closeOtherPlayeModal{
    font-size: 24px;
    background: none;
    border: none;
    position: relative;
    top: -7px;
    color: #8f8d8d;
}
.selectMember{
  border: none;
  background-color: #f0f0f0;
}
.playerCalender .fa {
  font-weight: 400;
}
.playerCalender .otherMemberCalender {
  margin: 0px;
  width: 49%;
}
/* .playerCalender  .modal-footer{border-top: 0px;} */
.playerCalender select.form-control {
  background: none;
  position: relative;
  color: #333333;
  border: unset;
  padding: 0px 16px;
  /* border-bottom: 3px solid #fff;   */
    height: 35px;
}
.playerCalender .playerdates {
  width: 48%;
  z-index: 1;
}
.playerCalender .playerdates .position-relative select.form-control{
  /* background: #f0f0f0; */
  border-bottom: 2px solid #000;
}

.playerCalender .playerdates .position-relative .add-icon {
  top: 3px;
  right: 3px;
  z-index: -1;
}
.playerCalender .createSlot {
  font-style: italic;
}

.playerCalender .playerdates .plus-icon-player.add-icon .fa.fa-sort-desc {
  font-weight: 600;
  font-size: 18px;
}
.playerCalender .playerdates span {
  padding: 0px 0px;
  font-size: 26px;
}
.playerCalender .modal-dialog{
  top: 20% !important;
}
.playerCalender .modal-content{
  padding: 10px;
}
.widthSort{
  position: absolute;
  padding: 0;
  margin: 2px 0 0 0;
  background: #ffffff;
  z-index: 2;
  width: 50%;
  border: 1px solid #ced4da;
  border-radius: 3px;
  max-height: 300px;
  overflow-y: auto;
}
.widthSort li{
  padding: 5px 10px;
  cursor: pointer;
}
.widthSort li:hover{
  background: rgba(0, 0, 0,0.1);
}

.btnfontSize{
  font-size: 14px;
}
.notVailableTable{
  padding: 30px 0px;
}

.thanksBtn{
  font-size: 16px !important;
  line-height: 15px !important;
}
.fc-day-today {
  background-color: white !important;
}
.fc-time{
  display : none;
}

.fc-timegrid-now-indicator-line {
  border-color: black;
  box-shadow: 0px -2000px 0px 2000px rgba(0, 0, 0, 0.1);
}

.fc-timegrid-event{
  width: 103% !important;
}
/* V2 5.) Live Scoring css End here -------------------- */

/* V2 6.) Manage Club Table css Start here -------------------- */

.custom-table .cls-11{fill:gray;stroke:#fff;stroke-width:.2px}
.custom-table .custom-col-1{
  max-width: 40%;
  flex: 0 0 40%;
  padding: 0 10px;
}
.custom-table .custom-col-2{
  max-width: 40%;
  flex: 0 0 40%;
  padding: 0 10px;
  padding-right: 40px;
}
.custom-table .custom-col-3{
  max-width: 20%;
  flex: 0 0 20%;
  padding: 0 10px;
}
.add-new-table{
  background: #b52830;
  color: #fff;
  font-size: 16px;
  border-radius: 20px;
  height: 40px;
  line-height: 1;
  font-family: "robotomedium";
  padding: 0 25px;
}
.add-new-table:hover{
  color: #fff;
  text-decoration: none; background-color: #8b1118;
}
.add-new-table svg{
  margin-right: 10px;
}
.custom-table.hourly-table .custom-col-1{
  max-width: 30%;
  flex: 0 0 30%;
  padding: 0 10px;
  display: flex;
  align-items: center;
}
.custom-table.hourly-table .custom-col-2{
  max-width: 30%;
  flex: 0 0 30%;
  padding: 0 10px;
  padding-right: 40px;
}
.custom-table.hourly-table .custom-col-3{
  max-width: 40%;
  flex: 0 0 40%;
  padding: 0 10px;
}
.custom-table.hourly-table .divbody .table-row{
  padding: 5px 14px;
}
.custom-table.hourly-table .bx-col{
  min-width: 122px;
  justify-content: center;
}
.custom-table.hourly-table .bx-col .tables {
  border: 1px solid #cccccc;
  box-shadow: none;
  display: flex;width: 124px;
}
.custom-table.hourly-table .bx-col .tables .currency-col {
  border: none;
  border-right: 1px solid #cccccc;
}
.custom-table.hourly-table .bx-col .tables .price-col {
  border: none;
}

.timePeriodError{
  border:1px solid #b52830;
  box-shadow: 0px 0px 3px -1px #b52830;
}

.hourly-table-errorshow{
  position: absolute;
  width: 161%;
  text-align: center;
  top: -56px;
  left: 433px;
}

.default-price{
  position: absolute;
  top: 29px;  
  margin-bottom: 3px;  
}

.errorshow {
  position: absolute;
  top: 9px;
  text-align: center;
  width: 100%;
  left: 255px;
}
.custom-table.hourly-table .bx-col .tables.error {
  display: flex;
  border:1px solid #b52830;
  box-shadow: 0px 0px 3px -1px #b52830;
  width: 124px;
}

.custom-table.hourly-table .bx-col .currency-col{
  height: 32px;
  width: 40px;
  font-size: 15px;
  line-height: 18px;
  color: #858585;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #cccccc;
  background: #f5f5f5;
}
.custom-table.hourly-table .bx-col .price-col{
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  height: 32px;
  border: 1px solid #cccccc;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-left: none;
  width: 105px;
  padding: 0 12px;
}

.errorMssg{
  top: 30px;
  position: absolute;
  width: 100%;
  right: 0;
  font-family: 'robotobold'
}
.table-input-error{
  border: 1px solid #b52830 !important;
}

.slotErrormsg {
  font-size: 10px !important;
}
/* .add-table-form .table-row .table-input-error{
  border: 1px solid #b52830;
} */
.custom-table .divhead .table-row,
.custom-table .divbody .table-row{
  background: #fff;
  padding: 8px 14px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
}
.custom-table .divhead .table-row{
  border-radius: 7px 7px 0 0;
}
.custom-table .divbody .table-row{
  border-radius: 7px;
  margin-top: 6px;
  font-size: 14px;
  padding: 10px 14px;
  line-height: 24px;    word-break: break-all;

}
/* manage ranking table css start*/
.bt-1{
    flex: 0 0 32% !important;
}

@media (max-width: 768px) {
  .bt-1 {
    flex: 0 0 33% !important;
    padding-right: 0px !important;
  }
}
/* manage ranking table css end*/

.custom-table .divhead{
  font-size: 16px;
  color: #124e25;
  font-weight: bold;
}

.custom-table .action-btn,
.custom-table .action-head{
  position: relative;
}
.custom-table .action-btn .tool-btn{
  margin: 0 15px;
  /* position: relative; */
}
.custom-table .action-btn .tool-btn[aria-expanded="true"] svg path.red-fill{
  fill: #f9d81c;
}
.light-controls-tool:hover, .custom-table .edits:hover{cursor: pointer;}
.custom-table .action-btn .tool-btn:hover svg path{
  opacity: 0.6;
}
.custom-table .edits:hover svg path {
  opacity: 0.6;
}
.custom-table .action-btn:before,
.custom-table .action-head:before{
  content: '';
  width: 5px;
  height: 44px;
  position: absolute;
  left: -30px;
  top: -10px;
  background: #eeeeee;
}
.custom-table .action-head:before{
  width: 1px;
  left: -28px;
  height: 40px;
  top: -8px;
}
.add-table a{
  font-size: 14px;
  line-height: 16px;
  color: #b52830;
  font-weight: 600;
  text-decoration: none;
}
.add-table a span{
  font-size: 24px;
  line-height: 28px;
  margin-right: 6px;
  font-weight: 500;
}
.share-iframe{
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  width: 156px;
  height: 40px;
  border-radius: 40px;
  font-size: 14px;
  color: #333333;
  font-weight: 600;
  margin-right: 40px;
}
.share-iframe svg{
  margin-right: 6px;
}
.tooltip div{
  opacity: 0.8;
  font-size: 12px;
  line-height: 20px;
}

.signout-tooltip{
  background: #fff;  
  box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.37);
  min-width: 210px;
  border-radius: 6px;
  padding: 10px 0;
  margin-top: 12px;
  margin-left: -30px;
  z-index: 10;
}
.signout-tooltip.light-control{
  min-width: 160px;
}

/* .dropdown-menu{z-index:10} */
.tool-tip-arrow::after {
  bottom: 100%;
  left: 0;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
  border-width: 10px;
  margin-left: 30px;
}
.overlay-tooltip::after {
  left: 59px;
}
.light-tooltip::after {
  left: 34px;}
.signout-tooltip[x-placement="top-start"]::after{
  top: 100%;
  bottom: initial;
  transform: rotate(180deg);
}
.signout-tooltip[x-placement="top-start"]{
  margin-top: 0;
}
.signout-tooltip ul{
  margin: 0;
  padding: 0;
}
.signout-tooltip ul li{
  padding: 6px 16px;
}
.signout-tooltip ul li:hover{
  background: rgba(181, 40, 48, 0.08);
}
.signout-tooltip ul li:hover a{
  text-decoration: none;
  color: #b52830;
}
.signout-tooltip ul li a{
  margin: 0 !important;
  color: #333333;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.25px;
}
.tool-tip-arrow.signout-right-tooltip::after{margin-left: 55px;}
.signout-tooltip{
  display: none;
}
.active.signout-tooltip{
  display: block;
}
.tool-btn.dropdown-toggle::after{
  display: none;
} 

/* .overlay-tooltip{right: -5px;top: -81px;}
.overlay-tooltip:after {
  bottom: -19px;
  right: 10px;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #ffffff;
  border-width: 10px;
} */
.share-iframe-modal .close{
  position: absolute;
  right: 14px;
  top: 14px;
  opacity: 1;
}
.share-iframe-modal h5{
  text-align: center;
  font-size: 22px;
  line-height: 25px;
  color: #000000;
}
.share-iframe-modal .modal-header{
  margin-bottom: 27px;
  justify-content: center;
  border:none ;
  padding: 0;
}
.share-iframe-modal .modal-footer{
  border:none ;
  padding: 24px 0 0 0;
}
.share-iframe-modal .modal-footer .btn-gray,
.btn-col .btn-gray{
  padding: 0 30px;
  height: 39px;
  border-radius: 39px;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  background: #ebebeb;
  margin: 0;
  margin-right: 22px;
  border: none;
  font-family: "robotomedium";
}
.btn-col .btn-gray{
  margin-right: 16px;
}
.share-iframe-modal .modal-footer .btn-red:focus,
.btn-col .btn-red:focus{box-shadow: 0 0 0 0.2rem rgb(255 0 111 / 50%) !important;}
.share-iframe-modal .modal-footer .btn-red:hover,
.btn-col .btn-red:hover{ color: #ffffff;
  background-color: #8b1118;}
.share-iframe-modal .modal-footer .btn-red,
.btn-col .btn-red{
  height: 39px;
  border-radius: 39px;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  background: #b52830;
  margin: 0;
  border: none;
  font-family: "robotomedium";
  padding: 0 30px;
}
.btn-col .btn-red-outline:hover{background: rgb(229 57 53 / 9%);
}

.btn-col .btn-red-outline{
  height: 39px;
  border-radius: 39px;
  font-size: 16px;
  line-height: 19px;
  color: #e53935;
  background: rgba(229, 57, 53, 0.04);
  margin: 0;
  border: none;
  font-family: "robotomedium";
  padding: 0 30px;
  border: 1px solid #e53935;
  margin-right: 16px;
}
.share-iframe-modal .modal-body{
  padding: 0;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
}
.add-table-section .add-table-form{
  background: #fff;
  padding: 24px 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  margin-top: 12px;
  /* min-height: 620px; remove after lights on off removed */
}
.add-table-section .add-table-form .form-control{
  border-color: #cccccc;
  font-size: 15px;
}
.add-table-section .add-table-form label{
  font-size: 15px;
  line-height: 18px;
  color: #333333;
}
.add-table-section .add-table-form label span{
  color: #b52830;
}
.add-table-section .add-table-form .form-control.textarea-col{
  height: 104px;
}
.add-table-section .add-table-form .table-row{
  margin-bottom: 24px;
}
.add-table-section .add-table-form .d-flex.table-row {
  margin-bottom: 0px;
}
.add-table-section .add-table-form .d-flex.table-row {
  box-shadow: none;padding-left: 0px;    margin: 0px;
}
.add-table-section .btn-col .btn-gray{background:#fff;}
/* V2 6.) Manage Club Table css End here -------------------- */
#addhour_popup .modal-dialog {
  max-width: 650px;
}
#addhour_popup .table-row {
  margin-bottom: 26px;
}
.timeperiod a {
  display: flex;
  color: #b52830;
  font-size: 15px;
  font-weight: 600;
  text-decoration: unset;
  cursor: pointer;
}
.timeperiod a:hover{
  display: flex;
  color: #b52830;
  font-size: 15px;
  font-weight: 600;
  text-decoration: unset;
  cursor: pointer;
}
.createSlot:hover{
  cursor: pointer;
}
#addhour_popup .custom-table .divhead {
  border-top: solid 1px #ccc;
  border-bottom: solid 1px #ccc;    font-size: 14px;
}
#addhour_popup .custom-table .table-row {
  margin: 0px;
  box-shadow: none;
  padding-left: 0px;
  width: 100%;    padding-right: 0px;
}
.add-table-section .add-table-forms .table{margin-bottom:0px;box-shadow: 0px 2px 3px rgb(0 0 0 / 20%);}
.add-table-section .add-table-forms .table.tables {
  box-shadow: 0px 2px 3px rgb(0 0 0 / 20%);
  padding: 0px 10px;margin-top: 5px;
  background: #fff;
  border-radius: 31px;
  border-radius: 7px;

}

.add-table-section .add-table-forms td .d-flex {
  box-shadow: none;
}
.add-table-section .add-table-forms .d-flex{background-color: #fff;border-radius: 8px 8px 0px 0px;    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 16%)}
.add-table-section .add-table-form h4{margin-bottom: 0px;}
.add-table-section .add-table-forms h4{margin-bottom: 0px;}

.add-table-section .add-table-form .custom-table .divhead {
  font-size: 15px;
}
#addhour_popup .custom-table .table-row .custom-col-1 {
  padding: 0px;
}

#addhour_popup .table-row label span{    color: #b52830}
.add-table-section .add-table-forms th {
  color: #124e25;
  font-size: 15px;
      background: #fff;    padding-left: 1.6em;
  padding-right: 1.6em;    width: 20%;border-bottom: none;
}
.add-table-section .add-table-forms .table.bordernone td{background-color: #fff;}
.add-table-section .add-table-forms .table.bordernone th{
     border-bottom: none;
     font-size: 14px; color: #858585;
     font-weight: 500;
}
.add-table-section .add-table-forms .table.bordernone td {
  border: none;
  padding: 0.3rem 1.6rem;
}
.add-table-section .add-table-forms td {
     width: 20%;
  border:none;
  padding: 0.6rem 1.6em;font-size: 15px;
}
.add-table-section .add-table-forms .cols {
  display: flex;
  padding-top: 6px;
  padding-left: 10px;
}
/*.add-table-section .add-table-forms .cols i {
  transform: rotate(-135deg);
}
.add-table-section .add-table-forms .cols.collapsed i {
  transform: rotate(45deg);
}*/

.add-table-section .add-table-form .down{float: right;}
/* V2 7.) Food Beverage css Start here -------------------- */
.add-table-section .add-table-form .col .down {
  border-color: #b52830;
    margin-top: 9px;display: block;transform: rotate(-135deg);
} 
.add-table-section .add-table-form .collable a.col {color: #b52830;text-decoration: unset;    padding-right: 10px;
  
}

.add-table-section .add-table-form .col.collapsed:after {
  content: "Expand";
}
.add-table-section .add-table-form .col:after {
  content: "Collapse";
  font-weight: 900;
}

.add-table-section .add-table-form .col.collapsed .down{border-color: #b52830;
  margin-top: 6px;transform: rotate(45deg);}
.food-beverage .item-col{
  padding: 16px 20px;
  border-radius: 12px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  margin-top:5px;
  margin-bottom:5px;
}
.food-beverage .item-col.add-more-item a{
  font-size: 18px;
  line-height: 21px;
  font-weight: bold;
  color: #b52830;
  text-decoration: none;
}
.food-beverage .item-col.add-more-item a span{
  padding: 11px 24px;
  border-radius: 10px;
  background-color: #f8e9ea;
  font-size: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  height: 72px;
}
.food-beverage .item-col figure{
  height: 72px;
  width: 72px;
  border-radius: 10px;
  margin-right: 16px;
  overflow: hidden;
  margin-bottom: 0;
}
.food-beverage .item-col figure img{
  width: 100%;
  height: 100%;
}
.food-beverage .item-col .item-title-col .title{
  font-size: 18px;
  line-height: 21px;
  color: #333333;
  font-size: 18px;
  margin-bottom: 7px;
  font-weight: bold;       word-break: break-all;
  width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}
.food-beverage .item-col .item-title-col .price{
  font-size: 15px;
  line-height: 18px;
  color: #666666;
  
}
.food-beverage .item-col .edit-delete-col a{
  margin-left: 16px;
}
.food-beverage .item-col .edit-delete-col a:hover svg path{
  fill: #b52830;
}
.delete-iframe-modal p{
  font-size: 16px;
  line-height: 24px;
  color: #666666;
  margin: 0;
}
.delete-iframe-modal p span{
  color: #333333;
}
.delete-iframe-modal .title{
  font-size: 22px;
  line-height: 25px;
  color: #000000;
  font-weight: bold;
  margin-bottom: 10px;
}
.delete-iframe-modal .btn-gray,
.edit-food .modal-footer .btn-gray{
  padding: 0 30px;
  height: 39px;
  border-radius: 39px;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  background: #ebebeb;
  margin: 0;
  margin-right: 22px;
  border: none;
  font-family: "robotomedium";
}
.edit-food .title{
  text-align: center;
  font-size: 22px;
  line-height: 25px;
  color: #000000;
  margin-bottom: 32px;
  font-weight: bold;
}
.delete-iframe-modal .btn-red,
.edit-food .modal-footer .btn-red{
  height: 39px;
  border-radius: 39px;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  background: #b52830;
  margin: 0;
  border: none;
  font-family: "robotomedium";
  padding: 0 30px;
}
.delete-iframe-modal .modal-body,
.edit-food .modal-body{
  padding: 0;
}
.delete-iframe-modal .modal-dialog{
  max-width: 438px;
}
.delete-iframe-modal .modal-footer,
.edit-food .modal-footer{
  border:none;
  padding: 0;
  margin-top: 32px;
}
.edit-food .modal-footer{
  margin-top: 5px;
}
.edit-food .modal-dialog{
  max-width: 740px;
}
.edit-food .upload-image figure{
  margin-bottom: 0;
  max-width: 286px;
  width: 286px;
  flex: 0 0 286px;
  height: 286px;
  border-radius: 12px;
  overflow: hidden;
  border-style: dashed;
}
.edit-food .upload-image figure.dummy-image{
  background: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
}
.edit-food .upload-image figure img{
  height: 100%;
  width: 100%;
}
.edit-food .item-content{
  max-width: calc(100% - 310px);
  flex: 0 0 calc(100% - 310px);
  margin-left: 24px;
}
.edit-food .item-content label{
  font-size: 15px;
  line-height: 18px;
  color: #333333;
  margin-bottom: 8px;
  font-family: "robotomedium";
}
.edit-food .item-content textarea{
  height: 80px;
}
.edit-food .item-content .form-control{
  font-size: 15px;
  line-height: 18px;
}
.edit-food .item-content .price .currency-col{
  border: 1px solid #cccccc;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  line-height: 18px;
  color: #858585;
  width: 42px;
  border-radius: 3px 0 0 3px;
  border-right: 0;
}
.edit-food .item-content .price .form-control{
  border-radius: 0 3px 3px 0;
}
.edit-food .item-content .form-group:last-child{
  margin-bottom: 0;
}
.change-photo a{
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
  color: #b52830;
  margin-top: 15px;
}
.format-image{
  background: rgba(181, 40, 48, 0.04);
  border-radius: 12px;
  border: solid 1px rgba(181, 40, 48, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.format-image .upload-format{
  width: 66px;
  height: 66px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(181, 40, 48, 0.12);
  margin-bottom: 10px;
}
.format-image p{
  text-align: center;
  font-size: 12px;
  color: #8c8c8c;
  width: 200px;
}
/* V2 7.) Food Beverage css End here -------------------- */

/* ============ NEW UI CSS END HERE =========== */



/* 1.) Login page css start here -------------------- */


.login-form .form-control {
  font-family: "robotomedium";
  padding: 12px 18px;
  height: 44px;
}
.login-form input[type="password"].form-control {
  color: #333333;
}
.login-btn {
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
  font-family: "robotomedium";
  border-radius: 28px;
  background-color: #124e25;
  padding: 12px;
}
.login-btn:hover {
  color: #ffffff;
  background-color: #2c5c3b;
}
.login-btn:focus {
  box-shadow: 0 0 0 0.2rem rgb(44 92 59 / 66%);
}
.login-form label.form-check-label {
  font-size: 12px;
  line-height: 16px;
}
.forgot-password {
  font-size: 11px;
  line-height: 19px;
  color: #333333;
  font-family: "robotomedium";
}
/* .all-rights { */
  /* font-size: 12px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.87);
  bottom: -28px;
  left: 0;
  right: 0;
  text-align: center;
  position: absolute; */
/* } */
/* .snooker-assciation { */
  /* text-align: center;
  font-size: 12px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.5); */
/* } */
i.fa.fa-eye.view-pass, i.fa.fa-eye-slash.view-pass {
  font-size: 17px;
  bottom: 13px;
  right: 7px;
}
.view-pass {
  position: absolute;
  right: 12px;
  bottom: 14px;
  cursor: pointer;
}
.view-pass:hover circle,
.view-pass:hover path {
  fill: #000000;
}
.login-page .error-alert {
  top: 110px;
  padding: 0 25px;
}
.forgot-password-wrapper .login-page .error-alert {
  top: -20px;
}
.forgot-password-wrapper.set-password-wrapper .login-page .error-alert {
  top: 67px;
}
.home-page .no-record {
  margin-top: 9.4em;
  margin-bottom: 9.4em;
}
.no-record{
  height: 50px;
  overflow: hidden;
  margin-bottom: 20px;
}
.login-red-btn{
  font-size: 20px;
  line-height: 24px;
  padding: 13px;
  height: initial;
  border-radius: 28px;
}
/* .register-player .login-page{ */
  /* background: #eee;
  max-width: 900px;
  border-radius: 8px; */
/* } */
/* .register-player .login-page .error-alert{ */
  /* top: 23px; */
/* } */
/* Login page css end here ---------------**********-- */
/* 2.) Header page css start here -------------------- */

.header {
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  z-index: 11;
  background: url(../images/inner-pages-header-bg.jpg) center top no-repeat;
  background-size: cover;
  z-index: 999;
}
.main-logo {
  width: 100%;
  padding: 11px 24px;
  text-align: center;
}
.main-logo img {
  max-width: 130px;
}
.user-image {
  width: 40px;
  height: 40px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #ffffff;
  border-radius: 100%;
}
.user-image img{
  width: 38px;
  height: 38px;
}
.user-name {
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  font-family: 'robotobold';
}
.dropdown-arrow {
  border-top: 5px solid #ffffff;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
.profile-options-small-screen{z-index: 10;}
.profile-options {
  background-clip: padding-box;
  border-radius: 0.25rem;
  padding: 12px 0;
  position: absolute;
  right: 0;
  top: 48px;
  margin: 0;
  width: 216px;
  display: none;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #cccccc;
  background-color: #ffffff;
}
.profile-options a,
.profile-options span.signout-btn {
  font-size: 16px;
  line-height: 25px;
  color: #333333;
  display: block;
  padding: 7px 20px;
  font-family: "robotomedium";
  border-left: 3px solid transparent;
  text-transform: capitalize;
}
.profile-options a:hover,
.profile-options span.signout-btn:hover {
  border-left: 3px solid #b52830;
  color: #b52830;
  text-decoration: none;
  background: #f5f5f5;
  border-bottom: none;
}
/* .profile-options a.active {
  border-left: 3px solid #b52830;
  color: #b52830;
  text-decoration: none;
  background: #f5f5f5;
} */
/* .profile-options a.active svg path, */
.profile-options a:hover svg path,
.profile-options span.signout-btn:hover svg path {
  fill: #b52830;
  stroke: #b52830;
}
.profile-options::before {
  content: "";
  position: absolute;
  right: 28px;
  top: -8px;
  border-bottom: 8px solid #ffffff;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}
.user-profile-panel:hover .profile-options {
  display: block;
}
.tab-menu-bar.active svg:first-child,
.tab-menu-bar svg:last-child {
  display: none;
}
.tab-menu-bar.active svg:last-child,
.tab-menu-bar svg:first-child {
  display: block;
}
.tab-menu-bar {
  position: fixed;
  left: 15px;
  top: 30px;
}
.profile-options svg {
  width: 24px;
}
.roll-id{
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  font-family: 'robotobold_italic';
}
.user-club-name{
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
  font-family: 'robotobold';
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  cursor: unset;
}
.user-club-name:hover {
  background-color: transparent !important;
  color: #fff !important;
  border-left: 3px solid #212e1d !important;
}
.sidebar-panal .user-club-name:hover svg {
  fill: #fff !important;
}
.user-club-tooltip {
  position: absolute;
  left: 16px;
  background-color: #ffffff;
  color: #000000;
  padding: 0.2rem;
  width: max-content;
  border-radius: 0.25rem;
  z-index: 2;display: none;
}
.user-club-header-tooltip{
  position: absolute;
  /* top: -8px; */
  bottom: -16px;
  background-color: #ffffff;
  color: #000000;
  padding: 0.2rem;
  width: max-content;
  border-radius: 0.25rem;
  z-index: 2;
  right: 40%;
}
.public-mode{
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
  font-family: 'robotomedium';
  border-radius: 20px;
  border: solid 1px rgba(255, 255, 255, 0.7);
  background-color: rgba(0, 0, 0, 0.24);
  padding: 6px 18px;
  position: relative;
}
.public-mode:hover,
.public-mode:active{
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.24);
  text-decoration: none;
}
.public-mode::before{
  content: "";
  position: absolute;
  right: -40px;
  top: 0;
  bottom: 0;
  width: 1px;
  background: #eeeeee;
}
.profile-nav .public-mode{
  border: none;
  border-radius: 0;
}

/* Header page css end here -----------------------**********-- */
/* 3.) Forgot Password page css start here -------------------- */

.set-password-wrapper {
  background: url(../../assets/images/bg.png);
  background-size: cover;
  padding: 15px;
}
.set-password-wrapper .login-page{
  max-width: 480px;
  width: 100%;
  margin: 0 auto 35px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
}
.login-page {
  padding: 24px 15px;
}
.login-page .text-error{
  top: 45px;
}
.forgot-form .form-control {
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  padding: 12px 18px;
  height: initial;
}
.forgot-form h3 {
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  font-weight: 700;
}
.forgot-form.set-password-form h3 {
  font-size: 18px;
  line-height: 1;
}
.forgot-form p {
  font-size: 16px;
  line-height: 24px;
  color: #666666;
  font-weight: 400;
  margin-bottom: 16px;
}
.back-to-login {
  font-size: 16px;
  line-height: 19px;
  color: #666666;
}
.forgot-password-wrapper .login-btn {
  margin: 20px 0;
}
.forgot-password-wrapper .set-password-form .login-btn {
  margin: 0;
}
.set-password-logo {
  max-width: 110px;
}
.forgot-form.set-password-form .pass-info {
  font-size: 12px;
  line-height: 15px;
  color: #858585;
  margin: 15px 0 0;
}
.pass-strength {
  font-size: 14px;
  line-height: 24px;
}
.weak-color {
  color: #b52830;
}
.strong-color {
  color: #124e25;
}
.medium-color {
  color: #f8c10a;
}
.set-password-form h3 svg {
  width: 18px;
  height: 20px;
}
.forgot-form.set-password-form p {
  line-height: 20px;
}
.change-password,
.personal-info-wrapper {
  max-width: 744px;
  width: 100%;
  margin: 0 auto;
}
.change-password-form p {
  font-size: 12px;
  line-height: 15px;
  color: #858585;
  margin: 15px 0 0;
}
.input--wrapper-error input{
  border: 1px solid #b52830;
}
.form-control:-internal-autofill-selected {
  background: #fff !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* Forgot Password css end here -----------**********-- */
/* 4.) Sidebar page css start here -------------------- */
a.btn-expand-collapse {
  width: 100%;
  color: #fff;
  font-size: 20px;
  display: flex;
  padding: 11px 19px;text-decoration: unset;
}
a.btn-expand-collapse b {
  font-size: 15px;
  padding-left: 10px;
  font-weight: 500;
}
a.btn-expand-collapse i{
   color: #fff;
}
.navbar-primary.collapsed {
  width: 62px;
}
.navbar-primary.collapsed span {
  font-size: 0px;
  line-height: 0px;    align-items: center;
  justify-content: center;

}
.collapsed + .content-wrapper, .collapsed + .add-club-wrapper, .collapsed + .addnewclub, .collapsed + .add-player-wrapper, .collapsed + .my-account-wrapper, .collapsed + .demo-app{
  margin-left: -190px;
}
.collapsed + .content-wrapper .sticky-layout{left: 83px;}
.navbar-primary.collapsed .footer p{display: none;}
.navbar-primary.collapsed .btn-expand-collapse i {
  font-size: 20px;    width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;margin-left: -5px;
}
.navbar-primary.collapsed .btn-expand-collapse {
  padding: 11px 0px 11px 0px;
}
.navbar-primary.collapsed .btn-expand-collapse {
  text-align: center;
}
a.btn-expand-collapse span {
  width: 100%;
  display: flex;
}
a.btn-expand-collapse i {
  float: left;
}
.navbar-primary.collapsed i {
  font-size: 20px;
}
.navbar-primary.collapsed .home a {
  display: inline-flex;
  width: 100%;
}
.navbar-primary.collapsed b{
  display: none;
}

.custom-table{
  scrollbar-width: thin;
}
.rdw-editor-main {
  scrollbar-width: thin;
}
.table-responsive.global-table.mt-2 {
  scrollbar-width: thin;
}
.modal-open .modal {
  scrollbar-width: thin;
}
.sidebar {
  box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: #2c3c27;
  width: 250px;
  padding: 24px 0 0 0;
  position: fixed;
  top: 64px;
  left: 0;scrollbar-width: thin;
  bottom: 0;
  z-index: 111;
  display: none;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.sidebar-panal>li.active {
  background-color: #212e1d;
} 
.sidebar-panal>li.active span {
  border-left: 3px solid #212e1d;
} 

.sidebar-panal>li.active ul li a {
  border-left: 3px solid #212e1d;
}

.sidebar.active {
  display: block;
}
.playerCalender i {
  float: left;
}
.sidebar-panal b{font-weight: 500;}
.sidebar-panal span {
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
  font-family: "robotomedium";
  letter-spacing: 0.09px;
  padding: 11px 17px;
  display: flex !important;border-left: 3px solid #2c3c27;
}
.sidebar-panal .home a i.mr-2 {
  margin-left: -4px;
}
.navbar-primary.collapsed .home.news i {
  margin-left: -5px;
}
.bgYellowStatus .none-select-field {
  background: #e9ecef !important;    opacity: 1;
}
.bgGreenStatus .none-select-field {
  background: #e9ecef !important;  opacity: 1;

}
.bgGreenStatus .select-red-bg{
  background: #e9ecef !important;
}
.bgYellowStatus .select-red-bg {
  background: #e9ecef !important;  
}

.sidebar-panal .home a{line-height:18px;}
.sidebar-panal a,
.sidebar-panal ul li span {
  font-size: 14px;
  line-height: 15px;
  color: #ffffff;
  font-family: "robotomedium";
  padding: 11px 17px 11px 17px;
  display: block;    border-left: 3px solid #2c3c27;margin:1px 0px;


}
.sidebar-panal span i{float: left;}
.sidebar-panal a i,
.sidebar-panal ul li span i{float: left;}
.sidebar-panal a:hover,
.sidebar-panal a:active {
  text-decoration: none;
}
.sidebar-panal ul li a:hover{
  border-left: 3px solid #b52830 !important;
}
.sidebar-panal ul li a.active {
  background-color: #f5f5f5;
  border-left: 3px solid #f5f5f5;
  color: #b52830;
}
.sidebar .profile-nav a.active{
  color: #ffffff;
  background-color: #192316;
  border-left: 3px solid #192316;
}
.sidebar .profile-nav a:hover svg path, 
.sidebar .profile-nav a.active svg path, 
.sidebar .profile-nav span:hover svg path {
  fill: #ffffff;
  stroke: #ffffff;
}
.arrow {
  position: absolute;
  right: 10px;
  top: 8px;
}
.sidebar-panal ul li.active {
  background-color: #212e1d; 
}

/* .sidebar-panal ul li:hover {
  background-color: #b52830;
  border-left: 2px solid #fff;
} */

.sidebar-panal .open-panal span:hover{
  border-left: 3px solid #b52830;
  color: #b52830;
  background-color: #f5f5f5;
  text-decoration: none;
  padding: 11px 17px 11px 17px;
}


.sidebar-panal a:hover {
  border-left: 3px solid #b52830;
  color: #b52830;
  background-color: #f5f5f5;
  text-decoration: none;
}
.sidebar-panal .open-panal span:hover svg{  fill: #b52830;}
.sidebar-panal a:hover svg, .sidebar-panal a:active svg {
  fill: #b52830;
}
.sidebar-panal a i svg:hover {
 fill: #b52830;
}

.sidebar-panal a:active :hover {
  border-left: 3px solid #b52830;
  color: #b52830;
  background-color: #f5f5f5;
  text-decoration: none;
  padding: 11px 17px 11px 17px;
}
.footer {
  padding: 12px 12px 24px;
}
.footer p {
  font-size: 12px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.7);
  font-family: 'roboto_condensedregular';
}
.footer span {
  display: block;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.16);
  margin-bottom: 32px;
  padding: 9px 16px;
  font-family: 'robotobold_italic';
}
.sidebar .my-account-sidebar {
  width: 100%;
  background: none;
  box-shadow: none;
}
.sidebar .open-panal.active .my-account-sidebar{
  background: #212e1d;
}
.sidebar .profile-user-name,
.sidebar .profile-details span,
.sidebar .profile-nav a,
.sidebar .profile-nav span{
  color: #ffffff;
}
.sidebar .profile-nav a svg path,
.sidebar .profile-nav span svg path{
  fill: #ffffff;
  stroke: #ffffff;
}
.sidebar .profile-details{
  display: flex;
  align-items: center;
  padding: 15px;
}
.sidebar .profile-details figure{
  margin: 0;
  max-width: 40px;
  height: 40px;
}
.profile-details figure img{
  height: 30px;
  width: 30px;
}
.sidebar .profile-details span{
  display: none !important;
}
.sidebar .profile-user-name{
  margin: 0 !important;
  padding-left: 10px;
}
.sidebar .profile-nav{
  padding: 0 0 16px;
  display: none;
}
.sidebar .open-panal.active .profile-nav{
  display: block;
}
.sidebar-panal > li > ul{
  display: none;
}
.sidebar-panal > li.active > ul{
  display: block;
}
.sidebar-panal > li.active .arrow,
.sidebar .open-panal.active .sidebar-opener .arrow{
  transform: rotate(180deg);
}
.sidebar-opener{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 71px;
  z-index: 1;
}
.sidebar-opener .arrow{
  top: 24px;
}
.open-panal> span{
  cursor: pointer;
}
.powered-by{
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  opacity: 0.5;
  font-family: 'robotomedium_italic';
}

/* Sidebar page css end here ----------------------**********-- */
/* 5.) Club Management page css start here -------------------- */

.content-wrapper h3 {
  font-size: 22px;
  line-height: 25px;
  color: #000000;
}
.paymentMemberTable.labels th {
  width: 242px;
}
.add-club.MemberTransactionsfilter,.add-club {
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  border-radius: 20px;
  background-color: #b52830;
  padding: 7px 22px;
  font-family: "robotomedium";
  margin: 0 auto 20px;
  justify-content: center;
}
.add-club:hover {
  background-color: #8b1118;
  color: #ffffff;
  cursor: pointer;
}
.bulk-action {
  border-radius: 4px;
  border: solid 1px #cccccc;
  background-color: #ffffff;
  color: #333333;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  padding: 9px 17px;
}
.bulk-action.dropdown-toggle::after {
  margin-left: 13px;
  border-top: 5px solid #666666;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
.show-page-number {
  color: #666666;
  font-size: 13px;
  line-height: 15px;
}
.global-table {
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
}

.global-table th {
  color: #2d3c25;
  font-size: 16px;
  line-height: 19px;
  font-family: "robotobold";
}
.global-table td {
  color: #333333;
  font-size: 14px;
  line-height: 16px;
  vertical-align: middle; word-break: break-all;
}

.global-table.addtournament .search-box {
  min-width: 164px;    max-width: 100px;
}
.global-table.addtournament .search-box.date-box {
  min-width: 132px;
}
.addtournament th {
  font-size: 15.7px;
 }
.addtournament .enter-tournament-icon.disabled{right:unset;position: unset;}
.addtournament  .enter-tournament-icon{right:unset;position: unset;}
.addtournament  a.text-secondary {
  padding-left: 10px;
}
.addtournament a.text-primary.cursor-pointer{  padding-left: 10px;}
.addtournament .select-box.staus-box {
  width: 125px;
}
.club-name-bold {
  font-family: "robotomedium";
}
.club-name-header{
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  cursor: pointer;
}
.w-24 {
  width: 56px;
  padding: 0 16px;
}
.select-box {
  border-radius: 4px;
  border: solid 1px #cccccc;
  background-color: white;
  cursor: pointer;
  color: #666666;
  font-size: 14px;
  line-height: 16px;
  width: 90px;
  padding: 6px 20px 6px 10px;
}
.select-box.staus-box{
  width: 150px;
}
.select-box.staus-box:hover{cursor: pointer;}
.search-box {
  border-radius: 3px;
  border: solid 1px #cccccc;
  background-color: white;
  color: #333333;
  font-size: 13.7px;
  line-height: 16px;
  height: 34px;
  padding: 5px 9px 5px 32px;
}
.arrow-icon {
  border-top: 5px solid #666666;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  position: absolute;
  right: 6px;
  top: 18px;
  z-index: 0;
}
.global-table .select-box.staus-box ~ .arrow-icon,
.global-table .select-box.matches-box ~ .arrow-icon{
  top: 13px;
  right: 10px;
}
.search-icon {
  position: absolute;
  left: 8px;
  top: 7px;
}
.search-box.searchss {
  min-width: 191px !important;
}
.global-table.others th {
  border: unset;
}
.global-table.others thead tr{border-bottom:2px solid #dee2e6;}
.global-table.others tr{border-top: 1px solid #dee2e6;}
.global-table.others td {
  border-top: unset;
}
.members-tab {
  min-width: 299px;
  width: 299px;
}
.searchs{    width: 179px; min-width: 179px;}
.actions-tab {
  min-width: 120px;
  width: 120px;
}
.plus-icon{
  position: absolute;
  right: 15px;
  top: 13px;
}


.plus-icon-player{
  position: absolute;
  left: 480px;
   top: 16px;

}

.pagination{
  border-radius: 4px;
}
.pagination > li > a, .pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #124e25;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
}
.pagination > .disabled > span, 
.pagination > .disabled > span:hover, 
.pagination > .disabled > span:focus, 
.pagination > .disabled > a, 
.pagination > .disabled > a:hover, 
.pagination > .disabled > a:focus {
  color: #777777;
  background-color: #fff;
  border-color: #ddd;
  cursor: not-allowed;
}
.pagination > li:first-child > a, 
.pagination > li:first-child > span {
  margin-left: 0;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.pagination > .active > a, 
.pagination > .active > span, 
.pagination > .active > a:hover, 
.pagination > .active > span:hover, 
.pagination > .active > a:focus, 
.pagination > .active > span:focus {
  z-index: 3;
  color: #fff;
  background-color: #124e25;
  border-color: #124e25;
  cursor: default;
}
.view-twich-channel .dropdown-menu.show{
  left: initial !important;
  right: 0;
}
.dropdown-item.active, 
.dropdown-item:active{
  color: #212529;
  text-decoration: none;
  background-color: #ffffff;
}
.members td{width:20%;word-break: break-all;}
.opponentName{display:block;color: #124e25c2;}
.global-table td.no-record-found{
  height: 200px;
  vertical-align: middle;
  text-align: center;
  font-family: 'robotobold';
}
.newsCard .no-record-found.newsMessage {
  background: #fff;
}
#clubNews .newsCard .no-record-found.newsMessage {
  transform: translateY(0px);
  box-shadow: 0px 0px 6px rgb(0 0 0 / 20%);
  padding: 0px 10px;
  background: #fff;
  border-radius: 7px;
  width: 0.3% !important;
}
.no-record-found{
  margin: auto;
  width: 50%;
  padding-top: 100px;
  height: 300px;
  vertical-align: middle;
  text-align: center;
  font-family: 'robotobold';
  color: white;
}
.email-club path{fill-opacity: 0.5;fill:#000;}
.email-club:hover path{fill-opacity:1;}
.player_manage {
  width: 240px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.delete-club:hover g,
.edit-club:hover g{
  fill-opacity: 1;
}
.global-table .search-box{
 min-width: 230px;
}
.content-wrapper .club-name-bold span{
  color: #124e25; float: left;
}
.content-wrapper .club-name-bold .memeber-lighter {
  color: #666666;
  font-size: 12px;
  font-weight: 100;
  font-family: "robotoregular";
  padding-left: 5px;
}
.cloudsnooker .statics-tab.nav-tabs .nav-link{
  background: transparent;
}
.managenewss p.text-right {
  position: absolute;
  right: 14px;
  font-size: 13px;
  color: #858585;
  margin-top: 10px;
}
.managenewss{background:transparent;box-shadow:unset;}
.managenewss .club-name-bold span{font-size: 16px;line-height: 22px;    margin-bottom: 0px;
  display: block;}
.managenews .club-name-bold span{font-size: 15px;line-height: 19px;    width: 682px;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;}
.managenews .club-name-bold .d-block.text-capitalize{
  font-size: 13px;
  font-weight: 300;    font-family: "robotoregular";
}
.managenewss h6.text-center {
  font-size: 14px;
  color: #666666;
  font-family: "robotoregular";
  margin-top: 2em;
}
.managenewss h6.text-center i {
  margin-left: 4px;
}
.managenewss .club-name-bold .d-block.text-capitalize{
  font-size: 13px;    margin-bottom:0px;
  font-weight: 300;    font-family: "robotoregular";line-height: 19px;
}


.newdetails h3{    font-size: 20px;
  line-height: 28px;    color: #124e25;position: relative;margin-left:10px;}
  .newdetails h3:before {
    position: absolute;
    content: "";
    background: #b52830;
    width: 2px;
    height: 46px;
    left: -10px;
    top: 5px;
}
.newdetails img{margin-bottom:1.5em;}
  .newdetails h4{    font-size: 15px;
    line-height: 28px;    color: #124e25;}
.newdetails p{font-size: 14px;
  font-weight: 300;    margin-bottom: 7px;
  font-family: "robotoregular";color:#666666;line-height: 28px;    
}
.newdetails {
  width: 67%;
  margin: auto;background: #fff;
}
.newdetails ul{padding-inline-start:0px;}
.newdetails li{font-size: 15px;
  font-weight: 300;
  font-family: "robotoregular";color:#666666;line-height: 28px;    
}
.newdetails li span{color: #333333;    font-weight: 600;}
.newdetails h6{font-size: 13px;
  font-weight: 300;color:#858585;
  font-family: "robotoregular";margin-bottom: 20px;
}
.newsCardWidth{
  max-width: 150px;
}
.newsCardMember{
  max-width: 215px;
}
.newsMessage{
  width: 1% !important;
  padding: 0px !important;
}
.newsCard{
  background-color:transparent;
 /* box-shadow: 3px 0px 5px #d9dcde;*/
}
.content-wrapper.white {
  background: #fff;
  height: 100vh;
}

.news-details{background: linear-gradient(0deg, #f5fcff 0%, #fdf6f9 100%) !important;}
.content-wrapper.white.news {
  margin-top: 30px;
  width: 75%;
  margin-left: auto;
  margin-right: auto;  border-radius: 12px;
  padding: 30px;
  margin-bottom: 20px !important;    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 16%);height: auto;
}
.content-wrapper.white.news .backs {
  margin-left: -147px;
  font-size: 14px;    margin-top: -29px;

  font-weight: 500;
  line-height: 1.14;    position: absolute;

  color: #666;
}
  .content-wrapper.white.news .backs i{  font-size: 18px;    padding-right: 2px;
  }
.content-wrapper.white.news h3 {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.4;
  color: #2d3c25;margin-bottom: 23px;
}
.content-wrapper.white.news p{
  font-size: 13px;
font-weight: normal;
line-height: 1.15;
/* color: #858585; */
}
.content-wrapper.white.news img {
  border-radius: 16px;
  margin-bottom: 15px;
}

.content-wrapper.white.news p span{
  background: transparent !important;
font-size: 14px;
font-weight: normal;
line-height: 1.71;
color: #666;
}
.news-heading{
  border-left: 3px solid #b52830;
padding-left: 8px;
}

.news-content{
  text-align: justify;
}

.global-table.managenews .table thead th {
  vertical-align: bottom;
  border-bottom: 0px solid #dee2e6;
  background: #fff;
}
.global-table.managenews {
  background: transparent;
  box-shadow: unset;
}
.allTransactionTopLayout.newspagination {
  padding: 14px 20px;
  border-radius: 0px;
}
.managenews .allTransactionTopLayout.newspagination .pagination a {
    border-radius: 50%;
    margin-left: 11px;
}
.managenews .allTransactionTopLayout.newspagination .pagination{margin:0px;}
.managenews .table th:first-child, .managenews .table td:first-child {
  width: 71%;
}
.managenews .table th:nth-child(2), .managenews .table td:nth-child(2) {
  width: 19%;
}
.managenews .table th:nth-child(3), .managenews .table td:nth-child(3) {
  width: 13%;
}
#userDetailsPopup .modal-dialog{
  max-width: 650px;
}
.list-of-user strong {
  text-align: left;
  font-family: 'robotomedium';
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}
.list-of-user span{
  font-size: 14px;
  line-height: 16px;
  color: #666666;
}
.user-details-popup h3{
  font-size: 16px;
  line-height: 19px;
  color: #b52830;
  font-family: 'robotobold';
}
.user-details-popup h2{
  font-size: 22px;
  line-height: 25px;
  color: #000000;
  font-family: 'robotobold';
}
.popup-table.global-table{
  box-shadow: none;
}
.popup-table .table thead th{
  color: #000000;
  font-family: 'robotomedium';
  font-weight: 400;
}
.popup-table .table tbody td{
  color: #666666;
}
.popup-table .table tbody td,
.popup-table .table thead th{
  border: none;
  padding: 5px 0;
  font-size: 14px;
  line-height: 16px;
}
.view-channel-list .btn{
  font-size: 14px;
  line-height: 16px;
  color: #0062b0;
  text-decoration: underline;
  border: 0;
  text-decoration: underline;
  text-align: left;
}
.actions-tab-80{
  width: 80px;
  max-width: 80px;
}
.actions-tab-80 > div:first-child{
  display: none;
}
.renew-icon-show{
  width: 18px;
  height: 18px;
}
/* Club Management page css end here --------------**********-- */
/* 6.) User Management page css start here -------------------- */

.user-role .arrow-icon {
  right: 16px;
  top: 18px;
}
.user-role .form-control {
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.mobile-number {
  border-radius: 3px;
}
.mobile-number.input-error {
  border: 1px solid #b52830;
}
.mobile-number .arrow-icon {
  right: 4px;
}
.mobile-number #code_select, .mobile-number #selectBox{
padding: 10px 5px !important;    cursor: pointer;
}
.mobile-number > div:last-child{margin-left: 4px;}
.mobile-number > div {
  border: solid 1px #cccccc;
  width: 100px;  border-radius: 3px;
}
.arrow-icon {
  border-top: 5px solid #666666;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  position: absolute;
  right: 6px;
  top: 18px;
  z-index: 0;
}
.used-licence {
  min-width: 145px;
}
.available-licences {
  min-width: 170px;
}
.mobile-number select {
  background: #f5f5f5;
}
/* User Management page css end here ----------**********-- */
/* 7.) My Profile page css start here --------------------- */

.my-account-sidebar {
  width: 254px;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  position: relative;
  /* z-index: 20; */
}
.profile-details {
  padding: 32px 15px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
}
.profile-details figure {
  border: solid 2px #666666;
  border-radius: 100%;
  max-width: 115px;
  height: 115px;
  width: 100%;
  margin: 0 auto 10px;
  padding: 3px;
}
.profile-user-name {
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  font-family: "robotomedium";
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 17px;
}
.profile-details span {
  font-size: 14px;
  line-height: 16px;
  color: #666666;
  font-family: "robotomedium";
}
.profile-nav {
  padding: 16px 0;
  margin: 0;
}
.profile-nav a,
.profile-nav span {
  display: block;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  font-family: "robotomedium";
  padding: 9px 20px;
  border-left: 3px solid transparent;
}
.profile-nav a:hover,
.profile-nav span:hover {
  border-left: 3px solid #b52830;
  color: #b52830;
  background-color: #f5f5f5;
  text-decoration: none;
}
.profile-nav a.active {
  stroke: #333333;
  background-color: #f5f5f5;
 
}

.profile-nav a:hover svg path,
/* .profile-nav a.active svg path, */
.profile-nav span:hover svg path {
  fill: #b52830;
  stroke: #b52830;
}

.profile-nav a:hover svg path,
/* .profile-nav a.active svg path, */
.profile-nav span:hover svg path {
  fill: #b52830;
  stroke: #b52830;
}
.profile-nav svg {
  width: 24px;
}
.updated-photo {
  width: 130px;
  margin: 0 auto;
}
.updated-photo figure {
  width: 127px;
  height: 127px;
  border-radius: 100%;
  border: solid 2px #b52830;
  padding: 3px;
  margin: 0 auto 10px;
}
.upload-image-icon {
  position: absolute;
  right: 0;
  bottom: 0;
}
.change-photo {
  font-size: 12px;
  line-height: 14px;
  color: #b52830;
  font-family: "robotomedium";
}
.sidebar-details .forgot-password-wrapper {
  background: none;
}
.sidebar-details .snooker-assciation {
  display: none;
}
.sidebar-details .login-page {
  border: 1px solid rgba(0, 0, 0, 0.16);
}
.photo-area {
  background: #666666;
  min-height: 297px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.photo-upload-btn {
  position: relative;
  width: 100%;
  margin: 16px auto 0;
  max-width: 110px;
  height: 15px;
  overflow: hidden;
}
.photo-upload-btn input[type="file"] {
  width: 110px;
  padding: 0;
  position: relative;
  z-index: 1;
  opacity: 0;
}
.photo-upload-btn span {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  font-size: 14px;
  line-height: 16px;
  color: #b52830;
  font-family: "robotomedium";
  right: 0;
}
.photo-upload-icon {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  height: 88px;
  width: 88px;
}
.photo-upload-icon input[type="file"] {
  height: 88px;
  width: 88px;
  z-index: 1;
  position: relative;
  opacity: 0;
}
.photo-upload-icon svg {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}
#uploadPhoto .modal-dialog {
  max-width: 480px;
}
.sidebar-details-inner {
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  padding: 20px 15px;
}
.sidebar-details-inner.statics{padding: 20px 32px;}
.sidebar-details-inner.staticss{padding: 14px 20px;}
.sidebar-details-inner.tounatabs{padding: 0px;}
.sidebar-details-inner.statics p{font-size:12px;color: #666666;}
.sidebar-details-inner.statics h5{font-size:24px;color: #333333;font-weight: 600;}
.sidebar-details-inner.staticss small{font-size: 13px;color: #666666 !important;font-weight: 500;
}
.sidebar-details-inner.statics h4, .sidebar-details-inner.tounatabs h4{font-size:18px;color:#2d3c25;}
.sidebar-details-inner.staticss .form-control{font-size:14px;color: #2d3c25;height: 37px;    padding: 8px 15px;}
.statics-tab.nav-tabs .nav-link.active {
  color: #b52830;text-decoration: underline;border-right:1px solid #cccccc;
}
.sidebar-details-inner.staticss .manage-player-tab-wrapper > div .position-relative {
  width: 204px;
}
.sidebar-details-inner .box{
  width: 50%;border-left: 1px solid rgba(18, 78, 37, 0.3);}
  .sidebar-details-inner .box .tops{padding:13px 6px;    height: 110px;}
.sidebar-details-inner .box .tops h5{font-size:14px;color: #124e25;}
.sidebar-details-inner .box .tops p{font-size:13px;color: #666666;}
.sidebar-details-inner .box .tops p small{font-size:13px;color: #333333;}
.sidebar-details-inner .box .tops h3{font-size:12px;color: #b52830;text-transform: uppercase;}
.sidebar-details-inner .box .tops .topsdetail{width: 19%;}
.statics-tab.nav-tabs .nav-link {
  font-size: 14px;
  line-height: 19px;
  color: #666666;
  padding: 0px 10px;border: none;border-right:1px solid #cccccc;
}
.statics-tab.nav-tabs .nav-link:hover{border-right:1px solid #cccccc;}
.sidebar-details-inner .box:first-child {
    border: none;
}
.sidebar-details-inner .members.status td {
  height: 57.5px;
}
.sidebar-details-inner .box .table tbody td br{display: none;}
.sidebar-details-inner .box .table thead th{padding: 5px 6px 5px 6px;   
  background-color: rgba(18, 78, 37, 0.16);color: #124e25;font-size:14px;}
  .sidebar-details-inner .box .table tbody tr:nth-child(even){background-color: rgba(18, 78, 37, 0.04);}
  .sidebar-details-inner .box .table tbody td{padding: 7px 6px 7px 6px;  
    color: #666666;font-size:14px;     height: 78.5px;}
.home_btn_style:hover{
  text-decoration: none;
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
  color: white;
}
.home_btn_style{
  background: #124e25;
  color: white;
  padding: 10px 40px;
  border-radius: 26px;
  font-size: 20px;
}
.home_btn{
  display: flex;
  justify-content: center;
  padding: 40px 0;
}
.onboard_message{
  display: flex;
  justify-content: center;
  color: gray;
  /* font-weight: 600; */
  padding-top: 20px;
}
.color_gray {
  color: gray !important;
}
.color_red {
  color: rgba(255, 0, 0, 0.805);
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.show-only-msg .success-alert{
  position: static;
}
.enable-after-profile-event .cancel-btn,
.enable-after-profile-event .red-btn{
  pointer-events: none;
  opacity: .65;
}

/* My Profile page css end here ---------------**********-- */
/* 8.) Add new club page css start here -------------------- */

.organization-information {
  flex: 1;
}
.add-club-management .organization-information {
  width: 65%;
}
.layout-view h4 {
  font-size: 18px;
  line-height: 21px;
  color: #b52830;
  margin-bottom: 20px;
}
.calendar-icon {
  position: absolute;
  right: 16px;
  top: 9px;
}
.bucket_slider{
  display: flex;
  justify-content: space-between;
}
.upload-logo-view {
  border: dashed 1px #999999;
  background-color: #f5f5f5;
  width: 126px;
  height: 92px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  margin-right: 16px;
  border-radius: 4px;
}
.ShowNotPlayer {
  background: #e9ecef;
  border-radius: 4px;
  text-align: center;
  color: black;
  border: 1px solid #ced4da;
  padding: 7px 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 33px;
  width: 100px;
  max-width: 130px;
}
.upload-logo-view input[type="file"] {
  position: absolute;
  left: 0;
  right: 0;
  width: 126px;
  height: 126px;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
}
.logo-dimension li {
  font-size: 13px;
  line-height: 18px;
  color: #858585;
  margin-bottom: 10px;
  position: relative;
  padding-left: 15px;
}
.pencil_i:hover{
  color: black !important;
}
.logo-dimension li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  width: 6px;
  height: 6px;
  background-color: #999999;
  border-radius: 100%;
}
.react-datepicker-wrapper{
  width: 100%;
  position: relative;
  z-index: 1;
}
.react-datepicker-wrapper input[type="text"]{
  width: 100%;
  height: 40px;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  padding: 10px 15px;
  display: block;
  width: 100%;
  font-weight: 400;
  background: none;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.state-zip-code div:first-child{
  width: 65%;
}
.total-matches-play td{
  border-right: 1px solid #b8c7bc !important;
}
#addressFilupForm .arrow-icon{
  top: 18px;
}
.address-placeholder{
  font-size: 16px;
  line-height: 19px;
  color: #666666;
  font-family: "Roboto", sans-serif;
}
.twitch-channel-list{
  list-style: none;
}
.view-twich-channel{
  position: absolute;
  right: 0;
  top: 45px;
  font-size: 13px;
}
.view-playerlist-channel{
  position: absolute;
  right: 0;
  top: 45px;
  font-size: 13px;
}
.remove-list-name{
  position: absolute;
  top: 7px;
  right: 16px;
}
.remove-list-name svg:hover g path{fill:#b52830;}
.add-club-wrapper .error-alert{
  top: 30px;
}
.phone-number-tab{
  min-width: 150px;
  width: 150px;
}
.status-tab{
  min-width: 125px;
  width: 125px;
}
.global-table input[type="number"].search-box{
  min-width: 150px;
  width: 150px;
}

/* Add new club page css end here ----------**********-- */
/* 9.) Add Player page css start here -------------------- */

.add-player-inner{
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  padding: 21px;
}
.add-player-inner h4{
  font-size: 18px;
  line-height: 21px;
  color: #b52830;
}

.red-checkbox input:checked ~ .checkmark {
  background-color: #b52830;
  border: 3px solid #b52830;
}
.colan-show::before{
  content: ":";
  position: absolute;
  left: 0;
  top: -1px;
  font-size: 14px;
  line-height: 16px;
  color: #666666;
}
.colan-show{
  position: relative;
  padding-left: 7px;
}
.membership input[type='text']{pointer-events: all;opacity: 1;}
.disable-all-fields input[type='text'],
.disable-all-fields input[type='number'],
.disable-all-fields #addressFormValue,
.disable-all-fields select,
.disable-all-fields .twitch-channel-list{
  pointer-events: none;
  opacity: 0.8;
  background: #f5f5f5 !important;
}
.disable-all-fields select.enable-select{
  pointer-events: auto;
  opacity: 1;
  background-color: #ffffff !important;
}
.disable-all-fields  ~ label,
.disable-all-fields .remove-list-name {
  pointer-events: none;
  opacity: 0.8;
}
.react-datepicker-popper[data-placement^="bottom"],
.react-datepicker-popper[data-placement^="top"]{
  z-index: 3;
}

.pasba-admin-search-fields{
  max-width: 213px;
}
.sortNews{
  border-radius: 20px;
}
.rdw-emoji-wrapper {
  display: none !important;
}
.rdw-list-wrapper {
  display: none !important;
}

.upcomingtour {
  background: #fff;
  padding: 14px 6px 14px 6px;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 16%);
}
.upcomingtour select#selectClub{
  border-radius: 3px;
  font-size: 14px;
  border: solid 1px #ccc;
  background-color: #f5f5f5;
  height: 36px;
  padding: 4px 5.3px 4px 14px;
}
.upcomingtour .search-box{
  border-radius: 3px;
  font-size: 14px;
  border: solid 1px #ccc;
  background-color: #f5f5f5;
  height: 36px;
  padding: 4px 15px 4px 32px;}
.upcomingtour.manage-player-tab-wrapper > div {
  width: 90%;
  min-width: auto;
  margin: 0px 10px;
}
.upcomingtourna .d-md-flex.left .noRecord {
  margin: 0px 6px;
}
.upcomingtourna .d-md-flex.left {
  margin: 0px -6px;
}
.upcomingtourna .box {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(25% - 12px);
  flex: 0 0 calc(25% - 12px);
  margin: 6px 6px 6px 6px;
  position: relative;
  padding: 2.7em 16px 3.2em;
  display: flex;
  background: #fff;    height: 230px;
  border-radius:12px;    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 16%);
  flex-direction: column;    transition: all 0.30s ease-in-out;
  
}
.upcomingtourna .box.disable:hover .btn{border: none; color: #858585; cursor: default;}
.upcomingtourna .box.disable .btn{border: none; color: #858585; cursor: default; box-shadow:unset}
.upcomingtourna .box.disable:hover{box-shadow:unset;}
.upcomingtourna .box.disable:hover h6, .upcomingtourna .box.disable:hover h5{color: #858585;}
.upcomingtourna .box.disable h6, .upcomingtourna .box.disable h5, .upcomingtourna .box.disable p{color: #858585; cursor: default}
.upcomingtourna .box.disable h3{color:#666666;}
.upcomingtourna .box.disable:hover h3{color:#666666;}
.upcomingtourna .box.disable .btn:hover{box-shadow: unset}

.upcomingtourna .box:hover{
  -ms-transform: scale(105%,105%);
 transform: scale(105%,105%);
 transition: all 0.30s ease-in-out;
 cursor: pointer;
}
.upcomingtourna .box:hover:after{
 content: '';
 height: 11px;
 width: 100%;
 position: absolute;
 bottom: 0;
 left: 0;
 background: #b52830;
 transition: all 0.30s ease-in-out;    border-radius: 0px 0px 12px 12px;
}
.upcomingtourna .box.disable:hover:after{display: none;}
.upcomingtourna .box.disable:hover{
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 16%);
  -ms-transform: none;
  cursor: unset; transform: none;
 transition: none;
 cursor: default;
}
.upcomingtourna .box.disable:hover:after{
  content: '';
  height: 0px;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: none
 }


.upcomingtourna .box:hover{box-shadow: 0 8px 24px 0 rgba(18, 78, 37, 0.38); cursor: default;}
.upcomingtourna .box:hover h6{color: #b52830;}
.upcomingtourna .box:hover h3{color:#2d3c25;}
.upcomingtourna .box:hover .btn{color: #b52830;border-color:#b52830 ; }
.upcomingtourna .box .btn:hover{box-shadow: 0px 0px 8px -2px #b52830;}
.upcomingtourna .box h6{font-size: 13px;line-height:17px;color: #b52830; font-family: "robotoregular";font-weight: 600;    position: absolute;
  top: 18px;}
.upcomingtourna .box h5{font-size: 13px;line-height:20px;color: #666666;    font-family: "robotoregular";}
.upcomingtourna .box h3{font-size: 16px;line-height:21px;color: #333333; overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; 
  -webkit-box-orient: vertical;word-break: break-all;}
.upcomingtourna .box p{font-size: 14px;line-height:22px;color: #454545;   overflow: hidden;
  margin-bottom: 0;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; 
  -webkit-box-orient: vertical;    word-break: break-all;}
.upcomingtourna .box .btn{
  padding: 8px 24px;    font-size: 14px;
  height: 37.5px;
  border-radius: 50px;
  border: solid 1px #b52830;  position: absolute;
  bottom: 16px;
  background-color: #fff;color: #b52830;   
  width: fit-content;
  margin: auto;left: 0;
  right: 0;}
  
._2iA8p44d0WZ-WqRBGcAuEV{
  padding: 6px 15px !important;
  border: 1px solid #ced4da !important;
}
._2iA8p44d0WZ-WqRBGcAuEV:hover{cursor: pointer;}
._3vt7_Mh4hRCFbp__dFqBCI{
  z-index: 1;
}
._3vt7_Mh4hRCFbp__dFqBCI input{
  margin: 0 !important;
}
.optionContainer li{
  position: relative;
  padding-left: 45px !important;
}
.single-select-box .optionContainer li{
  padding-left: 15px !important;
}
.single-select-box ._7ahQImyV4dj0EpcNOjnwA{
  background: none !important;
  padding: 0;
  margin: 0;
  font-size: 16px;
    line-height: 19px;
    color: #333333;
}
.optionContainer li input[type="checkbox"]{
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 1;
}
.optionContainer li span{
  height: 18px;
  width: 18px;
  border-radius: 2px;
  border: 1px solid #999999;
  position: absolute;
  left: 15px;
  top: 12px;
}
.optionContainer li input:checked ~ span:after {
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  content: "";
  display: block;
  margin: auto;
}
._1YkOwrSkIvJIAvNNRNP3P5,
._3vLmCG3bB3CM2hhAiQX1tN{
  bottom: 44px;
}
.remove-dropdown-list .optionListContainer{
  display: none !important;
}
.optionContainer li input:checked ~ span {
  background-color: #124e25;
  border: 3px solid #124e25;
}
.lhyQmCtWOINviMz0WG_gr{
  background: none !important;
  color: initial !important;
}
._7ahQImyV4dj0EpcNOjnwA{
  background: #124e25 !important;
}
.select-club-by-user strong{ 
  font-size: 16px;
  line-height: 19px;
  color: #b52830;
}
/* .select-club-by-user select.form-control{ */
  /* min-width: 203px; */
/* } */
.add-player-wrapper.disable-all-fields.enable-selectbox .enable-if-exists{
  pointer-events: initial;
  background: none;
  opacity: 1;
 }

.double-row-name{
  font-size: 13px;
  line-height: 15px;
}
.double-row-name span:last-child{
  color: #858585;
}
.layout-view{
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
}
.thumbnail-area{
  width: 168px;
  height: 126px;
  border-radius: 4px;
  border: dashed 1px #999999;
  background-color: #f5f5f5;
  position: relative;
  overflow: hidden;
}
.thumbnail-dimension{
  font-size: 13px;
  line-height: 18px;
  color: #858585;
}
.thumbnail-dimension li{
  position: relative;
  padding-left: 20px;
}
.thumbnail-dimension li::before{
  content: ".";
  position: absolute;
  font-size: 50px;
  color: #999999;
  line-height: 0;
  top: -6px;
  left: 0;
}
.upload-icon{
  background: #b52830;
  height: 48px;
  width: 48px;
  display: block;
  border-radius: 100%;
}
input[type="file"].input-type-file{
  position: absolute;
  left: 0;
  top: -31px;
  bottom: 0;
  width: 100%;
  opacity: 0;
  cursor: pointer;
} 
#registerAsPlayer .modal-dialog{
  max-width: 900px;
}
#registerAsPlayer #addressForm .modal-dialog,
#registerAsPlayer #success_popup .modal-dialog,
#registerAsPlayer #confirmationPlayer .modal-dialog{
  max-width: 500px;
}
#registerAsPlayer #addressForm,
#registerAsPlayer #success_popup,
#registerAsPlayer #confirmationPlayer{
  background: rgba(0,0,0,0.5);
}
.register-as-player{
  background: #eee;
  border-radius: 8px;
}










/* Add Player page css end here ----------**********-- */
/* 10.) Match page css start here -------------------- */
.player-list-name{
  position: absolute;
  padding: 0;
  margin: 2px 0 0 0;
  background: #ffffff;
  z-index: 20;
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 3px;
  max-height: 300px;
  overflow-y: auto;
}
.player-list-name li{
  padding: 5px 10px;
  cursor: pointer;
}
.player-list-name li:hover{
  background: rgba(0, 0, 0,0.1);
}

/* Match page css end here ----------**********-- */
/* 11.) Tournament page css start here -------------------- */

.start-tournament::before{
  content: "Start:";
}
.end-tournament::before{
  content: "End:";
}
.start-tournament::before,
.end-tournament::before{
  font-size: 13px;
  line-height: 15px;
  color: #858585;
  width: 35px;
  text-align: right;
  margin-right: 5px;
}
.add-match-btn,
.add-match-btn:hover,
.add-match-btn:active{
  border-radius: 3px;
  border: dashed 1px #999999;
  font-size: 14px;
  line-height: 16px;
  color: #b52830;
  padding: 13px 10px;
  font-family: 'robotomedium';
}
.match-details-table tr th{
  min-width: 130px;
}
.match-details-table tr th:nth-child(2){
  min-width: 200px;
}
.standing-tab{
  min-width: 110px;
  width: 110px;
}
.matches-tab{
  min-width: 110px;
  width: 110px;
}
.date-tab{
  min-width: 120px;
  width: 120px;
}
.productmanage .date-tab{
  min-width: 98px;
  width: 98px;
}
.productmanage .status-tab {
  min-width: 100px;
  width: 100px;
}
.productmanage td a {
  color: #000;
}
.productmanage td a:hover{color: #0041c1;}
.product-des.text-left {
  min-width: 290px;
  width: 290px;
}
.global-table .productmanage .search-box.product-des {
  min-width: 195px;
}
.global-table .productmanage  .view-price{margin: auto;}
.global-table .productmanage td a:hover .view-price{fill-opacity:1;}

.global-table .productmanage .search-box.date-box {
  padding: 5px 1px 5px 26px;
  width: 119px;
  min-width: 119px;
}

.productmanage .imgs {
  min-width: 75px;
}

.productmanage .imgs figure {
  margin: 0px;
}
.productmanage .imgs img {
  width: 50px !important;
  height: 50px !important;
}
/* .productmanage tr:hover td {
  background: #eee;
} */
.productmanage tr.back:hover td {
  background: transparent;
}
.productmanage .select-box.staus-box {
  width: 115px;
}
.productmanage .actions-tab {
  min-width: 104px;
  width: 104px;
}

.global-table .search-box.date-box{
  min-width: 150px;
}
.add-club-wrapper .expand-btn{
  position: absolute;
  right: 10px;
  top: 7px;
  display: none;
}
.add-club-wrapper .expand-btn.collapsed{
  display: block;
}
.pagination.managepagination li {
  margin: 0px 9px;
}
.pagination.managepagination li a {
  border-radius: 50%;
  padding: 5px 12px;
}
.modal .dollar {
  width: 40px !important;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.status .btn-toggle > .handle {
  position: absolute;
  top: 0.1875rem;
  left: 0.1875rem;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 1.125rem;
  background: #fff;
  transition: left 0.25s;
}
.status .btn-toggle:after {
  content: 'Enable';
  right: -4rem;
  opacity: 0;
}
.status .btn-toggle:before, .status .btn-toggle:after {
  color: #666666;
}
.status .btn-toggle {
  padding: 0;
  position: relative;
  border: none;
  height: 1.5rem;
  width: 3rem;
  border-radius: 1.5rem;
  color: #6b7381;
  background: #bdc1c8;
  box-shadow: none !important;
}
.status .btn-toggle:before, .status .btn-toggle:after {
  line-height: 1.5rem;
  width: 4rem;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  position: absolute;
  bottom: 0;
  transition: opacity 0.25s;
}
.status .btn-toggle.active > .handle {
  left: 1.6875rem;
  transition: left 0.25s;
}
.status .btn-toggle.active {
  background-color: #b52830 !important;
}
.status .btn-toggle.active {
  transition: background-color 0.25s;
}
.status .btn-toggle.active:before {
  opacity: 0;
}
.status .btn-toggle.active:after {
  opacity: 1;
}
.status .btn-toggle:before {
  content: 'Disable';
  right: -4rem;
}
.paymentsubscription .actions-tab {
  min-width: 80px;
  width: 80px;
}
.paymentsubscription  .playerNamePaymentTabs {
  min-width: 172px;
  width: 172px;
}
.paymentsubscription tr:hover td {
  background: #eee;
}
/* #mybooking tr:hover td, #otherbooking tr:hover td{
  background: #eee;
} */
.paymentsubscription tr.back:hover td {
  background: transparent;
}
.paymentsubscription .discriptionTab {
  min-width: 146px;
  width: 146px;
}
.usd .currencyAmt {
  padding: 8px 6px 5px 5px;
  border: solid 1px #ccc;
  background-color: #f5f5f5;
  border-radius: 3px 0px 0px 3px;border: solid 1px #ccc;
}
.usd .arrow-icon {
  position: absolute;
  right:6px;
  top: 14px;
}
.fullnames{width:33px;}
.managematchpast th {
    width: 33.3%;
}
.paymentsubscription .select-box.staus-box {
  background: #fff;
  padding: 7px 20px 7px 10px;
}
.usd.clubs .arrow-icon {
  position: absolute;
  right: 2px;
  top: 14px;
}


.paymentMemberTable .dates {
    height: 44px;
}
.paymentMemberTable.paymentsubscription .select-box.staus-box {
  background: #fff;
  padding: 8px 20px 8px 10px;
}
.paymentMemberTable.paymentsubscription .select-box.staus-box ~ .arrow-icon {
  right: 12px;
}
.paymentMemberTable.paymentsubscription .calendar-icon {
  top: 6px;
}
.paymentMemberTable.paymentsubscription .round-input{    height: 35px;
  min-height: 35px;}
.paymentMemberTable.paymentsubscription .react-datepicker-wrapper input[type="text"] {
  height: 35px;    font-size: 15px;
}
.player-info-wrapper .edit-profile {
  padding-left: 0px;    overflow: revert;
}
.usd .amountInput{  border-radius: 0px 3px 3px 0px;border: solid 1px #ccc;    border-left: 0px;    
  min-width: 66px;
  /* width: 66px; */
}
#member_credit {
  pointer-events:unset !important;
  background: white !important;
}
#credit_dis {
  pointer-events:unset !important;
  background: white !important;
}
.disable_discount{
  pointer-events: none !important;
  background-color: #192316 !important;
}
.paymentsubscription .amount-Payment-Tab {
  min-width: 106px;
  width: 106px;
}
.paymentsubscription .search-box {
  min-width: 168px;
}
.checkred input:checked~.slider-switch {
  background: #b52830;
}
#subscription .modal-dialog {
  max-width: 75%;
  margin: 1.75rem auto;
}
.paymentsubscription .playerNamePaymentTab {
  min-width: 198px;
  width: 198px;
}
#newslot p {
  color: #666;
  font-size: 14px;
}
 
#newslot p a{color: #b52830;text-decoration: underline;}
.modal .dollar i {
  font-style: inherit;
  color: #adadad;
  font-weight: 600;
  font-size: 15px;
}
.tab-details{
  margin-top: -10px;
  color: #858585;
  font-size: 14px;
  line-height: 16px;
}
.toutnas .tounas {
  width: 90%;
  flex-wrap: wrap;
}
.toutnas .tounas .tab-details {
  line-height: unset;    margin: 0px;}
.collapse ~ .tab-details{
  display: block;
}
.collapse.show ~ .tab-details{
  display: none;
}
.manage-player-tab a{
  color: #333333;
  font-family: 'robotomedium';
}
.manage-player-tab.nav-tabs .nav-link.active{
  color: #ffffff;
  background: #b52830;
}
.manage-player-tab.nav-tabs .nav-link{
  background: #ffffff;
  border: none;
  font-size: 14px;
  line-height: 16px;
  min-width: 162px;
  text-align: center;
  padding: 12px 20px;
}
.manage-player-tab.nav-tabs li:first-child a{
  border-radius: 30px 0 0 0;
}
.manage-player-tab.nav-tabs li:last-child a{
  border-radius: 0 30px 0 0;
}
.tab-details-view .show-page-number{
  position: absolute;
  right: 0;
  top: -60px;
}
.snooker-table{
  border-radius: 12px;
  border: solid 1px #cccccc;
  background-color: #fafafa;
  padding: 15px 12px;
}
#viewSnookerGroupDetails .modal-dialog{
  max-width: 650px;
}
.heading-snooker-group h2{
  font-size: 22px;
  line-height: 25px;
  color: #000000;
}
.heading-snooker-group strong{
  font-size: 14px;
  line-height: 22px;
  color: #333333;
  font-family: 'robotomedium';
}
.heading-snooker-group span{
  font-size: 14px;
  line-height: 22px;
  color: #666666;
}
.view-snooker-group-details th{
  font-size: 14px;
  line-height: 16px;
  color: #2d3c25;
}
.view-snooker-group-details tr th:first-child{
  color: #b52830;
}
.view-snooker-group-details td{
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}
.view-snooker-group-details .table thead th,
.view-snooker-group-details .table tbody td,
.pan-snooker-details .table thead th,
.pan-snooker-details .table tbody td{
  border: none;
  padding: 5px 12px;
}
#panSnookerDetails .modal-dialog{
  max-width: 650px;
}
.pan-snooker-details th{
  font-size: 14px;
  line-height: 16px;
  color: #b52830;
}
.pan-snooker-details td{
  font-size: 13px;
  line-height: 20px;
  color: #000000;
}
.pan-snooker-details td span{
  color: #666666;
}
.delete-match-snooker{
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}
.text-underline{
  text-decoration: underline;
}
.player-name-list .dropdown-menu[x-placement^=bottom]{
  top: 7px !important;
  box-shadow: 0 -2px 4px 4px rgba(0, 0, 0, 0.16);
}
.player-name-list .dropdown-menu[x-placement^=top]{
  box-shadow: 0 5px 4px 4px rgba(0, 0, 0, 0.16);
  top: -10px !important;
}
.player-name-list .dropdown-menu[x-placement^=bottom]::before{
  content: '';
  position: absolute;
  left: 25px;
  top: -10px;
  border-bottom: 11px solid #ffffff;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}
.player-name-list .dropdown-menu[x-placement^=top]::before{
  content: '';
  position: absolute;
  border-top: 12px solid #ffffff;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  bottom: -12px;
  left: 43px;
}
.player-name-list .dropdown-toggle::after{
  vertical-align: middle !important;
}

.playerList-reload {
  width: 13px;
  position: absolute;
  left: -22px;
  top: 4px;
  fill: #b52830;
  cursor: pointer;
}

.enter-tournament-icon {
  width: 15px;
  position: absolute;
  right: 187px;
  fill: #b52830;
}
.enter-tournament-icon.disabled {
  width: 15px;
  position: absolute;
  right: 187px;
  fill: #6c757d;
}

.add-club-wrapper .expand-btn.collapsed ~ .red-btn{
  display: none;
}
.group-bucket-wrapper,
.bucket-name-wrapper{
  border-radius: 3px;
  border: solid 1px #cccccc; 
  position: relative;
}
.group-bucket-wrapper{
  padding: 8px 16px 16px;
}
.bucket-dropdown{
  padding: 16px;
  max-height: 288px;
  overflow: auto;
  min-height: 200px;
}
.group-bucket-wrapper  div[role="button"],
.bucket-name-wrapper > div > div > div[role="button"],
.bucket-name-wrapper-knockout > div{
  border-radius: 3px;
  border: solid 1px #cccccc;
  background-color: #f3f3f3;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  padding: 9px 13px;
}
.bucket-name-wrapper > div > div,
.bucket-name-wrapper-knockout > div{  
  margin-top: 8px;
}
.bucket-name-wrapper-knockout{
  border-radius: 3px;
  border: solid 1px #cccccc;
  padding: 16px;
  position: relative;
  min-height: 36px;
}
.nav-tabs .nav-link:hover{
  border: none;
}

.none-before::before{
  display: none !important;
}
.bucket-wrapper h6{
  font-size: 14px;
}
.group-tab{
  margin-bottom: 9px;
  overflow-x: auto;
  overflow-y: hidden; margin-top: 10px;
}
.group-tab a{
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  font-family: 'robotomedium';
  border-bottom: 2px solid transparent;
  padding: 0 0 5px 0;
}
.group-tab .nav-tabs .nav-item.show .nav-link, 
.group-tab .nav-tabs .nav-link.active,
.group-tab a.active{
  color: #b52830;
  border: none;
  border-bottom: 2px solid #b52830;
}
.group-tab .nav-tabs{
  border: none;
}
.group-tab a:hover{
  text-decoration: none;
}
.group-tab .nav-tabs .nav-item{
  margin: 0 30px 0 0;
}
.group-tab .nav-tabs .nav-item:last-child{
  margin-right: 0;
}
.group-tab .nav-tabs .nav-link{
  border: none;
}
.tournament-list{
  border-radius: 3px;
  border: solid 1px #cccccc;
  padding: 16px 8px;
}
.tournament-list-head{
  padding: 0 15px;
}
td.refrees.text {
  width: 50px;
  min-width: 51px;
  word-break: break-all;
}
.tournament-list-head .table-col{max-width: 80px;}
.tournament-list-head > div{
  font-size: 14px;
  line-height: 16px;
  color: #666666;
  font-family: 'robotomedium';
  /* width: 33.33%; */
  font-weight: normal;
  flex: 1;    min-height: auto;
  height: auto;
}
.tournament-list-item{
  border-radius: 3px;
  border: solid 1px #cccccc;
  background-color: #f3f3f3;
  padding: 10px 15px;
  min-width: 1338px;
}
.tournament-list-item > div,
.tournament-edit-item > div{
  font-size: 14px;
  line-height: 16px;
  color: #666666; 
  /* width: 33.33%;  */
}
.match-name-wrap{
  width: 100%;
  min-width: 145px;
  max-width: 145px;
}

em{
  font-family: 'robotoitalic';
}
.save-match{
  font-size: 14px;
  line-height: 16px;
  padding: 0;
  color: #b52830;
  border: 0;
}
.tournament-edit-item{
  border-radius: 3px;
  border: dashed 1px #cccccc;
  background-color: #f3f3f3;
  padding: 10px 15px;
}
.tournament-list-item .react-datepicker-wrapper input[type="text"]{
  font-size: 14px;
  line-height: 16px;
  color: #666666;
  height: initial;
  width: 149px;
  cursor: pointer;
}
.tournament-edit-item .calendar-icon{
  left: 0;
  right: initial;
  top: -2px;
}
.tournament-edit-item.tournament-list-item .react-datepicker-wrapper{
  padding: 2px 0 0 34px;
  pointer-events: initial;
}
.tournament-list-item .react-datepicker-wrapper{
  padding: 2px 0 0 0;
  pointer-events: none;
}
.tournament-edit-item .arrow-icon{
  top: 8px;
  right: 17px;
}
.tournament-edit-item .none-select-field ~ .arrow-icon{
  top: 15px;
  right: 7px;
}
.none-select-field{
  border: none;
  background: no-repeat;
  padding-right: 16px;
  font-size: 14px;
  line-height: 16px;
  color: #858585;
  padding: 0 15px 0 5px;
  font-family: 'robotobold';
  pointer-events: none;
  min-width: 100px;
}
.tournament-edit-item em,
.tournament-list-item em {
  padding: 0 10px;
}
input:focus{
  outline: none;
}
.resend-e-mail{
  color: #ff8802;
  font-size: 0.875rem;
  line-height: 1.125rem;
}
.random-eye{
  font-size: 13px;
  line-height: 15px;
  color: #b52830;
  font-family: 'robotomedium';
}
.accordion>.card.layout-view{
  overflow: visible;
}
.add-club-wrapper .accordion{
  margin: 0 -15px;
}
.add-club-wrapper .accordion .card{
  border: none;
  border-radius: 0;
}
.bucket-placeholder{
  position: absolute;
  left: 16px;
  right: 16px;
  top: 16px;
  bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bucket-placeholder-knockout{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  margin: 0 !important;
}

#addPlayerList{
  z-index: 1;
  position: relative;
  background: none;
}
#collapseTournamentDetails .plus-icon{
  z-index: 0;
}
.tournament-edit-item .show-date,
.tournament-list-item .arrow-icon,
.tournament-edit-item .match-number,
.tournament-edit-item .edit-delete-panel,
.tournament-list-item .save-match-panel,
.tournament-list-item .edit-date .calendar-icon,
.tournament-list-item .edit-date .arrow-icon{
  display: none;
}

.tournament-edit-item.tournament-list-item .arrow-icon,
.tournament-edit-item.tournament-list-item .save-match-panel,
.tournament-edit-item.tournament-list-item .edit-date .calendar-icon,
.tournament-edit-item.tournament-list-item .edit-date .arrow-icon{
  display: block;
}
/* .none-select-field{
  font-family: 'robotobold';
  pointer-events: none;
} */
.tournament-edit-item .none-select-field{
  font-family: 'robotoregular';
  pointer-events: initial;    cursor: pointer;    width: 100px !important;
  text-align-last: center;
  text-align: center;
}
.tournament-edit-item .none-select-field option{text-align:left;}

.bgGreenStatus .none-select-field{cursor: unset;}
.edit-date,
.show-date{
  max-width: 185px;
  width: 100%;
}
#collapseTournamentDetails .twitch-channel-list.dropdown-menu{
  max-height: 200px;
  overflow-y: auto;
  z-index: 11;
}
.group-ride > div{
  height: 100%;
}
.layout-view-in-channel{
  border-radius: 8px 8px 0 0;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
}
.channel-list{
  border-radius: 0 0 6px 6px;
}
.layout-view-in-channel .text-error{
  width: 100%;
  text-align: center;
}
.primary > div,
.primary >  div{
    height: 400px;
}
.primary{
    background-color: red;
}
.primary + .primary{
    background-color: rosybrown;
}
.primary + .primary + .primary{
    background-color: seagreen;
}
#Group0 ~ div{
  border-radius: 3px;
  border: solid 1px #cccccc;
  position: relative;
  padding: 8px 16px 16px;
  display: none;
  flex: 1;
  min-height: 200px;
}
#Group0 ~ div.active{
  display: block;
}
[data-rbd-droppable-context-id="0"] {
  min-height: 226px;
}
#Group0{
  opacity: 1 !important;
  width: 307px;
  margin-right: 35px;
}
[data-rbd-droppable-id="GroupA"],
[data-rbd-droppable-id="GroupB"],
[data-rbd-droppable-id="GroupC"],
[data-rbd-droppable-id="GroupD"],
[data-rbd-droppable-id="GroupE"],
[data-rbd-droppable-id="GroupF"],
[data-rbd-droppable-id="GroupG"],
[data-rbd-droppable-id="GroupH"],
[data-rbd-droppable-id="GroupI"],
[data-rbd-droppable-id="GroupJ"],
[data-rbd-droppable-id="GroupK"]{
  overflow: hidden;
  min-height: 226px;
}
[data-rbd-droppable-id="GroupA"] > div:nth-child(2n+1),
[data-rbd-droppable-id="GroupB"] > div:nth-child(2n+1),
[data-rbd-droppable-id="GroupC"] > div:nth-child(2n+1),
[data-rbd-droppable-id="GroupD"] > div:nth-child(2n+1),
[data-rbd-droppable-id="GroupE"] > div:nth-child(2n+1),
[data-rbd-droppable-id="GroupF"] > div:nth-child(2n+1),
[data-rbd-droppable-id="GroupG"] > div:nth-child(2n+1),
[data-rbd-droppable-id="GroupH"] > div:nth-child(2n+1),
[data-rbd-droppable-id="GroupI"] > div:nth-child(2n+1),
[data-rbd-droppable-id="GroupJ"] > div:nth-child(2n+1),
[data-rbd-droppable-id="GroupK"] > div:nth-child(2n+1){
  width: 49%;
  float: left;
  margin-top: 8px;
}

[data-rbd-droppable-id="GroupA"] > div:nth-child(2n+2),
[data-rbd-droppable-id="GroupB"] > div:nth-child(2n+2),
[data-rbd-droppable-id="GroupC"] > div:nth-child(2n+2),
[data-rbd-droppable-id="GroupD"] > div:nth-child(2n+2),
[data-rbd-droppable-id="GroupE"] > div:nth-child(2n+2),
[data-rbd-droppable-id="GroupF"] > div:nth-child(2n+2),
[data-rbd-droppable-id="GroupG"] > div:nth-child(2n+2),
[data-rbd-droppable-id="GroupH"] > div:nth-child(2n+2),
[data-rbd-droppable-id="GroupI"] > div:nth-child(2n+2),
[data-rbd-droppable-id="GroupJ"] > div:nth-child(2n+2),
[data-rbd-droppable-id="GroupK"] > div:nth-child(2n+2){
  width: 49%;
  float: right;
  margin-top: 8px;
}
[data-rbd-droppable-id="Group0"] > div{
  margin-top: 8px;
}
[data-rbd-droppable-id="Group0"]{
  min-height: 226px;
  max-height: 226px;
  overflow-y: auto;
  overflow-x: hidden;
}
.tournament-list-item .react-datepicker-popper,
.tournament-list-item .react-datepicker{
  width: 328px !important;
}
.rounds.round-input {
  padding: 5px 5px;
}
.round-input{
  height: 32px;
  min-height: 32px;
  margin: 0;
  padding: 5px 9px;
  font-size: 14px;
  line-height: 17px;
}
.schedule-date-col {
  height: 32px;
  min-height: 32px;
}
/* .round-input-wrapper{ */
  /* max-width: 200px;
  width: 100%; */
/* } */
.round-input-wrapper.edit-save-btn{
  min-width: 190px;
  max-width: 190px;
  width: 190px;
}
.remove-fields,
.add-fields{
  position: relative;
  height: 24px;
  width: 24px;
  border-radius: 100%;
  border: 2px solid #b52830;
}
.remove-fields::before,
.add-fields::before,
.add-fields::after{
  content: "";
  position: absolute;
  left: 4px;
  top: 9px;
  width: 12px;
  height: 2px;
  background: #b52830;
}
.add-fields::after{
  transform: rotate(90deg);
}
.round-input-wrapper .text-error{
  top: 30px;
}
.middle-name .text-error{
  top: -14px;
  right: 0;
  font-family: 'robotobold';
}
.middle-name .text-error:before {
  content: "";
    position: absolute;
    top: -59%;
    left: 52%;
    transform: translateX(-50%);
    margin-left: -5px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #d6d6d6 transparent;
}
.rounds.round-input {
  padding: 5px;
}
.home-page .loadings {
  width: 100%;
  overflow: hidden !important;
}
.card-col .card-top .scrols {
  height: 103px;
  overflow-y: auto;overflow-x: hidden;
}
.card-col:hover .card-top .scrols::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}
.card-col .card-top .scrols::-webkit-scrollbar {
  width: 0px;
  height: 10px;
}
.middle-name .text-error.errors {
    top: 37px;
    right: 0;
    font-family: 'robotobold';
    padding: 10px;
    z-index: 1;
    background-color: #d6d6d6;
}
.middle-name.input-error.errors{
  border: 1px solid #b52830;
}
#tournamentSchedule .red-btn.disabled{
  opacity: 0.5;
}
/* .tab-details-view .update-showing .show-page-number{
  top: -60px;
} */
/* .update-showing .global-table{overflow:hidden;} */
.generate-list-item{
  position: absolute;
  right: -11px;
  top: -100px;
  z-index: 1;
}
.generate-list-item-mobile-screen{
  top: 10px;
  right: -2px;
  font-weight: 100;
}

.generate-list-item-knockoutSchedule {
  top: -3px;
  right: 6px;
}
.add-club-wrapper.disable-all-fields #addPlayerList,
.add-club-wrapper.disable-all-fields .remove-list-name,
.add-club-wrapper.disable-all-fields .red-btn,
.add-club-wrapper.disable-all-fields .generate-list-item,
.add-club-wrapper.disable-all-fields .middle-name select{
  pointer-events: none;
  opacity: 0.8;
  background: #f5f5f5;
}
div#tournamentSchedule .tournament-list, #knockoutSchedule .tournament-list {
  padding: 16px 8px 8px;
}
.tournament-list::-webkit-scrollbar-thumb {
  background: #90a979;
  border-radius: 8px;
}
.tournament-list::-webkit-scrollbar {
  width: 11px;
  height: 4px;
}
.disable-all-fields .none-select-field{
  pointer-events: none;
  opacity: 0.8;
  background: #f5f5f5;
}
.edit-save-btn .red-btn{
  font-size: 15px;
  min-width: 80px;
  height: initial;  
  padding: 6px 20px;
}
.edit-save-btn.disable-all-fields .red-btn,
.add-club-wrapper.disable-all-fields .edit-save-btn .red-btn{
  pointer-events: none;
  opacity: 0.6;
  background: #b52830;
}
.col-frame-width{
  max-width: 60px !important;
  min-width: 60px;
  width: 100px !important;
}
.col-match-width {
  max-width: 100px;
  min-width: 100px;
  width: 100% !important;
}
.frame-col{margin-left: 48px}
.table-col{margin-left:26px}
#knockoutSchedule .col-frame-width, #tournamentSchedule .col-frame-width{
  max-width: 75px;
}
.score-modal th {
  width: 20%;
}
.score-modal .modal-dialog {
  max-width: 750px;
}
.score-modal .round-input {
  height: 32px;
  min-height: 32px;
}
.score .red-btn {
  padding: 0px;
  height: 27px;
  margin: 0px;
  min-width: 53px;
  font-size: 13px;
  margin-top: 2px;
}
.btnDisable{
  padding: 0px;
  height: 27px;
  margin: 0px;
  min-width: 53px;
  font-size: 13px;
  margin-top: 2px;
  background-color: #b52830;
  color: white;
  border-radius: 18px;
  opacity: 0.6;
}
.schedule-date-col {
  max-width: 200px;
  min-width: 200px;
  padding-left: 46px;
  width: 200px !important;
  text-align: center;
}
.red-checkbox .form-check-label {
  padding: 5px 0 5px 2px;
}
.col-finalScore-width{
  width: 100%;
  max-width: 175px;
  min-width: 175px;
  text-align: center;
}

.player-col-width {
  min-width: 267px;
  text-align: center;
  margin-left: 25px;
}
.referees-col {
  text-align: center;
  min-width: 88px;
}
.notes-col {
  text-align: center;
  min-width: 276px;
}
textarea.form-control.round-input {
  height: 32px;
  min-height: 32px;
}
.tableName-width{
  width: 100%;
  max-width: 89px;
  min-width: 89px;
}
/* Tournament page css end here ----------**********-- */
/* 12.) Home page css start here -------------------- */
html{
  scroll-behavior: smooth;
  scrollbar-width: thin;
}
.home-page-headeer{
  position: fixed;
  left: 0;
  right: 0;
  z-index: 11;
  background: url(../images/home-page-header-bg-new.webp) center top no-repeat;
  background-size: cover;
  height: 56px;
}
.home-page-navigation{
  position: absolute;
  left: 0;
  top: 56px;
  width: 100%;
  background: #010e00;
  border-top: 1px solid #cccccc;
  padding: 15px 15px 25px;
  border-bottom: 1px solid #cccccc;
  box-shadow: 0 15px 24px 0 rgba(0, 0, 0, 0.12);
}
.home-page-navigation > li {
  margin-bottom: 10px;
}
.home-page-navigation li > span{
  color: #ffffff;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  font-family: 'robotomedium';
  padding-bottom: 5px;
}
.home-page-navigation li > span > a {
  color: #fff;
  text-decoration: none;
}
.home-page-navigation li > span:hover,
.home-page-navigation li > span:active{
  border-bottom: 2px solid #e70606;
  color: #ffffff;
}
.home-page-navigation li span.register-to-play{
  border: 2px solid #ffffff;
  border-radius: 20px;
  padding: 11px 23px;
  display: block;
  text-align: center;
}
.home-page-navigation li span.register-to-play:hover{background:linear-gradient(0deg, #f5fcff 0%, #fdf6f9 100%);color: #000;}
.main-content{
  padding: 75px 0 0;
  background: #124e25 url(../images/bg-left.png) left center no-repeat;
  position: relative;
}
.main-content:after{
  content: '';
  right: 0;
  top: 200px;
  position: absolute;
  background:url(../images/br-right.png) left center no-repeat;
  height: 200px;
  width: 100px;
}
.match-card-wrapper h3{
  font-size: 24px;
  line-height: 28px;
}
#liveMatch h3{
  color: #b52830;
}
#upcomingMatch h3{
  color: #2d3c25;
}
#pastMatch h3{
  color: #000000;
}
.match-card-item{
  border-radius: 20px;
  /* box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  padding: 15px; */
}

.newsCard {
padding: 17px 15px !important;
border-top: 0px !important;
}
.newsCard span{
  font-size: 17px !important;
}
/* .match-video{ */
  /* border-radius: 16px;
  flex: 1; */
/* } */
/* .match-video iframe{ */
  /* border-radius: 16px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%; */
/* } */
/* .match-video-details{ */
  /* padding: 18px 0 0 0; */
  /* flex: 1; */
/* } */
/* .club-name-info span{ */
  /* font-size: 18px;
  line-height: 18px;
  color: #666666;
  font-family: 'robotoitalic';
  width: 114px; */
/* } */
/* .club-name-info strong{ */
  /* font-size: 18px;
  line-height: 18px;
  color: #333333;
  font-family: 'robotomedium_italic'; */
/* } */
/* .match-type-info li{ */
  /* margin: 0 0 10px 0; */
/* } */

/* .match-type-info li span{ */
  /* font-size: 16px;
  line-height: 18px;
  color: #666666;
  font-family: 'robotoitalic'; */
/* } */
/* .match-type-info li strong{ */
  /* font-size: 16px;
  line-height: 18px;
  color: #b52830;
  font-family: 'robotomedium_italic'; */
/* } */
/* .coming-live{ */
  /* background: #f3f3f3;
  min-height: 70px;
  border-radius: 12px;
  padding: 15px; */
/* } */
/* .coming-live span{ */
  /* font-size: 16px;
  line-height: 18px;
  color: #666666;
  font-family: 'robotoitalic'; */
/* } */
/* .coming-live strong{ */
  /* font-size: 16px;
  line-height: 18px;
  color: #333333;
  font-family: 'robotomedium_italic'; */
/* } */

/* .upcoming-match-start-details .match-start-from strong{ */
  /* font-size: 16px;
  font-family: 'robotobold_italic'; */
/* } */
/* .upcoming-match-start-details span{ */
  /* font-size: 12px;
  line-height: 18px;
  color: #858585; */
/* } */
/* .upcoming-match-start-details strong{ */
  /* font-size: 24px;
  line-height: 18px;
  color: #333333;
  font-family: 'robotobold'; */
/* } */
/* .upcoming-match-start-details ul li:first-child{ */
  /* padding-left: 0; */
/* } */
/*.home-page-footer{
  background-color: #2c2c2c;
  padding: 28px 15px;
}*/

.newsCardMember .text-truncate{max-width: 650px;}
.disableSlotbtn{
    box-shadow: none;
    color: #ffffff;
    background-color: #ab585d;
    border-color: white;
    font-size: 14px;
    height: 40px;
    border: 1px solid #f07279;
    padding: 10px 28px;
    font-size: 14px;
    border-radius: 20px;
}
.home-page-footer-nav li{
  position: relative;
  padding-left: 20px;
  z-index: 3;
}

.home-page-footer-nav li:first-child{
  padding-left: 0;
}
.home-page-footer-nav li::before{
  content: "";
  position: absolute;
  left: 8px;
  top: 10px;
  height: 5px;
  width: 5px;
  background: #ffffff;
  border-radius: 100%;
  opacity: 0.2;
}
.home-page-footer-nav li:first-child::before{
  display: none;
}
.home-page-footer-nav li a{
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  font-family: 'robotomedium';
}
.home-page-footer-nav li a:hover{
  color: #ffffff;
}
.home-page-footer p{
  font-size: 13px;
  line-height: 15px;
  color: #ffffff;
  opacity: 0.6;
  font-family: 'roboto_condensedregular';
  text-align: center;
}
.home-page-footer p.copy-right{
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  opacity: 0.4; 
}
.mobile-menu{
  position: fixed;
  right: 16px;
  top: 20px;
}
.logo-cloud{
  position: relative;
  z-index: 2;
}
.logo-cloud::before{
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -2;
  object-fit: contain;
  opacity: 0.84;
  filter: blur(13.6px);
  background-color: #ffffff;
}
.inner-shadow-for-logo{
  background-image: linear-gradient(to right, #ffffff 28%, rgba(255, 255, 255, 0));
  background-size: 500px;
  background-repeat: no-repeat;
}
.select-red-bg{
  background: linear-gradient(0deg, #fafffc 0%, #fdf6f9 100%);
  border-radius: 4px;
  text-align: center;
  color: black;    border: 1px solid #ced4da;
}
div#knockoutSchedule .generate-list-item-knockoutSchedule {
  top: 10px;
  right: -2px;
}
.tournament-list .generate-list-item {
  top: -79px;
}
div#knockoutSched .generate-list-item{ top: -3px;
  right: 6px;}
.bgGreenStatus .schedule-date-col {
  background: linear-gradient(0deg, #e9ecef 0%, #e9ecef 100%);
  opacity: 1;
}
.bgGreenStatus .schedule-date-col .react-datepicker-wrapper input{
  cursor: unset;
}
.hide-button{
  display: none;
}
.show-button{
  display: block;
}
.match-container{
  padding: 23px 15px 16px;
  border-radius: 20px; 
}
.bg-green{
  background: #124e25;
}
.match-logo{
  background: #ffffff;
  width: 93px;
  height: 40px;
  border-radius: 4px; 
}
.match-container h4{
  font-size: 15px;
  line-height: 18px;
  color: #ffffff;
}
.list-of-type li{
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  font-family: 'robotomedium_italic';
  position: relative;
}


.bg-green-child{
  border-radius: 12px;
  background-color: rgba(0, 0, 0, 0.4);
}
.name-wrapper{
  padding: 15px;
}
.name-wrapper > div{
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  /* flex: 1; */
}
.number-text{
  font-size: 24px;
  line-height: 40px;
  color: #ffffff;
  font-family: 'robotobold';
  padding: 5px;
  border-radius: 8px;
  min-width: 55px;
}
.red-text{
  background-color: #b52830;
}
.best-of{
  font-size: 11px;
  line-height: 12px;
  color:rgba(255, 255, 255, 0.7)
}
.best-of > div{
  font-size: 18px;
  line-height: 20px;
  color:rgba(255, 255, 255, 0.7);
  font-family: 'robotobold';
}
.best-of strong{
  color:rgba(255, 255, 255, 1)
}
.red-icon{
  position: absolute;
  bottom: 9px;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  box-shadow: 0 0 4px 0 rgba(237, 45, 45, 0.6);
  background-color: #f14949;
}
.left-side{
  left: 3px;
}
.right-side{
  right: 3px;
}
.live-match-btn{
  background-color: #e60000;
  border-radius: 20px;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.12);
  padding: 3px;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  font-family: 'robotomedium';
  padding: 3px 9px;
  max-width: 60px;
}
.match-card-item .match-container{
  margin-top: 15px;
  /* flex: 0 0 49.5%;
  max-width: 49.5%; */
  width: 100%;
}
.match-card-item .match-container:first-child{
  margin-top: 0;
}
.bg-gray{
  background: #2d3c25;
}
.bg-white{
  background: white !important;
}
.white-text{
  background: #efefef;
}
.number-text.white-text{
  color: #000000;
}
.bg-light-black{
  background: #2c2c2c;
}
.light-black-text{
  background: rgba(255, 255, 255, 0.16);
}
.nonMemberTop{
  padding-top: 30px;
}
.left-padding{
  padding-left:5px;
}
.play-icon{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 25px;
  width: 25px;
  margin: auto;
  cursor: pointer;
}
.match-live-icon{
  position: absolute;
  left: 14px;
  top: 16px;
  border-radius: 28px;
  background-color: #e53935;
  width: 84px;
  height: 30px;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  font-family: 'robotomedium';
  display: flex;
  align-items: center;
  justify-content: center;
}
.social-share-link{
  position: absolute;
  right: 15px;
  top: 23px;
}
.thumbnail-video{
  width: 95px;
  height: 53px;
  border: solid 1px rgba(255, 255, 255, 0.7);
  overflow: hidden;
  cursor: pointer;
}
.player-1{
  position: absolute;
  left: 15px;
  bottom: 7px;
  width: 50%;
}
.player-2{
  position: absolute;
  right: 15px;
  bottom: 7px;
  width: 50%;
  text-align: right;
}
.popup-video .login-page{
  padding: 5px;
}
.popup-video .modal-content{
  border-radius: 5px;
}
.popup-video .popup-close-btn svg path {
  fill: #ffffff;
}
.popup-video .popup-close-btn{
  margin-top: -39px;
}
#registerAsPlayer .register-as-player{
  border-radius: 8px;
  padding: 40px 15px;
  background: none;
}
#registerAsPlayer .add-player-inner{
  padding: 15px 0;
  box-shadow: none;
}
/* Home page css end here ----------**********-- */

/* Tournament fixture styling */
.tournament-fixture-container{
  background-color: #fff;
  flex:1
}
.tournament-fixture-heading{
  margin: 0 auto;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  flex-direction: column;
}
.fixture-heading-text{
  font-size: 22px;
  font-weight: bold;
  color: #333333;
  line-height: 2;
  text-transform:capitalize ;
}
.fixture-card-item{
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  padding: 0px;
  border-radius: 20px;
  width: 97%;
  margin: 16px auto;
}
.fixture-container{
  padding: 23px 15px 16px;
  border-radius: 20px; 
}

.fixture-container h4{
  font-size: 24px;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
}
.fixture-frame-text{
  margin: 0 auto;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 0;
  margin-bottom: 0rem;
}
.fixture-detail-wrappper{
  padding: 27px 24px;
  justify-content: space-between;
  align-items: center;
}
.fixture-card-item .player{
  font-size: 16px;
  color: #ffffff;
  font-weight: normal;
  font-style: normal;
  letter-spacing: normal;
}
.fixture-player-1{
  width: 50%;
  padding-right: 24px;
}
.fixture-player-2{
  width: 50%;
  padding-left: 24px;
}
.fixture-text-bold{
  font-size: 14px;
  font-weight: 600;
}
.fixture-champion-container{
  display: flex;
  justify-content: center;
  width: 48%;
}

.fixture-winner{
  width: 48%;
  text-align: center;
}
.fixture-runnerup{
  width: 48%;
  text-align: center;
}
.fixture-winner h3{
  font-size: 20px;
  font-weight: 500;
  color: #124e25;
  margin-bottom: 0px;
}
.fixture-runnerup h3{
  font-size: 20px;
  font-weight: 500;
  color: #b52830;
  margin-bottom: 0px;
}
.fixture-player-frames{
  min-width: 70px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.runner-up{
  text-align: left;
}
.fixture-champion-divider{
    height: inherit;
    width: 1px;
    background-color: #D3D3D3;
    align-items: center;
    top: 4px;
    position: relative;
}
.social-icon-container{
  position: absolute;
  top: 30px;
  right: 40px;
}
.share-icons{
  position: absolute;
  right: 9px;
  top: 1px;

}
.fixture-share-icon{
  position: absolute;
  right: 30px;
  top: 56px;
}

.fixture-best-of{
  font-size: 11px;
  line-height: 12px;
  color:rgba(255, 255, 255, 0.7)
}
.fixture-best-of > div{
  font-size: 18px;
  line-height: 20px;
  color:rgba(255, 255, 255, 0.7);
  font-family: 'robotobold';
}

.tournamentCreateHeading{
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 73px;
  z-index: 22;
  background: #eee;
  padding-left: 15px;
  min-height: 82px;
}

.tournamentCreateButtons{
  justify-content: space-between;
  align-items: center;
  display: flex;
}
.tournamentCreateButtons .form-group,
.tournamentCreateButtons .red-btn{
  margin-right:15px;
}
.collapse.show ~ .d-flex > .tab-details{
  display: none;
}

.edit_pencil {
  font-size: 20px;
  padding: 10px;
  border: 1px solid #f7f7f7;
  background: #f7f7f7;
  color: #bdb5b5;
  cursor: pointer;
}
.edit_pencil:hover{
  color:black;
}

/*round-robin*/

.addGroupRoundRobin{
  position: relative;
  height: 24px;
  width: 24px;
  border-radius: 100%;
}

.addGroupRoundRobin::before,
.addGroupRoundRobin::after{
  content: "";
  position: absolute;
  left: 4px;
  top: 9px;
  width: 12px;
  height: 2px;
  background: #b52830;
}

.addGroupRoundRobin::after{
  transform: rotate(90deg);
}

.addGroupText{
  font-size: 14px;
  color: #b52830;
  margin-right: 4px;
}

.addGroupWrapper{
  cursor: pointer;
}

.round-robin-nav-item{
  width: 100px;
  float: right;
  display: flex;
  align-items: center;
  margin: 0px !important;
}

.removeRoundRobin{
  position: relative;
  height: 16px;
  width: 16px;
  border-radius: 100%;
  border: 2px solid #b52830;
  left: 4px;
  bottom: 3px;
  
}

.removeRoundRobin::before{
  content: "";
  position: absolute;
  left: 1px;
  top: 5px;
  width: 10px;
  height: 2px;
  background: #b52830;
}
.column-wrapper .child:first-child{
  padding: 16px;
  max-height: 288px;
  overflow-y: auto;
  min-height: 200px;
  /* max-width: 310px; */
  flex: 0 0 310px;
  overflow-x: hidden;
  margin-right: 0;
  margin-bottom: 10px;
  display: block;
  opacity: 1;
}
.column-wrapper .child > div{cursor:unset;}
.column-wrapper .child > div{
  border-radius: 3px;
  border: solid 1px #cccccc;
  background-color: #f3f3f3;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  padding: 9px 13px;
  margin-bottom: 8px;
  float: none !important;
}
.column-wrapper .child{
  max-height: 300px;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  padding: 8px 16px 16px;
  max-width: calc(100% - 345px);
  flex: 0 0 calc(100% - 345px);
  overflow: auto;
}
.child.column-2 > div,
.child.column-3 > div,
.child.column-4 > div,
.child.column-5 > div,
.child.column-6 > div,
.child.column-7 > div,
.child.column-8 > div,
.child.column-9 > div,
.child.column-10 > div,
.child.column-11 > div,
.child.column-12 > div,
.child.column-13 > div,
.child.column-14 > div,
.child.column-15 > div,
.child.column-16 > div,
.child.column-17 > div,
.child.column-18 > div,
.child.column-19 > div,
.child.column-20 > div,
.child.column-21 > div,
.child.column-22 > div,
.child.column-23 > div,
.child.column-24 > div,
.child.column-25 > div,
.child.column-26 > div,
.child.column-27 > div,
.child.column-28 > div,
.child.column-29 > div,
.child.column-30 > div,
.child.column-31 > div,
.child.column-32 > div,
.child.column-33 > div{
  /* float: left; */
  width: calc(33.33% - 10px);
  margin-right: 10px;
}

.randomizeButton{
  border: none;
  background: white;
  color: #b52830;
  position: relative;
  bottom: 6px;
  font-size: 14px;
}



/* ======== Group Result CSS Start Here ========= */
.table-group{
  border-radius: 20px;
  background-color: #2d3c25;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  overflow: auto;
  margin-bottom: 16px;
}
.table-group .active-bg{
  background-color: rgba(255, 255, 255, 0.2);
}
.table-group .table-group-head,
.table-group .table-group-head-v1,
.table-group .table-group-body{
  min-width: 1930px;
  width: 100%;
}
.table-group .group-title{
  flex: 0 0 400px;
  width: 100%;
  border-right: 1px solid #000000;
}
.table-group .group-title.groups {
  flex: 0 0 760px;
}
.table-group-head-col-v1.group-results {
  max-width: 760px;
  flex: 0 0 760px;
  width: 100%;
  padding: 0 0px;
}
.table-group-body-col.group-results.d-flex.align-items-center {
  max-width: 400px;width: 47px;
  /* flex: 0 0 360px; */
 
}
.table-group-head-col.group-results{
  max-width: 760px;
  flex: 0 0 760px;
  width: 100%;
  /* border-right: 1px solid #000000; */
  padding: 0 15px;
}
.table-group-container{
  border-right: 1px solid #000000;
}

.table-group-body-col.highest-break {
  max-width: 120px;
  flex: 0 0 120px;
}
.table-group-body-col.group-matches {
  max-width: 180px;
  flex: 0 0 180px;
}
.table-group .group-matches{
  max-width: 180px;
 flex: 0 0 180px;
  width: 100%;
  border-right: 1px solid #000000;
  padding: 0 15px;
}
.table-group-head-row{
  min-height: 70px;
  font-size: 20px;
}
.table-group .group-matches span,
.table-group .group-frames span,
.table-group .group-results span{
  padding: 5px 0;
  max-width: 35px;
  margin: 0 7px;
  width: 100%;
}
.table-group .large-font{
  font-size: 40px;
  line-height: 64px;
  font-weight: 600;
}
.group-title .table-group-head-col-v1 {
  color: #fff;
}
.table-group .group-title .code-col{
  max-width: 80px;
  flex: 0 0 80px;
  padding: 9px 12px;
  border-right: 1px solid #000000;
}
.table-group-head-col-v1.group-results span {
  color: #fff;
}
.table-group-head-col-v1 span {
  color: #fff;
}
.table-group .highest-break.custom-bg {
  color: #fff;
}
input.table-group-body-col.highest-break.table-group-position {
  height: 32px;
  border-right: 1px solid #ced4da;
  margin: 3px 0px 3px 3px;
  max-width: 133px;
  flex: 0 0 133px;
}
.table-group-checkbox:before {
  position: absolute;
  content: "";
  background: #000;
  height: 43px;
  width: 1px;
  left: 3px;
}
.table-group .group-title .name-col{
   max-width: 174px; 
  flex: 0 0 174px;
  width: 150%;
  text-align: left;
  padding: 9px 12px;
  border-right: 1px solid #000000;
}
.table-group .group-title .country-col{
  max-width: 150px;
  flex: 0 0 150px;
  width: 100%;
  text-align: left;
  padding: 8px 12px;
}
.table-group .blank-space{
  background-color: rgba(255, 255, 255, 0.2);
  width: 10px;
  height: 30px;
}
.table-group .group-frames{
  max-width: 165px;
  flex: 0 0 165px;
  border-right: 1px solid #000000;
  padding: 0 15px;
}
.table-group .highest-break{
  max-width: 140px;
  flex: 0 0 140px;
  width: 100%;
  padding: 5px 12px;
  border-right: 1px solid #000000;
}
.table-group .highest-break.border-0{
  border: none;
}
.table-group-head-row-v1{
  background-color:#243122;
  height: 40px; 
  background-color: rgba(18, 78, 37, 0.24);
}
.table-group-body-row{
  min-height: 39px;
  border-bottom: 1px solid #000000;
}
.table-group-body-row:last-child{
  border: none;
}
.table-group-body{font-size: 14px;}
.table-group .highest-break.custom-bg{
  height: calc(100% + 40px);
  background-color:#243122;
  position: relative;
  z-index: 1;
  font-size: 16px;
  top: -4px;
}

.table-group-position{
  height: 38px;
  padding: 5px 15px;
  font-size: 18px;
  text-align: center;
}

.table-group-checkbox{
  position: relative;
  top: 10px;
  width: 100%;
  border-radius: 2px;
}
.table-group .highest-break.table-group-checkbox {
  height: 19px;
}
.add-club-wrapper .accordion .card .layout-view .table-group.card_bg {
  overflow-y: hidden;
}
.selected-player{
  font-size: 16px;
}

.generate-list-item-group-match{
  position: absolute;
  right: 8px;
  top: 20px;
  z-index: 10;
}

.payment-details .defult-card{
  background: url(../images/payment-card-bg.png) center top no-repeat;
  width: 336px;
  height: 193px;
}
.payment-details .defult-card a{
  color: #b52830;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
}
.payment-details .card-ui{
  background: url(../images/payment-ui.svg) center top no-repeat;
  width: 281px;
  height: 203px;
  float: right;
}
.card-top-right{
  background: url(../images/ic_card.svg) center top no-repeat;
  width: 30px;
  height: 25px;
  position: absolute;
  right: 30px;
  top: 30px;
}
.newsPageNumber {
  position: absolute;
  top: -21px;
  color: #666666;
  font-size: 13px;
  line-height: 15px;
  right: 3px;
}


.rdw-embedded-modal-header-option{
  width: 100% !important;   
}
.rdw-link-modal-target-option{
  margin-bottom: 3px !important;
}
.rdw-embedded-wrapper{
  display:none !important;
  }
  
 .rdw-image-modal {
    position: static !important;
    max-width: 500px !important;
 }

.rdw-embedded-wrapper{
  display:none !important;
  }
  
  .rdw-image-modal {
    position: static !important;
    max-width: 500px !important;
  }
  .newsSearch {
    top: -70px;
    position: absolute;
    right: 0px;
}
.topTab{
  top: 40px;
}

.sidebar-details{
  position: relative;
}
.stripe-bottom{
  margin-top: 200px;
}
.stripe-left{
  background: url(../images/powered_stripe.svg) center top no-repeat;
  width: 106px;
  height: 19px;
}
.stripe-right{
  background: url(../images/payment_ui_v2.svg) center top no-repeat;
  width: 111px;
  height: 74px;
}
.info-ico{
  background: url(../images/info-ico.png) center top no-repeat;
  width: 18px;
  height: 18px;
  float: right;
}
.card-details{
  /* background: url(../images/card-bg-ui.png) center top no-repeat; */
  width: 335px;
  height: 191px;
  padding: 22px 24px 20px 24px;
  background: #808080;
}
.card-details h4{
  color: #ffffff;
}
.card-details .card-number{
  color: #fff;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: 2px;
  margin-top: 20px;
}
.card-details .card-details-bottom{
  color: #fff;
  margin-top: 30px;
}
.card-details .card-cvv{
  margin-left: 30px;
  margin-right: 10px;
}
.card-details .card-details-bottom .name-on-card,
.card-details .card-details-bottom .expiry,
.card-details .card-details-bottom .cvv{
  font-size: 12px;
  line-height: 14px;
  opacity:  0.7;
}
.card-details .card-details-bottom .name,
.card-details .card-details-bottom .expiry-date,
.card-details .card-details-bottom .cvv-number{
  font-size: 15px;
  line-height: 22px;
  font-weight: 600;
  margin-top: 5px;
}

.productSpinner{
  position: absolute;
  top: 50%;
  left: 18%;
}



.match-remove-fields{
  position: relative;
  left: 5px;
  height: 24px;
  width: 24px;
  border-radius: 100%;
  border: 2px solid #b52830;
  top: 3px;
  padding: 10px;
}
.blankSpaceDelete{
  height: 24px;
  width: 22px;
  margin-right: 10px !important;
}

.blankSpaceDelete2{
  height: 24px;
  width: 14px;
  margin-right: 10px !important;
}

.match-remove-fields::after,
.match-remove-fields::before{
  content: "";
  position: absolute;
  left: 4px;
  top: 9px;
  width: 12px;
  height: 2px;
  background: #b52830;
}
.red-color-text{
  color: #b52830;
}
.tooltip-cvv{
  display: none;
}
.tooltip-on-hover:hover + .tooltip-cvv { 
  display: block;
  position: absolute;
  background: #e0fffb;
  left: 100%;
  width: 100%;
  font-size: 12px;
  top: 0;
  padding: 5px;
  z-index: 999;
 }
 .removeClass{
   color: #0404d2;
   cursor: pointer;
 }

.create-tournament-red-btn{
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  font-family: "robotomedium";
  border-radius: 20px;
  background-color: #b52830;
  min-width: 100px;
  height: 40px;
  border: none;
  margin: 0;
  padding: 10px 28px;
  position: absolute;
  top: -62px;
  right: -2px;
}

.piceBilling{
  background-color: #e1e6ed !important;
}
/* Tournament page fee collection section css start here ---------------- */

#collapseTournamentFeeCollection .global-table{
  margin: 0 -20px -20px;
  width: initial;
  box-shadow: none;
  border-radius: 0
}
#collapseTournamentFeeCollection .global-table input[type="text"]{
  margin: 0 auto;
}
#collapseTournamentFeeCollection .global-table input[type="text"].maxWidth60{
  max-width: 60px;
  text-align: center;
}

.selection-player input[type="text"]{
  height: 32px;
  padding-left: 80px;
  font-size: 14px;
  font-family: 'robotomedium';
  color: #000000;
}
.selection-player input[type="text"].maxWidth120{
  max-width: 120px;
  padding-left: 65px;
}
.selection-player .title{
  font-size: 14px;
  line-height: 16px;
  color: #666666;
  position: absolute;
  left: 20px;
  top: 8px;
}


/* Tournament page fee collection section css end here -------**********-- */

.bgGreenStatus{
  background-color: #bfecbf;
}
.bgYellowStatus{
  background-color: #eaea5f !important;
}
.bgRedStatus{
  background-color: #f6c3c3;
}

.colorCodeText{
  font-size: 14px;
  color: #666666;
  display: inline-block;
}
.colorCodeText span{
  height: 10px;
  width: 10px;
  display: inline-block;
}

/* Payments Member css start here */

.playerMemberSelectBox{
  min-width:242px;
  height: 37px;
  font-size: 14px;
}

.playerMemberTopLayout{
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 16%);
    background-color: #ffffff;
    padding: 21px
}

.playerNamePaymentTab{
  min-width: 200px;
  width: 200px;
}

.amount-Payment-Tab{
  min-width: 130px;
  width: 130px;
}
.amountInput{
  min-width: 85px;
  width: 85px;
}
.currencyAmt{
  padding: 8px 0;
}

.topAmount {
  width: 55px;
  border: 1px solid #e8e8e8;
  height: 21px;
  margin-top: 1px;
  padding: 0px 5px !important;
}
.topDiscription{
  border: 1px solid #e8e8e8;
  height: 21px;
  margin-top: 1px;
  padding: 0px 5px !important;  width: 280px;
}
.discriptionTab{
  min-width: 260px;
  width: 260px;
}
.discrip-below{
  min-width: 200px;
  width: 200px;
}
.allTransaSelcletBox{
  height: 37px;
  font-size: 14px;
  flex: 1 1 calc(50% - 5px);
  max-width: calc(50% - 5px);
}
.allTransactionTopLayout .form-inline{
  flex: 1 1 calc(50% - 5px);
  max-width: calc(50% - 5px);
}
.rowHeight{
  height: 80px;
}

.datePickerWidth{
  flex: 1 1 calc(100% - 50px);
  max-width: calc(100% - 50px);
}

.searchable{
  max-width: calc(100% - 40px) !important;
}
.payment-details{
  flex: 1 1 calc(100% - 82px);
  max-width: calc(100% - 82px);
}
.allTransactionTopLayout{
    border-radius:0px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 16%);
    background-color: #ffffff;
    padding: 20px 20px 18px 20px; 
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;  
    z-index: 111;width: 100%;
    /* animation: .4s slideInDown ease-in-out;
    -webkit-animation: .4s slideInDown linear; */
}
.allTransactionTopLayout.layout{position: relative;animation: none;}
@keyframes slideInDown {
0% {
  -webkit-transform: translate3d(0,-100%,0);
  transform: translate3d(0,-70%,0);
  visibility: visible;
}

100% {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
}
.allTransactionTopLayout .search-box {
  background: transparent;
  height: 39px;
}
.allTransactionTopLayout .total_curr {
  text-align: center;
  height: 39px;
  width: 20%;
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding: 8px;
  background-color: transparent;
  margin-top: 10px !important;
  display: block;
  margin: auto;
  color: rgb(18, 78, 37);
  font-weight: 600;
}
.allTransactionTopLayout .search-icon {
  top: 10px;
}
.allTransactionTopLayout.retrieving {
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  font-size: 20px;
  color: #741e1e;
  margin-top: 8% !important;
  position: unset;
  animation: none;
  padding: 40px 92px;
  margin: auto;
  background: #e9e9e9;
  box-shadow: none;
  background: linear-gradient(0deg, #e9f5fa 0%, #fdf6f9 100%);
}

.allTransactionTopLayout.retrieving .dot-pulse {
  position: relative;
  left: -9969px;
  width: 22px;
  height: 11px;
  border-radius: 5px;
  background-color: #0053ff;
  color: #0053ff;
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
}
.allTransactionTopLayout.retrieving .dot-pulse::before, .allTransactionTopLayout.retrieving .dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 10px;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: #0053ff;
  color: #0053ff;
}
.allTransactionTopLayout.retrieving .dot-pulse::before {
  box-shadow: 9984px 0 0 -5px;
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
}
.allTransactionTopLayout.retrieving .dot-pulse::after {
  box-shadow: 10014px 0 0 -5px;
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9984px 0 0 -5px;
  }
}
@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9999px 0 0 -5px;
  }
}
@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 10014px 0 0 -5px;
  }
}
.text-muted.black{color:#000 !important; font-size: 14px;}
.allTransactionTopLayout .total_curr {
  width: auto;
   border: none;
  border-radius: 5px;
  padding:0px;
  background-color: #fff;
  text-align: center;
  font-size: 19px;
  font-weight: 800;
}

.reports-transaction > div{padding: 0 10px;text-align: center !important;}
.payment-reports-transaction > div{
  padding: 0 10px
}
.clubname{width: 185px;}
.email{width: 300px;word-break: break-all;}
.allTransaction-list-head{
    font-size: 16px;
    line-height: 16px;
    color: black;
    font-family: 'robotobold';
    font-weight: normal;
    flex: 1 1;
    border-top: 0px;
}
.mobile-screen-Transaction{box-shadow: 0 2px 4px 0 rgb(0 0 0 / 16%);font-size: 16px;}
.allTransaction-list{
  border-radius: 0px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 16%);
  background-color: #ffffff;
  padding: 12px 20px 12px 20px;
  font-size: 14px;    word-break: break-all;
  cursor: pointer;    border-top: 1px solid #dee2e6;

}

.actionTab-width{
  width: 80px;
}

.paymentHeight td{
  padding: 7px 10px 5px 10px;
}
.members.paymentHeights th{position: relative;}

.arrowT {
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
}
.arrows.up{ 
  width: 0px;
  height: 0px;
  border-top: 7px solid #666666;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  transform: unset;top: 24px;
  right: 39px;position: absolute;}
  .arrows.down {
    width: 0px;
    height: 0px;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #666666;
    position: absolute;
    transform: unset;
    right: 39px;
    top: 15px;
}
.up {
  transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
}
.datePickerFont{
  font-size: 14px;
}
.datePickerWidth .react-datepicker-wrapper input[type="text"] {
  font-size: 14px;
  line-height: 16px;
}
.dropbuttonAlign{
  margin-top: -4px;
}
.calendar-icon {
  position: absolute;
  right: 5px;
  top: 9px;
  width: 15px;
}
.on-mobile{
  max-width: 100% !important;
}
/* Payments Member club Super Admin css End here */
@media (min-width: 768px){
  #paymentReviewModal .modal-content {
      padding: 32px;
      min-height: calc(100vh - 500px);
  }
  .on-mobile{
    max-width: 50%;
  }
  
}

@media (min-width: 576px){
  #paymentReviewModal .modal-dialog {
      max-width: 75%;
      margin: 1.75rem auto;
  }
  
  .overLayScore.right-text,
  .overLayScore.left-text {
    font-size: 14px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .for-streaming .overLayScore.right-text,
  .for-streaming .overLayScore.left-text {
    font-size: 14px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .for-streaming .overLayScore.right-text,
  .for-streaming .overLayScore.left-text {
    font-size: 14px;
  }
}

@media (min-width: 1200px){
  .for-streaming .overLayScore.right-text,
  .for-streaming .overLayScore.left-text {
    font-size: 22px;
  }
}

#paymentReviewModal .global-table {
  /* height: 300px; */
  overflow: auto;
}
#paymentReviewModal .color-green{
  color: green;
}

/* Overlay css start here */

/*.overlayout{
  position: fixed;
  left: 15px;
  right: 15px;
  bottom: 15px;
  z-index: 9999;
}*/
.overLayScore.right-text .strikeCircle {
  right: unset;
  margin-left: 0px;
}

.playerDetails .overLayScore.left-text, .overLayScore.right-text{width:33.3%;}
.strikeCircle{
  width: 15px;
  height: 15px;
  box-shadow: 0 0 4px 0 rgb(237 45 45 / 60%);
  background-color: #e53935;
  border-radius: 50%;
  margin-left: -19px;
  border: 1px solid #fff;
  position: absolute;
  right: unset;
  top: 50%;
  transform: translateY(-50%);
}
.viewer{
  width: 150px;
  background-color: #0c0d0c;
  padding: 5px 0px 5px 11px;
  border-radius: 35px;
  position: absolute;
  left: 0;
  bottom: 210px;
  right: 0;
  margin: auto;
}

.for-streaming{
  position: absolute;
  left: 10px;
  bottom: 40px;
  right: 10px;
  margin: auto;
}

.playerDetails{
  background-color: #f4d341;
    padding-bottom:0px;   
}
/* .scrores {
  width: 33.3%;
} */
.overlayout .century {
  color: #124e25;
  background: #e7f5e6; 
  width: auto;
  padding: 2px 46px;  
  font-size: 17px;font-weight: 700;
  letter-spacing: 4px;text-align: center;
}
.overlayout .possible h5 span {
    width:21px;
    height:21px;
    display: block;
    text-align: center;
    line-height: 21px;
    border-radius: 50%;color: #fff;  margin: 0px 1px;

}
.overlayout .possible h5 {
  font-size: 11px;display: flex;flex-wrap: wrap;
  font-family: 'robotomedium';    margin: 0px 1px;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 10px;
}
.overlayout .possible .red{background:#e01312;}
.overlayout .possible .blue{background:#0602ff;}
.overlayout .possible .green{background:#124e25;}
.overlayout .possible .pink{background:#f96189;}
.overlayout .possible .brown{background:#4c230d;}
.overlayout .possible .black{background:#171717;}
.overlayout .possible .yellow{background:#eeaa34;}
.overlayout .breaks {
  display: flex;
  border-radius: 0px 0px 10px 10px;
  box-shadow: 0 1px 0px 0 rgb(18 78 37 / 10%);    background: #f1f1f1;width: 35.3%;    padding: 2px 0;
  align-items: center;
  /* justify-content:space-around; */
}
.overlayout .breaks{
  width: 100% !important;
}

.overlayout .breaks.player1-break {
  border-radius:unset !important;
}

.overlayout .breaks.ahead {
  width: 36.8% !important;
}
.overlayout .breaks.visibles{visibility: hidden;}
.overlayout .century.visibles{visibility: hidden;}
.overlayout .possible h4 {
  margin-bottom: 0px;
  font-size: 13px;
  padding-left: 5px;
  color: #000000;
  font-weight: 300;
  font-family: 'robotoregular';
}
.overlayout .possible h4{
  color: #555555;
}
.overlayout .possible h4 span{
  color: #000;
}
.overlayout .possible h4{
  white-space: nowrap;
}
.overlayout .possible h4 span {
  font-size:13px;    font-weight: 800;
}
.playerScores{
  background-color: #124e25;
  color: #fff;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.for-streaming .playerScores {
  font-size: 20px;
}
.playerScores.red{
  background-color: #fff;
}
.overLayScore{
  font-size: 14px;
  color: #000;
  text-align: center;
  margin: 0px;position: relative;
  font-weight: 800;
}
/*.outerBorder{
  border: 2px solid white;
  border-radius: 18px;
  padding: 5px;
  margin: 0 auto;
}*/
.playerScoreOne {
  border-radius: 0px;
  position: relative;
  width: 80px;
  overflow: hidden;
  text-align: center;
  margin-right: -16px;
  clip-path: polygon(0 0, 100% 0%, 81% 100%, 0% 100%);
  color: #124e25;
  background-color: #fff;
}

.playerScoreOne:after {
  content: "";
  position: absolute;
  margin: 22px;
  width: 34px;
  height: 85px;
  transform: rotate(28deg);
  background-color:#fafcfc;
  top: -18px; 
  right: -37px;display: none;
}
.number-score{
  width: 90px;
  position: relative;
  margin-left: 0px;
  background: #124e25;
  clip-path: polygon(11% 0%, 100% 0%, 89% 100%, 0% 100%);
}
.number-score .overLayScore span {
  padding: 0px 2px;
}
.number-score svg{
  display: none;
}
.number-score .overLayScore {
  color: #fff !important;
}
.playerScoreTwo {
  border-radius: 0px;
  position: relative;
  width: 80px;
  overflow: hidden;
  text-align: center;
  clip-path: polygon(19% 0, 100% 0%, 100% 100%, 0 100%);
  background: #fff;
  color: #124e25;
  margin-left: -19px;
}
.playerScoreTwo:before {
  content: "";display: none;
  position: absolute;
  margin: 0;
  width: 34px;
  height: 85px;
  transform: rotate(-69deg);
  background-color: #fafcfc;
  top: 7px;
  left: -13px;
  z-index: 11;
}

.number-score::before{
    content: "";
    position: absolute;
    left: 19px;
    top: -24px;display: none;
    width: 1px;
    height: 63px;
    background-color: #999999;
    transform: rotate3d(1, 1, 1,50deg);
  }
  .number-score::after{
    content: "";
    position: absolute;
    right: 11px;
    top: -24px;display: none;
    width: 1px;
    height: 63px;
    background-color: #999999;
    transform: rotate3d(1, 1, 1,50deg);
  }

.paymentMemberTable .scrollPaymentTbl {
  display:block;
  max-height: 400px;
}
.paymentMemberTable .col-2 {
  width: 200px;
  max-width: none;
  flex: unset;
}
.paymentsubscription .select-box.staus-box{width: 136px;}
.paymentMemberTable thead, .paymentMemberTable tbody tr {
  display:table;
  width:100%;
  table-layout:fixed;
  overflow-wrap:break-word;
}

.hide {
  display: none;
}
.show {
  display: block;
}

.centerPosRelative .error-alert, .centerPosRelative .success-alert{
  position: relative;
}

/* -------------privacy-------policy css starat here--------------- */
.information-points{list-style: square;}
.TERM-AND-TERMINATION small p span{font-size: 15px !important;}
.termsconditions a:hover{color:#3030F1 !important;}
.bucket-tooltipText{
  font-size: 12px;
  line-height: 15px;
  display: block;
  color: #fff;
}
.tooltip-bucket-wapper{
  position: absolute;
  right: -184px;
  top: 50%;
  transform: translateY(-50%);
  background: #b52830;
  padding: 10px;
  border-radius: 10px;
  width: 170px;
  display: none;
  text-align: center;
}
.tooltip-bucket::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -30px;
  border-width: 10px 10px 10px 10px;
  border-color: transparent #b52830 transparent transparent;
  border-style: solid;
}
.plus-icon-player.add-icon:hover .tooltip-bucket-wapper{
display: block;
}
.ABOUT-US h4 {
  font-family: 'robotoregular';
  font-size: 18px;
  position: relative;
  margin-bottom: 25px;
}
.ABOUT-US h4:before {
  position: absolute;
  content: "";
  width:100%;
  height: 1px;
  background: #6c757d;
  bottom: -8px;
}
.ABOUT-US img {
  width: fit-content;
  display: block;
  margin: auto;
}
.ABOUT-US h5 {
  font-size: 17px;
}
.ABOUT-US p {
  font-size: 15px;
  text-align: justify;
}
.ABOUT-US p a{text-decoration:underline;font-weight: 700;color: #000;}
.ABOUT-US hr {
  margin: 10px 0;
}
.ABOUT-US .solid li {
  list-style: circle;
}
.ABOUT-US li {
  list-style: disc;
}
.font-15 {
  font-size: 15px;
}
.total_curr {
  width: 20%;
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding: 5px;
  background-color: #f5f5f5;
}

.fa-google {
  background: conic-gradient(from -45deg, #ea4335 110deg, #4285f4 90deg 180deg, #34a853 180deg 270deg, #fbbc05 270deg) 73% 55%/150% 150% no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
  padding: 0 15px;
}
.google_btn{
  padding: 15px 20%;
  border-radius: 10px;
  border: none;
  background-color: white;
}

.fa-facebook{
  padding: 0 15px;
  color: blue;
}
.info_icon {
  margin: 0 5px;
  color: #0041c1;
  cursor: pointer;
  position: relative;
}
.info_icon:hover .tooltiptext{
  visibility: visible;
}
.tooltiptext {
  visibility: hidden;
  color: white;
  position: absolute;
  z-index: 5;
  /* white-space: nowrap; */
  width: 300px;
  bottom: 15px;
  left: -1px;
  background-color: #0041c1;
  padding: 5px;
  font-family: math;
  border: 1px solid wheat;
  border-radius: 5px;
}
.doll_sig{
  color: #85bb65;
}
.period_time{
  font-size: 12px;
}
.password_icon {
  margin: 0 5px;
  color: #0041c1;
  cursor: pointer;
  position: relative;
}
.password_icon:hover .password_tooltiptext{
  visibility: visible;
}
.password_tooltiptext {
  visibility: hidden;
  color: gray;
  position: absolute;
  z-index: 5;
  width: 300px;
  bottom: 5px;
  left: 15px;
  background-color: white;
  padding: 5px;
  border: 1px solid wheat;
  border-radius: 5px;
}
.pass_feild {
  position: relative;
}
.eye_icon{
  position: absolute;
  right: 0;
  top:0
}
.font-16 {
  font-size: 16px !important;
}
.font-18 {
  font-size: 18px !important;
}
.ml-9{
  margin-left: 9% !important;
}
.view_or{
  padding-top: 20px;
  font-weight: 600;
}
.mar-5 {
  margin-left: 4em !important;
}
.checkbox_trans {
  position: absolute;
  top:17px;
  left: 5px;
}
.pl-10 {
  padding-left: 30px;
}
.first-player{
  margin-left: 16px;
}
.serial-no{
  color: #858585;}
.pt-10 {
  padding-top: 10px !important;
}
.px-5-0 {
  padding:5px 0px !important
}

.background_white {
  background-color: white !important;
}
.pl-32 {
  padding-left: 32px;
}
/* .rich_editor { */
  /* width: 750px;
  margin-left: 15%; */
  /* margin-top: 10px; */
  /* margin-bottom: 50px; */
/* }  */
.rdw-editor-main{
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 5px;
  background: white;
  height: 350px !important;
} 
.public-DraftStyleDefault-block {
  margin: 0px !important;
}
.max-width-900 {
  max-width: 900px !important;
}
.rdw-image-modal {
  width: max-content !important;}

.b_g_color{
  background: linear-gradient(0deg, #f5fcff 0%, #fdf6f9 100%) ;
}
/* width */
::-webkit-scrollbar {
  width: 12px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background:  #c6c5c2;
  border-radius: 8px ;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background:#9fc99f !important;
}
.card_bg{
  background: linear-gradient(0deg, #f5fcff 0%, #fdf6f9 100%) !important;
  color: #82826F;
}
._2iA8p44d0WZ-WqRBGcAuEV{
  padding: 6px 15px !important;
  border: 1px solid #ced4da !important;

} */
.cloudsnooker .statics-tab.nav-tabs .nav-link{
  background: transparent;
}
.managenewss p.text-right {
  position: absolute;
  right: 14px;
  font-size: 13px;
  color: #858585;
  margin-top: 10px;
}
.managenewss{background:transparent;box-shadow:unset;}
.managenewss .club-name-bold span{font-size: 16px;line-height: 22px;    margin-bottom: 0px;
  display: block;float:unset;}
.managenews .club-name-bold span{font-size: 15px;line-height: 19px;    width: 682px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;}
.managenews .club-name-bold .d-block.text-capitalize{
  font-size: 13px;
  font-weight: 300;    font-family: "robotoregular";
}
.managenewss h6.text-center {
  font-size: 14px;
  color: #666666;
  font-family: "robotoregular";
  margin-top: 2em;
}
.managenewss h6.text-center i {
  margin-left: 4px;
}
.managenewss .club-name-bold .d-block.text-capitalize{
  font-size: 13px;    margin-bottom:0px;
  font-weight: 300;    font-family: "robotoregular";line-height: 19px;
}
.managenews .table, .managenewss .table{
  margin-bottom: 0px;
  box-shadow: 0px 2px 3px rgb(0 0 0 / 20%);    margin-left: auto;
    margin-right: auto;width: 99.4%;
}

.managenews .table.tables{
  box-shadow: 0px 0px 6px rgb(0 0 0 / 20%);
  padding: 0px 10px;
  background: #fff;
  border-radius: 7px; margin-top: 10px;    
 
}
.managenewss .table.tables{
  box-shadow: 0px 0px 6px rgb(0 0 0 / 20%);
  padding: 0px 10px;
  background: #fff;
  border-radius: 7px; margin-bottom: 10px;margin-top:0px;

}
.newdetails h3{    font-size: 20px;
  line-height: 28px;    color: #124e25;position: relative;margin-left:10px;}
  .newdetails h3:before {
    position: absolute;
    content: "";
    background: #b52830;
    width: 2px;
    height: 46px;
    left: -10px;
    top: 5px;
}
.newdetails img{margin-bottom:1.5em;}
  .newdetails h4{    font-size: 15px;
    line-height: 28px;    color: #124e25;}
.newdetails p{font-size: 14px;
  font-weight: 300;    margin-bottom: 7px;
  font-family: "robotoregular";color:#666666;line-height: 28px;    
}
.newdetails {
  width: 67%;
  margin: auto;background: #fff;
}
.newdetails ul{padding-inline-start:0px;}
.newdetails li{font-size: 15px;
  font-weight: 300;
  font-family: "robotoregular";color:#666666;line-height: 28px;    
}
.newdetails li span{color: #333333;    font-weight: 600;}
.newdetails h6{font-size: 13px;
  font-weight: 300;color:#858585;
  font-family: "robotoregular";margin-bottom: 20px;
}
.content-wrapper.white {
  background: #fff;
  height: 100vh;
}
.global-table.managenews .table thead th {
  vertical-align: bottom;
  border-bottom: 0px solid #dee2e6;
  background: #fff;
}
.global-table.managenews {
  background: transparent;
  box-shadow: unset;
}
.allTransactionTopLayout.newspagination {
  padding: 14px 20px;
  border-radius: 0px;
}
.managenews .allTransactionTopLayout.newspagination .pagination a {
    border-radius: 50%;
    margin-left: 11px;
}
.managenews .allTransactionTopLayout.newspagination .pagination{margin:0px;}
.managenews .table th:first-child, .managenews .table td:first-child {
  width: 71%;
}
.managenews .table th:nth-child(2), .managenews .table td:nth-child(2) {
  width: 18%;
}
.managenews .table th:nth-child(3), .managenews .table td:nth-child(3) {
  width: 13%;
}
.manage_icon_action {
  display: flex;
  justify-content: flex-start;
}
.newsCard .edit-club:hover g{
  fill: #413d74;
}
.newsCard .delete-club:hover g{fill: rgb(143 8 16);}
.newsCard .publish:hover{fill-opacity:1;}

.icon_DOB{
  z-index: 10;
  cursor: pointer;
}
.w-275 {
  width: 275px;
}
.w-250 {
  width: 250px;
}

.groups .table-group-head-row th, .groups .table-group-head-row-v1 th {
  border-right: 1px solid #000000;
  font-weight: 500;
  border-bottom: 0px;
  border-top: 0px;
  font-size: 17.5px;
}
#groupResult .table {
  -moz-osx-font-smoothing: grayscale;
  font-family: "robotoregular";
}
.groups .table{margin-bottom: 0px;}
.groups  .table-group .highest-break {
  max-width: 115px;
  flex: unset;
  width: 118px;
  padding: 5px 12px;
  border-right: 1px solid #000000;
}

.groups .table-group .highest-break.selects{
  border: none;
}
.add-club-wrapper .accordion .card .layout-view .groups .table-group.card_bg {
  overflow-y: hidden;
  border-radius: 17px 17px 0px 0px;
}
.groups .table-group-head-row-v1 th.name-col {
  min-width: 138px;
  width: 138px;
}
.groups .table-group-head-row-v1 th.country-col {
  min-width: 117px;
  width: 117px;
}
.groups input.table-group-body-col.highest-break.table-group-position {
  margin: 3px 0px 1px 12px;
}
.groups .table-group.card_bg {
  margin: 0px;
}
.groups .table-responsive::-webkit-scrollbar {
  width: 11px;
  height: 4px;
}
.groups .table-responsive::-webkit-scrollbar-thumb {
  background: #90a979;
  border-radius: 8px;
}
.groups .table-group-checkbox{top: 0px;}
.groups .table-group .group-title{border-right: 0px;}
.groups .highest-break.custom-bg {
  background-color: #243122;
  position: relative;
  z-index: 1;
  font-size: 17.5px;
  top: unset;
  height: auto;
  min-width: 143px;
  width: 141px;
  background: linear-gradient(0deg, #f5fcff 0%, #fdf6f9 100%) !important;
  text-align: center;
  color: #82826F;
  padding: 16px 13px;
}
.groups  .table-group .highest-break.custom-bg.highhests{
  min-width: 94px;
  width: 94px;padding: 5px 12px;
}
.groups  .table-group-head-row-v1 th {
  color: #fff;font-weight:500;    padding: 6px 9px;
  font-size: 16px;    color: #124e25;line-height: 28px;

}
.groups  .table-group-body-row td{
border-top:1px solid #000000;}
.groups .table-group-checkbox:before{display:none;}
.groups  .table-group-head-row-v1 th span, .groups  .table-group-body-row td span{
  padding: 5px 0;
  max-width: 35px;
  margin: 0 7px;
  width: 100%;
}
.groups  .table-group-body-row td h5{
  font-size: 13px;
  margin-bottom: 0px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: "robotoregular";
}
.groups .table-group-head-row-v1 th h5{
  font-size: 16px;
  margin-bottom: 0px;
  display: flex;
  font-weight: 500;
  justify-content: space-around;
  align-items: center;
  font-family: "robotoregular";
}
.groups .table-group .group-matches {
  max-width: -moz-fit-content;
  max-width: fit-content;
  flex: unset;
  width: auto;
  padding: 2px 6px 1px 0px;
}
.groups  .table-group-head-row {
  min-height: 70px;
  font-size: 20px;
  color: #82826F;
  font-weight: 400;
}
.groups .table-group-body-row .table-group-body-col, .groups .group-titles {
  padding: 6px 9px;
  border-right: 1px solid #000000;
  font-size: 14px;line-height: 29px;
}
.groups .group-titles .table-group-body-col.group-results.d-flex.align-items-center {
  max-width: fit-content;
  width: fit-content;
  border: none;
  padding: 0px;
}
.groups .table-group-body-row td span {
  padding: 5px 0;
  max-width: unset;
  height: 30px !important;
  width: 30px !important;
  background: #c7d5cb;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Toastify__toast--success {
  background: rgb(26, 118, 30) !important;
}

.fc .fc-toolbar-title {
  font-size: 22px !important;
} .allTransaction-list-head .amount-tab,
.allTransaction-list .amount-tab {
  flex: 1 1 180px;
  max-width: 180px;
  text-align: center;
}
.allTransaction-list-head .date-time-tab,
.allTransaction-list .date-time-tab {
  flex: 1 1 150px;
  max-width: 150px;
  text-align: center;
}
.allTransaction-list-head .statusTab,
.allTransaction-list .statusTab {
  flex: 1 1 150px;
  max-width: 150px;
  text-align: center;
}
.allTransaction-list-head .actionTab-width,
.allTransaction-list .actionTab-width {
  flex: 1 1 80px;
  max-width: 80px;
}
.link_btn {
  color: white !important;
}
.link_btn:hover{
  text-decoration: none !important;
}
.lives{
  margin-bottom: 0px !important;
  border: 1px solid !important;
  padding: 10px;
  height: 30px !important;
}
.lives:hover{
  background-color: red;
  text-decoration: none !important;
  background: #c35c5c2b;
  cursor: pointer;
}

.liveBtn{
  position: absolute;
  margin-top: 4px;
  left: 9px;
  width: 12px;
  height: 12px;
  box-shadow: 0 0 4px 0 rgb(237 45 45 / 60%);
  background-color: #e53935;
  border-radius: 50%;
  margin-right: 11px;
}

.Blink {
  animation: blinker 1s cubic-bezier(.5, 0, 1, 1) infinite alternate;  
}

@keyframes blinker {  
from { opacity: 1; }
to { opacity: 0; }
}


.liveBtn:hover{
  background-color: red;
  text-decoration: none !important;
  background: #ef1010;
  /* cursor: pointer; */
}


#selectBox{
  padding-right: 0px !important;
}

.custom-table .action-btn div#dropdownCopyTool:hover, .custom-table .action-btn div#dropdownSignOutTool:hover{
  cursor: pointer;
}


#pageNotFound {
  height: 100vh;
}

.filterIcon{
  color: #b52830;
  font-size: 3em;
  cursor: pointer;
}
.filterIcon.alltras {
  font-size: 30px;    text-align: right;
}
.filters{display:flex;}
.sticky-layout {
  width: auto;
  position: fixed;
  right: 24px;
  left: 274px;
  top: 24px;
  z-index: 10;
  animation: slideInDown .3s ease-in-out;
  -webkit-animation: slideInDown .3s linear;
}
.closed_view{
  border: 1px solid lightgray !important;
}

.span_div{
  font-size: 15px;
    line-height: 18px;
    color: #333333;
    font-family: "robotomedium";
    display: block;
    padding-right: 6px;
}
.table-margin {
  margin-top: 0.8em !important;
}
.referee_div{
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.ml-20 {
  margin-left: 20px;
}
.ml-10 {
  margin-left: 10px;
}

#tournShowRk .inActive{
  display: none;
}
#score_view {
  margin-bottom: 22px;
  visibility: hidden;
}
.mr-6 {
  margin-right: 6px !important;
  font-size: 18px;
}
.add-club-wrapper .details-match.details .col-lg-6.col-md-6{padding-right:0px;}
.match-player-schedule .date-time{
  font-size: 14px;
  line-height: 20px;
  color: #333;
  font-family: "robotomedium";
  margin-bottom: 1px;
}
.match-player-schedule span.serial-no {
  font-size: 14px;
  font-weight: 600;
}
.team-content .table tbody tr.matchs-players{background-color:#fff;}
.match-player-schedule .player-title {
  font-size: 18px;
  margin-bottom: 6px;    line-height: 22px;
  color: #b52830;
  font-weight: 700;
}
.team-content .table tbody tr.matchs-players .match-player-schedule {
  background: #fff;
}
.team-content .table tbody tr.matchs-players .player-schedule{background-color:#fff;}
.match-player-schedule .player-schedule .saprater {
  margin: 0px 1.5em !important;align-items:center;display:flex;
}
td.match-player-schedule .card {
  padding: 10px 1.5em;
  margin: 12px;
  border-radius: 7px;
}
.match-player-schedule .left-content{text-align:right;}
.match-player-schedule h4 {
  font-size: 16px;    margin-bottom: 0px;
  font-weight: 600;        color: #124e25;}
  .match-player-schedule .groups{width:35%;background:#fff;}
  .match-player-schedule .detailss{    justify-content: center;
    width: 65%;background:#fff;} 
.match-player-schedule .player-schedule h5 {
  font-size: 16px;
  color: #767373;    margin: 0px;
}
td.match-player-schedule .card:last-child{margin-bottom: 0px;}
.match-player-schedule .right-content .player-title {
  color: #124e25;
}
.match-player-schedule .flag {
  padding: 0px 4px;
}
.match-player-schedule .lefts, .match-player-schedule .rights {
  width: 47%;
}
.match-player-schedule h6 span {
  padding: 0px 3px;
}
.match-player-schedule h6{
  align-items: center;
  display: flex;
  color: #555151;
  justify-content: center;
  margin-bottom: 0px;
  margin-top: 5px;
}
.match-player-schedule .title {
  color: #333;
  margin-bottom: 3px;
  font-size: 16px;
  line-height: 24px;
  font-family: "robotomedium";
  min-height: 24px;
}
.match-player-schedule .country {
  font-size: 13px;
  color: #666;
  line-height: 20px;
}
.team-content .table td a:hover{text-decoration:unset;}
.match-player-schedule .rights .player-tooltip {
  margin-left: -180% !important;
}
.match-player-schedule .country-name{
  font-size: 13px;
  line-height: 22px;
}
.add-club-wrapper .details-match.details .col-lg-6.col-md-6{padding-right:0px;}
button.create-tournament-red-btn.btns.disable-edit-delete {
  pointer-events: unset;
}
.whens.knockout-whens {
  left:unset;
  right: unset;
  margin-left: 2px;
  margin-top: 24px;
  width: fit-content;
}
.whens.knockout-whens .switch {
  height: 21px;    width: 45px;
}
.whens.knockout-whens .slider-switch:before {
  width: 14px;
  transform: translateX(0px);
}
.whens.knockout-whens input:checked~.slider-switch:before {
  transform: translateX(23px);
}
#tournShowRk{    margin-top: 18px;
}

#registerAsPlayer .register-as-player .checks .text-error.errors {
  top: 30px;
}
#registerAsPlayer .register-as-player .text-error.errors {
  top: 43px;
}

.email-condition .btn.btn-link {
  margin-top: 12px;
  padding: 0px;
}
.email-condition .alert-success {
  padding: 7.4px;
  margin-top: 4px;
}
.email-condition .spinner-border {
  margin-top: 9px;
}
button.btn.red-btn:disabled {
  opacity: 0.65;
  background-color: #b52830;
  pointer-events: unset;
  border: 1px solid #f07279;
}

.mobile-number .form-control {
  height: 38px;
}
span.filtertap {
  font-size: 15px;
  color: black;
  font-family: 'robotobold';
  font-weight: normal;margin-right:4px;
}

.allTransactionTopLayout .allTransaction-list-head.tabss{display: none !important;}
.addplayersforms select {
  -webkit-appearance: auto !important;
  appearance: auto !important;
  margin: 5px 5px;
  background: #fff;
  padding: 4px;
  border-radius: 3px;
}
select.react-datepicker__year-select {
  width: 86px;
}
.appprivacy h3 {
  color: #464444 !important;
  font-size: 32px;
  margin-top: 15px;
  margin-bottom: 23px;
}
.appprivacy h4{
  font-size: 17px;
  margin-bottom: 12px;
  color: #464444;
}
.appprivacy .small, .appprivacy small {
  font-size: 100%;
  font-weight: 400;
  color: #464444;
}
.appprivacy h5 {
  color: #464444;
  font-size: 18px;
  margin-bottom: 12px;

}
.appprivacy ul {
  margin: 0 0 0 2px;
  padding: 0 0 0 30px;
}
.appprivacy #SHARED, .appprivacy #TRACKING, .appprivacy #INFORMATION {
  margin-bottom: 18px;
}
.appprivacy li {
  margin-bottom: 10px;
  color:#464444;
  list-style: disc;


}
.non-paid {
  border: solid 1px #cccccc;
  background-color: #f3f3f3;
}
.fixedWidth{
  width: 150px !important;
}

td.tournamentFee {
  width: 505px;
  /* text-align-last: right; */
  font-size: 11px;
  word-break: unset !important
}

.tourFee {
  width: 400px;
  bottom: unset;
  top: 31px;
  left: unset;
  right: 13px;
}

.round-view-layout.schedules[data-rbd-droppable-context-id="0"] {
  min-height: unset !important
}

.winner-profile {
     height: 25px;
     font-family: auto;
     font-size: 24px;
     font-weight: 500;
     font-stretch: normal;
     font-style: normal;
     line-height: 0.2;
     letter-spacing: normal;
     text-align: right;
     color: #124e25;
     margin-bottom: 8px;
}

.referee-profile {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.2;
  letter-spacing: normal;
  text-align: right;
  color: #124e25;
  margin-top: 10px;
}

.text-style-2{
  font-size: 16px;
  font-family: Roboto;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: #666;
}

.text-style-1{
  font-size: 16px;
  font-weight: normal;
  color: #666;
}

.refereeNms{
  color: #b52830 !important;
}

.Line-14 {
  width: 0;
  height: 50px;
  margin: 9.5px 0 8.5px;
  border: solid 1px #ccc;
}

.winner, .winnerOnly {
  margin-top: 20px;
}

  .bar{
    width: 400px;
    height: 1px;
    /* background-color: #b8c7bc; */
    border-radius: 10px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), #b8c7bc, rgba(255, 255, 255, 0));
  }

  .add-rounds-points{
    padding-right: 34px;            
    cursor: pointer;
  }

  .signout-tooltip ul li:hover{
    text-decoration: none;
    color: #b52830;
  }
    .end-match-disable {
      cursor: auto;
      color: #6c757d !important;
    }
  
    .disabled-text:hover {
      cursor: auto;
      background: #fff !important;
      color: #6c757d !important;
    }
  
    .end-match-disable a:hover {
      color: #6c757d !important;
    }
  
    .score-btn-icon {
      margin: 5px 0px 0px 20px !important;
    }
  
    .cursor-auto {
      cursor: initial !important
    }
  
    .tournament-list-item .action-btn .tool-btn svg path.red-fill {
      fill: #b52830;
    }
  
    .light-controls-tool:hover,
    .tournament-list-item .edits:hover {
      cursor: pointer;
    }
  
    .tournament-list-item .action-btn .tool-btn:hover svg path {
      opacity: 0.6;
    }
  
    .tool-tip-arrow-score::after {
      bottom: 100%;
      left: 50px;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: #ffffff;
      border-width: 10px;
      margin-left: 30px;
    }
  
    .tool-tip-arrow-score {
      min-width: 143px !important;
      padding: 5px;
    }
  
    .score-bar {
      position: absolute;
      height: 1px;
      background-color: #b52830;
      border-radius: 21px;
      left: 5px;
      width: 132px;
      opacity: 0.3;
    }

    .delete-matches{
      left: 11px;
      top: 5px;
    }
  

  .tournament-details-component{
    max-width: none !important;
    transform: none !important;
    transition: none !important;
  }

  .live-scoring-iframe{
    padding: 34px 0 0 !important;
  }

  .share-iframe-url {
    width: 231px;
    align-items: center;
    height: 36px;
    color: #124e25;
    font-size: 16px;
    line-height: 19px;
    font-family: "robotomedium";
    transition: color 0.3s ease; /* Add transition for a smooth color change */
  }
  
  .share-iframe-url span:hover {
    color: #338033; /* Change color on hover */
    text-decoration: underline;
  }
  .share-iframe-url .copied{ cursor: default !important; text-decoration: none !important;}
  
  .share-iframe-url:active {
    color: #0d3e0d; /* Change color when clicked */
  }
  
  .share-iframe-url svg {
    margin-right: 7px;
    fill: #124e25; /* Initial color for the SVG */
    transition: fill 0.3s ease; /* Add transition for a smooth color change */
  }
  
  .share-iframe-url:hover svg {
    fill: #338033; /* Change SVG color on hover */
  }
  
  .share-iframe-url:active svg {
    fill: #0d3e0d; /* Change SVG color when clicked */
  } 

  .share-iframe-url .copied:hover, .share-iframe-url .copied:active {
    color: #124e25; /* Change color on hover */
    text-decoration: none;
  }  

  .card-unset-width{
    max-width: unset !important;
  }
  .discl{
    position: absolute;
    top: 60px;
    font-size: 11px;
    color: rgba(255, 255, 255, 0.6);
    width: 100%;
  }
  

  /* .card.frame-card{
    border: 1px solid rgba(0,0,0,.125) !important;
    border-radius: .25rem !important;
  } */
.add-club-wrapper #resultsModal td.match-update-score{
  padding: 15px;
}
.add-club-wrapper #resultsModal td.match-update-score svg{
  height: 14px;
  width: auto;
  margin-left: auto;
  margin-top: 3px;
  fill: #b52830;
  cursor: pointer;
}
.table-score-m-h{
  border: 1px solid #dee2e6;
}
/* .he-auto{
  min-height: 80px;
} */
.cus-form-control{
  font-size: 14px;
  line-height: 18px;
  color: #333333;
  padding: 10px 15px;
  display: block;
  width: 100%;
  font-weight: 400;
  background: none;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.cus-form-control:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(0,123,255,.25);
}
.btn-row{
  margin-bottom: 17px;
  margin-right: 10px;  
}
.btn-row .expand-btn{
  position: static;
  display: inline-block;
  line-height: 12px;
  height: 33px;
  margin-right: 16px;
}

.update-score-header{
  position: relative;
  margin-bottom: 10px;
}
.update-score-header .title{
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 4px;
  line-height: 20px;
  color: #333333;
}
.update-score-header .date-time{
  font-size: 16px;
  line-height: 20px;
  color: #333333;
  font-family: "robotomedium";
  margin-bottom: 7px;
}
.update-score-header .table-container{
  border: 1px solid #c7d5cb;
  margin-top: 5px;
  max-width: 928px;
}
.update-score-header .table-text{
  font-size: 13px;
  line-height: 20px;
  color: #858585;
}
.animated#tournamentDetails{
   animation: slideIn 1s forwards;
}

.animated#tournamentDetails.closing {
  animation: slideOut 1s forwards;
}


@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

.animated .top-head {
  padding: 10px 32px 10px 32px !important;
}

.animated .top-head .top-title {
    font-size: 18px !important;
    margin-right: 52px;
}

.animated .update-score-header .title{
  font-size: 18px !important;
}

.animated .update-score-header .date-time{
  font-size: 14px !important;
  line-height: 14px !important;
}

.animated .update-score-header .table-text{
  font-size: 12px !important;
  line-height: 14px !important;
}

.animated .update-score-header .table-container{
  margin-top: 0px !important;
}

.animated .tableView .player-details .title {
  font-size: 18px !important;
  margin-bottom: 3px !important;
  line-height: 20px !important;
}

.animated .tableView .player-details .player-name {
    font-size: 14px !important;
    margin-bottom: 3px !important;
    line-height: 20px !important;
}

.animated .tableView .player-details.left-player {
    /* margin-right: 18px; */
    position: relative;
}

.animated .tableView .player-details .content-left {
    margin-right: 24px;
}

.animated .tableView .player-details .content-right {
    margin-left: 24px;
}


.animated .tableView .player-details figure {
  width: 72px;
  min-width: 72px;
  height: 72px;
  min-height: 72px;
}

.animated .match-time-row{
  line-height: 18px;
}
.total-score{
  font-size: 14px;
}
.frame-diff{
  margin-right: 52px;
}
.pro-matchs-players{
  border-bottom: 1px solid #ced4da;
}
.pro-matchs-players:last-child{
  border-bottom: none;
}
.pro-match-update{
  width: 50%;
  padding: 20px 15px 10px !important;
}
.pro-match-update.pr-20{
  padding-right: 37px !important;
}
.pro-match-update.pl-20 {
  padding-left: 37px !important;
}
.pro-match-update .ball-adj{
  height: 49px;
  width: 49px;
  background: #fff;
  border: 1px solid #cccccc;
  border-radius: 50%;
  margin-right: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 14px;
  cursor: pointer;
  font-size: 16px;
  color: #333333;
}
.pro-match-update .ball-adj.add-break:hover{
  font-weight: bold;
  border-color: #439f47;
  box-shadow: 0 8px 16px 0 rgba(18, 78, 37, 0.2);
  svg{
    fill: #439f47;
    stroke: #439f47;
  } 
}
.custom-width-col-1{
  width: calc(100% + 15px);
  justify-content: flex-end;
}
.custom-width-col-2 {
  width: calc(100% + 15px);
}
.pro-match-update.br-1{
  border-right: 1px solid rgba(18, 78, 37, 0.16) !important;
}
.dropdownMenu-col{
  position: relative;
  width: 59px;
  padding-bottom: 40px;
}
.dropdownMenu-col img{
  height: 40px;
  width: 40px;
  position: relative;
  z-index: 6;
}
.dropdownMenu-col:last-child{
  margin-right: 0;
}
.dropdownMenu-col:last-child::after{
  display: none;
}
.pro-match-update .modal-ball-adj{
  height: 35px;
  width: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ball-dropdown{
  box-shadow: 0 8px 16px 0 rgba(18, 78, 37, 0.2);
  border: solid 1px rgba(18, 78, 37, 0.24);
  border-radius: 20px;
  margin-top: 7px;
  user-select: none;
}
.ball-dropdown .ball-col{
  width: 40px;
  height: 40px;
  position: relative;
}
.ball-dropdown .dropdownMenu-col:hover .ball-col:after,
.ball-dropdown .dropdownMenu-col.selected  .ball-col:after{
  content: '';
  height: 45px;
  width: 46px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  left: -3px;
  top: -3px;
  z-index: 5;
}

.ball-adj.dropdown-menu-right .dropdown-menu.ball-dropdown.show{
  left:inherit;
  right: 0;
}

.ball-adj.selected-break-bottom .dropdown-menu.ball-dropdown.show{
  top: -125px !important;
}
/* .dropdown-menu[x-placement^=bottom],
.dropdown-menu[x-placement^=left],
.dropdown-menu[x-placement^=right],
.dropdown-menu[x-placement^=top]{
  left: auto !important;
} */
.num-count{
    width: 18px;
    height: 18px;
    box-shadow: 0 2px 6px 0 rgba(18, 78, 37, 0.24);
    background-color: #fff;
    position: absolute;
    font-size: 12px;
    color: #b52830;
    z-index: 999;
    border-radius: 50%;
    text-align: center;
    line-height: 18px;
    right: -10px;
    top: -4px;
}
.ball-dropdown .dropdownMenu-col:hover .ball-col:before,
.ball-dropdown .dropdownMenu-col.selected  .ball-col:before {
  content: '';
  height: 47px;
  width: 48px;
  background: #439f47;
  border-radius: 50%;
  position: absolute;
  left: -4px;
  top: -4px;
  z-index: 4;
}
.add-min svg{
  height: 28px !important;
  width: 28px !important;
}
.pro-match-update .modal-ball-adj:last-child{
  margin-right: 0;
}
.pro-match-update .dropdownMenu{
  padding: 12px 12px 5px;
}
/* .dropdownMenu-col svg{
  height: 17px;
  width: 17px;
  margin-bottom: 7px;
} 
.dropdownMenu-col svg path{
  fill: #8f8f8f;
}
.dropdownMenu-col svg:hover path{
  fill: #030303;
}*/
.dropdownMenu-col:hover .add-min,
.dropdownMenu-col.selected .add-min{
  opacity: 1;
}
.add-min{
  margin-top: 10px;
  position: absolute;
  bottom: 0;
  opacity: 0;
  user-select: none;
}
.add-min #increment{
  margin-right: 8px;
}
.add-min svg path{
  fill: #858585;
}
.add-min a:hover svg path {
  fill: #124e25;
}
.dropdownMenu-col svg.minus {
  height: 20px;
  width: 20px;
  margin-bottom: 0;
  margin-top: 3px;
}
.pro-match-update .modal-ball-adj.black{
  background: #000;
  color: #fff;
}
.pro-match-update .modal-ball-adj.red {
  background: #F3571D;
  color: #fff;
}
.pro-match-update .modal-ball-adj.yellow {
  background: #FFF52C;
  color: #000;
}
.pro-match-update .modal-ball-adj.brown {
  background: #892400;
  color: #fff;
}
.pro-match-update .modal-ball-adj.green {
  background: #287900;
  color: #fff;
}
.pro-match-update .modal-ball-adj.purpule {
  background: #4357BD;
  color: #fff;
}
.pro-match-update .modal-ball-adj.pink {
  background: #FFC9D1;
  color: #000;
}
/* .pro-match-update .ball-adj.selected{
  font-weight: bold;
  border-color: #124e25;
} */
.pro-match-update .ball-adj.selected-break{
  font-weight: bold;
  border-color: #439f47;
  box-shadow: 0 8px 16px 0 rgba(18, 78, 37, 0.2);
}
/* .pro-match-update .ball-adj.selected[aria-expanded="true"]::after,
.pro-match-update .ball-adj.selected[aria-expanded="true"]::before{
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  margin-top: -10px;
  z-index: 9999;
}
.pro-match-update .ball-adj.selected[aria-expanded="true"]::after{
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
  border-width: 15px;
  margin-left: -15px;
} */
.pro-match-update .ball-adj.selected.selected-break::before {
  border-color: rgba(237, 237, 237, 0);
  border-bottom-color: #e6e6e6;
  border-width: 14px;
  margin-left: -14px;
}
.pro-match-update .bottom{
  margin:10px 0;
  font-size: 14px;
  font-weight: 600;
  color: #666666;
}
.pro-match-update .bottom svg{
  height: 15px;
  width: 15px;
  margin-left: 5px;
  cursor: pointer;
}
.pro-match-update .bottom svg:hover path{
  fill: #124e25;
}
.pro-match-update .bottom .red-txt{
  color: #b52830;
  margin-left: 5px;
}
.pro-match-update .bottom .text-green {
  color: #124e25;
  margin-left: 5px;
}


.updated-header{
  padding: 21px 16px 0;
}
.updated-header .table-data .title{
  font-size: 20px;
  line-height: 24px;
  color: #2d3c25;
  font-weight: bold;
  margin-bottom: 5px;
}
.updated-header .table-data .date-time .text-light-gray{
  font-size: 13px;
  color: #666666;
  font-weight: 600;
}
.updated-header .table-data .date-time .text-red {
  font-size: 13px;
  color: #b52830;
  font-weight: 600;
  margin-left: 5px;
}
.updated-header .expand-btn{
  background: #ebebeb;
  border: none;
  margin-right: 10px;
  font-size: 13px;
  font-weight: 500;
  color: #666666;
  padding: 8px 20px;
  height: 31px;
  width: auto;
  min-width: max-content;
  line-height: 15px;
}
.updated-header .add-club {
  border: none;
  font-size: 13px;
  font-weight: 500;
  padding: 8px 20px;
  height: 31px;
  width: auto;
  min-width: max-content;
  line-height: 15px;
}


.match-update-score .bottom{
  margin:10px 0;
  font-size: 14px;
  font-weight: 600;
  color: #666666;
}
.match-update-score .bottom svg{
  height: 15px;
  width: 15px;
  margin-left: 5px;
  cursor: pointer;
}
.match-update-score .bottom svg:hover path{
  fill: #124e25;
}
.match-update-score .bottom .red-txt{
  color: #b52830;
  margin-left: 5px;
}
.match-update-score .bottom .text-green {
  color: #124e25;
  margin-left: 5px;
}

.animated .team-content .table{
  font-size: 12px !important;
}

.download-app-panel:hover .download-options {
  display: block;
}

.download-options-small-screen{z-index: 10;}
.download-options {
  background-clip: padding-box;
  border-radius: 0.25rem;
  padding: 12px 0;
  position: absolute;
  right: 352px;
  top: 65px;
  margin: 0;
  width: 216px;
  display: none;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #cccccc;
  background-color: #ffffff;
}
.download-options.scoring-app{
  right: 462px !important;
  top: 58px !important;
}

.download-options.download-liveScoring{
  right: 220px !important;
}
.download-options.live-scoring-app{
  right: 331px !important;
  top: 58px !important;
}

.download-options a,
.download-options span.signout-btn {
  font-size: 16px;
  line-height: 25px;
  color: #333333;
  display: block;
  padding: 7px 20px;
  font-family: "robotomedium";
  border-left: 3px solid transparent;
  text-transform: capitalize;
}
.download-options a:hover,
.download-options span.signout-btn:hover {
  border-left: 3px solid #124e25;
  color: #124e25;
  text-decoration: none;
  background: #f5f5f5;
  border-bottom: none;
}
.download-options a:hover svg path,
.download-options span.signout-btn:hover svg path {
  fill: #124e25;
  stroke: #124e25;
}
.download-options::before {
  content: "";
  position: absolute;
  right: 28px;
  top: -8px;
  border-bottom: 8px solid #ffffff;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}
.download-options svg {
  width: 24px;
    height: 24px;
    margin-right: 10px;
}

i.arr-down.dropdown-arrow.ml-2 {
  top: 10px !important;
  position: relative !important;
}

.timezone-warning{
  font-size: 11px;
    color: darkred;
    top: 20px;
    position: relative;
}

input.cus-form-control.scoreInput{
  line-height: 8px !important;
  padding: 6px 12px;
  width: 29%;
  margin-left: 15px;
}

input.cus-form-control.scoreInput.input-error{
  border: 1px solid #b52830 !important;
}

.hidden-option {
    display: none !important;
}

.menu-icon {
  width: 20px;
  height: 20px;
  fill: currentColor;
}
.profile-options li a {
  color: #333; /* Adjust this as needed */
  font-size: 14px;
}


.app-download-panel:hover .app-options {
  display: block;
}

.app-options {
  background-clip: padding-box;
  border-radius: 0.25rem;
  padding: 12px 0;
  position: absolute;
  right: 0;
  top: 10px;
  margin: 0;
  width: 216px;
  display: none;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #cccccc;
  background-color: #ffffff;
}

.profile-options-small-screen{z-index: 10;}
.app-options {
  background-clip: padding-box;
  border-radius: 0.25rem;
  padding: 12px 0;
  position: absolute;
  right: -32px;
  top: 17px;
  margin: 0;
  width: 227px;
  display: none;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #cccccc;
  background-color: #ffffff;
}
.app-options a,
.app-options span.signout-btn {
  font-size: 16px;
  line-height: 25px;
  color: #333333;
  display: block;
  padding: 7px 20px;
  font-family: "robotomedium";
  border-left: 3px solid transparent;
  /* text-transform: capitalize; */
}
.app-options a:hover,
.app-options span.signout-btn:hover {
  border-left: 3px solid #b52830;
  color: #b52830;
  text-decoration: none;
  background: #f5f5f5;
  border-bottom: none;
}

.app-options a:hover svg path,
.app-options span.signout-btn:hover svg path {
  fill: #b52830;
  stroke: #b52830;
}

.app-options a:hover svg path.ios {
  fill: #b52830 !important;
}

.app-options a:hover svg path.outer_ios {
  fill: #fff !important;
}


.app-options::before {
  content: "";
  position: absolute;
  right: 28px;
  top: -8px;
  border-bottom: 8px solid #ffffff;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}
.app-options svg {
  width: 24px;
}

.app-options svg.android {
  fill: #3DDC84;
  stroke: #3DDC84;
}

.app-options svg.react {
  fill: #61DBFB;
  stroke: #61DBFB;
  g{
    stroke: #61DBFB;
  }
}


.app-options a:hover svg.react {
  fill: #b52830;
  stroke: #b52830;
  g{
    stroke: #b52830;
  }
}

.app-options svg.appStore {
  /* width: 40px;
  height: 40px; */

  transform: scale(1.5);  /* Scale by 2 times, adjust as needed */
  transform-origin: center center;  /* Optional: Set the scaling origin to the center */
}

.app-options li a {
  color: #333; /* Adjust this as needed */
  font-size: 14px;
}

.custom-style-ios {
  padding-bottom: 3px;
  margin-right: 12px !important;
}

/* ------- Optimized Download CSS --------- */

.download-container {
  background-image: url('../../assets/images/green-bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
}

.download-container img.background {
  width: 1280px;
  height: 397px;
  margin-bottom: 379px;
  padding-bottom: 239px;
  object-fit: contain;
}

.download-banner {
  position: absolute;
  top: 90px;
  width: 100%;
  height: 300px;
  background: linear-gradient(to bottom, #3e8e41, #1c531d);
  border-bottom-left-radius: 50% 20%;
  border-bottom-right-radius: 50% 20%;
}

.download-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 926px;
  background: linear-gradient(to bottom, #3e8e41 0px, #3e8e41 300px, #f5f5f5 200px, #f5f5f5 100%);
}

.download-title {
  position: absolute;
  width: 100%;
  height: 38px;
  margin: 65px 0px;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  color: #fff;
}

.app-card {
  width: 360px;
  height: 560px;
  margin: 160px 55px;
  padding: 34px 20px;
  border-radius: 24px;
  box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.04);
  background-color: #fff;
}

.app-card-content {
  width: 312px;
  height: 443px;
}

.app-logo-container {
  width: 208px;
  height: 208px;
  margin: 0 auto 30px;
  padding: 4px;
  border-radius: 57px;
  box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #ebebeb;
  background-color: #fff;
}

.app-logo {
  width: 200px;
  height: 200px;
  object-fit: contain;
}

.app-title {
  height: 47px;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: #2d3c25;
}

.app-description {
  width: 312px;
  height: 67px;
  margin: 17px 0 34px;
  font-size: 16px;
  text-align: center;
  color: #333;
}

.download-button {
  width: 148px;
  height: 44px;
  margin-right: 12px;
  padding: 12px 20px;
  border-radius: 22px;
  box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.04);
  border: solid 1px #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.download-button:hover {
  background-color: #124e25;
}

.download-button:hover .button-text {
  color: #fff;
}

.button-text {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #2d3c25;
}

.icon {
  height: 20px;
  object-fit: contain;
}

.button-group {
  width: 308px;
  height: 44px;
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top: 34px;
}

.react-native{
  width: 244px !important;
}

.app-logo-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}


.upload-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Adds spacing between items */
  justify-content: flex-start; /* Align items to the start */
}

.download-button {
  flex: 1 1 calc(50% - 10px); /* Ensures two items in the first row if three exist */
  /* max-width: 50%; */
  box-sizing: border-box;
  text-align: center;
}

@media (max-width: 768px) {
  .download-wrapper {
    height: 260vh;
  }
  .app-card {
    margin: auto;
    margin-top: 120px;
  }

  .mobile-view{
    display: flex;
    flex-direction: column;
  }
}

/* For screens below 600px, adjust to full width */
@media only screen and (min-width: 390px) and (max-width: 767px) {
  .download-button {
      flex: 1 1 100%;
      max-width: 100%;
  }
  .download-wrapper {
      height: 240vh;
  }
  .app-card {
    margin: auto;
    margin-top: 30px;
  }
  .download-title {
      margin: -20px 0px;
      font-size: 28px;
  }
  .mobile-view{
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 388px) {

  .download-wrapper {
    height: 280vh;
  }

  .download-title {
    margin: -20px 0px;
    font-size: 28px;
}
  .app-card {
    margin: auto;
    margin-top: 25px;
  }

  .mobile-view{
    display: flex;
    flex-direction: column;
  }

}

.download-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.download-component footer::after{
  display: none;
}